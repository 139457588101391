import withDefaultEnhancers from 'components/forms/enhancers/withDefaultEnhancers'
import { IFormElementBase } from 'components/forms/formTypes'

import { cn } from 'utils/tailwind'

import withValidationIcon from './enhancers/withValidationIcon'

export const InputText = ({ name, register, className, ...rest }: IFormElementBase) => (
  <input
    className={cn(
      'h-9 w-full border-0 px-3 py-2 text-rb-gray-400 shadow-none outline-none',
      className
    )}
    id={name}
    name={name}
    ref={register}
    {...rest}
  />
)

const InputTextWithEnhancers = withDefaultEnhancers(withValidationIcon(InputText))

export default InputTextWithEnhancers
