import { format, isSameYear, isToday, startOfDay, subDays } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PopoverPosition } from 'react-tiny-popover'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import Loading from 'components/Loading'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import {
  AiRecentChatsDocument,
  useRemoveAiChatMutation,
  useRenameAiChatMutation
} from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { displayErrorToast } from 'utils/toastService'

import { ReactComponent as PencilIcon } from 'images/icon--cohort-pencil.svg'
import { ReactComponent as TrashIcon } from 'images/icon--cohort-trash.svg'
import { ReactComponent as SliderIcon } from 'images/icon--slider.svg'

import { useGlobalChat } from '../GlobalChatProvider'
import { RecentChat, useRecentChats } from '../hooks/useRecentChats'

interface MenuSideBarHistorySectionProps {
  header: string
  recentChats: RecentChat[]
  onExistingChatClick: (chatId: string) => void
}
const MenuSideBarHistorySection = ({
  recentChats,
  header,
  onExistingChatClick
}: MenuSideBarHistorySectionProps) => {
  const [currentEditId, setCurrentEditId] = React.useState<string | null>(null)
  const [newName, setNewName] = React.useState('')
  const { newSession, chatId } = useGlobalChat()

  const [updateAiChatName] = useRenameAiChatMutation({
    onCompleted: () => {
      setCurrentEditId(null)
      setNewName('')
    },
    onError: () => {
      setCurrentEditId(null)
      setNewName('')
      displayErrorToast({
        message: 'Failed to update chat name'
      })
    }
  })

  const [deleteAiChat] = useRemoveAiChatMutation({
    onCompleted: (response) => {
      if (response && response?.removeAiChat?.id === chatId) {
        newSession({ ctaText: 'delete_existing_chat_button' })
      }
    },
    onError: () => {
      displayErrorToast({
        message: 'Failed to delete chat'
      })
    }
  })

  const resetEdit = () => {
    setCurrentEditId(null)
    setNewName('')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!currentEditId) return

    if (event.key === 'Enter') {
      updateAiChatName({
        variables: {
          input: {
            id: currentEditId,
            title: newName
          }
        },
        refetchQueries: [AiRecentChatsDocument]
      })
      setCurrentEditId(null)
      setNewName('')
    } else if (event.key === 'Escape') {
      resetEdit()
    }
  }

  const handleDelete = (id: string) => {
    deleteAiChat({
      variables: {
        input: {
          id
        }
      },
      refetchQueries: [AiRecentChatsDocument]
    })
  }
  const targetRef = useRef(null)
  const popoverPosition = useDynamicPopoverPosition(targetRef)

  return (
    <span className="mb-4" ref={targetRef}>
      <h2 className="m-0 px-2 py-1.5 text-sm text-gray-400">{header}</h2>
      {recentChats.map((chat, index) => (
        <div className="group" key={`chat-${index}`}>
          {currentEditId === chat.extId ? (
            <input
              autoFocus
              className="w-full rounded border border-gray-500 bg-rb-gray-50 px-2 py-1.5 text-sm focus:ring-0"
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value)
              }}
              onKeyDown={handleKeyDown}
              onBlur={resetEdit}
            />
          ) : (
            <div
              className={`flex items-center ${
                chatId === chat.extId ? 'bg-rb-gray' : 'hover:bg-rb-gray-50'
              }`}
            >
              <Button
                id={`chat-${index}`}
                variant="text-only"
                color="default"
                className="relative w-full cursor-pointer justify-between truncate px-2 py-1.5 text-left hover:bg-transparent"
                onClick={() => onExistingChatClick(chat.extId)}
              >
                <span className="truncate">{chat.title}</span>
              </Button>
              <DropdownContextMenu
                className="opacity-0 group-hover:opacity-100"
                triggerClassName="shrink-0 opacity-0 group-hover:opacity-100"
                positions={[popoverPosition as PopoverPosition]}
                reposition={true}
                dataTest={`chat-${index}-dropdown`}
                dismissOnClick
              >
                <DropdownContextMenu.DropdownItem
                  text="Rename Chat"
                  icon={<PencilIcon width={20} />}
                  onClick={() => setCurrentEditId(chat.extId)}
                />
                <DropdownContextMenu.DropdownItem
                  text="Delete Chat"
                  icon={<TrashIcon width={20} />}
                  onClick={() => handleDelete(chat.extId)}
                />
              </DropdownContextMenu>
            </div>
          )}
        </div>
      ))}
    </span>
  )
}

const OPTION_CLASSNAME =
  'p-2 flex h-9 w-full cursor-pointer items-center justify-start font-semibold hover:bg-neutral-50'
interface MenuSideBarProps {
  personalizeActive: boolean
  onSetPersonalizeActive: () => void
  onExistingChatClick: (chatId: string) => void
}
export const MenuSideBar = ({
  personalizeActive,
  onSetPersonalizeActive,
  onExistingChatClick
}: MenuSideBarProps) => {
  const { recentChats, loading, error, hasNextPage, loadMore } = useRecentChats()

  const recentChatsByDateKey: Map<string, RecentChat[]> = useMemo(() => {
    const todayStart = startOfDay(new Date())
    const last7DaysStart = startOfDay(subDays(todayStart, 7))
    const last30DaysStart = startOfDay(subDays(todayStart, 30))

    const resultMap: Map<string, RecentChat[]> = new Map([])

    recentChats.forEach((chat) => {
      let key: string
      if (isToday(chat.createdAt)) {
        key = 'Today'
      } else if (chat.createdAt >= last7DaysStart) {
        key = 'Last 7 Days'
      } else if (chat.createdAt >= last30DaysStart) {
        key = 'Last 30 days'
      } else if (isSameYear(new Date(), chat.createdAt)) {
        key = format(chat.createdAt, 'MMMM')
      } else {
        key = format(chat.createdAt, 'yyyy')
      }

      if (!resultMap.has(key)) {
        resultMap.set(key, [])
      }
      resultMap.get(key)!.push(chat)
    })
    return resultMap
  }, [recentChats])

  if (error) return <ErrorMessage error={error} />

  return (
    <div className="hide-scrollbar h-full overflow-y-auto p-2 pb-10 text-sm">
      <div className="flex flex-col space-y-2 p-4">
        <div className="relative">
          <div
            key="personalize"
            id="personalize"
            className={OPTION_CLASSNAME}
            role={'menuitem'}
            tabIndex={0}
            style={{ backgroundColor: personalizeActive ? '#F3F4F6' : '' }}
            onClick={onSetPersonalizeActive}
            onKeyUp={onEnterKeyPress(onSetPersonalizeActive)}
          >
            <SliderIcon width={15} className="mr-2" />
            Personalize
          </div>
        </div>
      </div>
      <div className="hide-scrollbar flex flex-col space-y-2 p-4">
        {Array.from(recentChatsByDateKey).map(([key, chats]) => (
          <div key={key} className="relative">
            <MenuSideBarHistorySection
              header={key}
              recentChats={chats}
              onExistingChatClick={onExistingChatClick}
            />
          </div>
        ))}
        {loading ? (
          <Loading />
        ) : (
          hasNextPage && (
            <button
              className="underlined text-gray-400 active:text-gray-700 hover:text-gray-600"
              onClick={loadMore}
            >
              Load More
            </button>
          )
        )}
      </div>
    </div>
  )
}

const useDynamicPopoverPosition = (targetRef: React.RefObject<HTMLElement>) => {
  const [position, setPosition] = useState('right')

  const updatePosition = useCallback(() => {
    if (!targetRef.current) return

    const { right } = targetRef.current.getBoundingClientRect()
    const { innerWidth } = window

    if (right > innerWidth - 250) {
      setPosition('left')
    } else {
      setPosition('right')
    }
  }, [targetRef])

  useEffect(() => {
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)
    const observer = new MutationObserver(updatePosition)
    observer.observe(targetRef.current as Node, {
      attributes: true,
      childList: true,
      subtree: true
    })
    updatePosition() // Initial check

    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('scroll', updatePosition)
      observer.disconnect()
    }
  }, [targetRef, updatePosition])

  return position
}
