import { publicApolloClient } from 'apolloClient'

import { useSearchCompanyQuery } from 'gql'

export type CompanyOption = {
  label: string
  value: string
}

const DEFAULT_COMPANIES: string[] = []

export function useSearchCompany() {
  const { data, loading, error } = useSearchCompanyQuery({
    variables: {
      search: '',
      kind: 'public'
    },
    client: publicApolloClient
  })

  const companies = data?.searchCompany || DEFAULT_COMPANIES

  return {
    companies,
    companyOptions: companies.map(
      (company): CompanyOption => ({
        label: company,
        value: company
      })
    ),
    companiesLoading: loading,
    companiesError: error
  }
}
