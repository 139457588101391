import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { useEffect } from 'react'

import ErrorBoundary from 'components/ErrorBoundary'

interface BugsnagProviderProps {
  children: React.ReactNode
}

const BUGSNAG_API_KEY = process.env.BUGSNAG_API_KEY
const RELEASE_VERSION = process.env.HEROKU_RELEASE_VERSION || 'localhost'

const BugsnagProvider = ({ children }: BugsnagProviderProps) => {
  const isBugsnagStarted = Bugsnag.isStarted()
  const shouldStartBugsnag = !!(!isBugsnagStarted && BUGSNAG_API_KEY)

  useEffect(() => {
    if (shouldStartBugsnag) {
      Bugsnag.start({
        onError: function (event) {
          if (!event.originalError || !event.originalError.stack) {
            return
          }

          // Remove all traces of notifyError from stack traces
          event.originalError.stack = event.originalError.stack
            .split('\n')
            .filter((line: string) => !line.includes('at notifyError'))
            .join('\n')
          event.errors[0].stacktrace = event.errors[0].stacktrace.filter(
            (frame) => frame.method !== 'notifyError'
          )
        },
        apiKey: BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()],
        appVersion: RELEASE_VERSION
      })
    }
  }, [shouldStartBugsnag])

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export default BugsnagProvider
