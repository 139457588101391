import React, { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useGlobalModal } from 'components/GlobalModal'
import { CopyIcon } from 'components/icons'

import notifyError from 'utils/errorNotifier'

import { ReactComponent as RetryIcon } from 'images/icon--retry.svg'
import { ReactComponent as ThumbsDownIconFilled } from 'images/icon--thumb-down-filled.svg'
import { ReactComponent as ThumbsDownIcon } from 'images/icon--thumb-down.svg'
import { ReactComponent as ThumbsUpIconFilled } from 'images/icon--thumb-up-filled.svg'
import { ReactComponent as ThumbsUpIcon } from 'images/icon--thumb-up.svg'

import { GlobalChatContextProps } from '../GlobalChatProvider'
import { useGlobalChatTracking } from '../GlobalChatTrackingProvider'
import { useCopyable } from '../hooks/useCopyable'
import { Message } from '../types'
import { FurtherFeedbackModal } from './FurtherFeedbackModal'

const BUTTON_CLASS =
  'cursor-pointer rounded-full border border-rb-gray-100 p-2 transition duration-200 ease-in-out hover:bg-rb-gray-50 active:bg-rb-gray-100'

const FEEDBACK_API_ENDPOINT = '/api/v1/ai/chat/feedback'

const apiHeaders = {
  'content-type': 'application/json',
  'x-csrf-token':
    document?.head?.querySelector('[name~=csrf-token]')?.getAttribute('content') || ''
}

export interface SendFeedbackProps {
  chatId: string
  thumb: 'up' | 'down'
  feedbackMessage?: string
  messageId: string
}

export const sendFeedback = async ({
  chatId,
  messageId,
  thumb,
  feedbackMessage = ''
}: SendFeedbackProps) => {
  const response = await fetch(FEEDBACK_API_ENDPOINT, {
    method: 'POST',
    headers: apiHeaders,
    body: JSON.stringify({
      chat_id: chatId,
      message_id: messageId,
      thumb,
      feedback_message: feedbackMessage
    })
  })

  if (!response.ok) {
    notifyError(
      `Failed to send feedback for aiChat: ${chatId}, with a thumbs ${thumb} and message: ${feedbackMessage}`
    )
  }
}

export interface FeedbackProps {
  chatId: string
  message: Message
  onReload: () => void
  allowReload: boolean
  draftTemplateName?: string
  mode: GlobalChatContextProps['mode']
}

export const Feedback = ({
  chatId,
  message,
  onReload,
  allowReload,
  draftTemplateName,
  mode
}: FeedbackProps) => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const [thumb, setThumb] = useState<'up' | 'down' | null>(null) // 'up' or 'down'
  const { copyBufferToClipboard } = useCopyable()
  const { trackChatRetryClicked, trackChatCopiedText, trackChatFeedbackClicked } =
    useGlobalChatTracking()
  const citedSourcesCount: number = useMemo(() => {
    if (!message || !message.data) return 0

    const hasCitedSources = message.data.find((data) => data.type === 'sources')

    if (!hasCitedSources) return 0

    return message.data
      .flatMap((data) => {
        if (data.type !== 'sources') return []

        return data.sources
      })
      .reduce((acc, cur) => {
        return acc + Number(cur.used)
      }, 0)
  }, [message])
  const thumbUpSelected = thumb === 'up'
  const thumbDownSelected = thumb === 'down'

  const handleRetry = () => {
    trackChatRetryClicked({ chatId, mode: mode.mode })
    onReload()
  }

  const handleCopyMessage = () => {
    copyBufferToClipboard((text) =>
      trackChatCopiedText({
        chatId,
        text,
        citedSourcesCount,
        messageId: message.id,
        draftTemplateName: draftTemplateName
      })
    )
  }

  const handleThumbUp = () => {
    if (thumbUpSelected) return
    setThumb('up')
    sendFeedback({ chatId, messageId: message.id, thumb: 'up' })
    trackChatFeedbackClicked({
      chatId,
      feedbackValue: 'positive'
    })
  }

  const openFeedbackModal = () => {
    openGlobalModal(
      <FurtherFeedbackModal
        chatId={chatId}
        messageId={message.id}
        removeDislike={() => {
          setThumb(null)
          closeGlobalModal()
        }}
        onClose={closeGlobalModal}
        sendFeedback={sendFeedback}
      />,
      {
        className:
          'w-full h-full max-h-full sm:h-full sm:max-h-full lg:h-auto lg:max-h-[calc(100%-80px)] lg:max-w-md lg:rounded-xl',
        containerClass: 'backdrop-blur-sm',
        fullWidth: true
      }
    )
  }

  const handleThumbDown = () => {
    setThumb('down')
    sendFeedback({ chatId, messageId: message.id, thumb: 'down' })
    trackChatFeedbackClicked({
      chatId,
      feedbackValue: 'negative'
    })

    if (thumbDownSelected) {
      openFeedbackModal()
    }
  }

  return (
    <div className="-mt-4 flex items-center justify-between">
      <div className="flex items-center justify-start gap-2">
        {allowReload && (
          <button
            onClick={handleRetry}
            className={twMerge(
              BUTTON_CLASS,
              'flex items-center gap-2 px-4 text-sm font-medium'
            )}
          >
            <RetryIcon width={16} />
            Retry
          </button>
        )}
        <button
          data-dd-action-name="AI Sidekick thumbs up CTA"
          className={BUTTON_CLASS}
          onClick={handleThumbUp}
        >
          {thumbUpSelected ? (
            <ThumbsUpIconFilled width={16} />
          ) : (
            <ThumbsUpIcon width={16} />
          )}
        </button>
        <button
          data-dd-action-name="AI Sidekick thumbs down CTA"
          className={BUTTON_CLASS}
          onClick={handleThumbDown}
        >
          {thumbDownSelected ? (
            <div className="flex items-center gap-2 px-3 text-sm font-medium text-rb-destructive-100">
              <ThumbsDownIconFilled width={16} />
              Send Feedback?
            </div>
          ) : (
            <ThumbsDownIcon width={16} />
          )}
        </button>
        <button
          onClick={handleCopyMessage}
          className={twMerge(
            BUTTON_CLASS,
            'flex items-center gap-2 px-4 text-sm font-medium'
          )}
        >
          <CopyIcon className="w-[16px]" />
          Copy
        </button>
      </div>
    </div>
  )
}
