import * as React from 'react'

import { CheckmarkIcon, WarningIcon } from 'components/icons'

interface IWithValidationIcon {
  isValid?: boolean
  hasError?: boolean
}

export const ValidationIcon = ({ isValid, hasError }: IWithValidationIcon) => {
  const icon = hasError ? <WarningIcon /> : isValid ? <CheckmarkIcon /> : null

  if (!icon) return null

  return <div className="absolute top-2.5 right-2 z-10 h-4 w-4">{icon}</div>
}

const withValidationIcon = <P extends object>(
  Element: React.ComponentType<P>
): React.FC<P & IWithValidationIcon> => {
  const WithValidationIcon = ({ isValid, hasError, ...props }: IWithValidationIcon) => (
    <div className="relative">
      <ValidationIcon isValid={isValid} hasError={hasError} />
      <Element {...(props as P)} />
    </div>
  )
  return WithValidationIcon
}

export default withValidationIcon
