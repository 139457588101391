import defaultAvatar from 'default_avatar.svg'
import React, { useRef, useState } from 'react'
import Cropper from 'react-cropper'

import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import { useUpdateUserMutation } from 'gql'

import { useExternalStylesheet } from 'hooks/useExternalStylesheet'
import useMediaQuery from 'hooks/useMediaQuery'

import notifyError from 'utils/errorNotifier'

interface AvatarPickerProps {
  avatarUrl?: string
  onCancel: () => void
  onDone: (avatarUrl?: string) => void
}

const AvatarPicker = ({
  onCancel,
  onDone,
  avatarUrl: avatarUrlProp
}: AvatarPickerProps) => {
  const cropperRef = useRef<HTMLImageElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [avatarUrl, setAvatarUrl] = useState<string>(avatarUrlProp || defaultAvatar)
  const [processing, setProcessing] = useState(false)
  const [shouldRemove, setShouldRemove] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [updateUser] = useUpdateUserMutation()
  useExternalStylesheet(
    'https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.6.1/cropper.min.css'
  )

  const isSmallerTabletCustomSize = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)

  const genericErrorMsg =
    'There was an error while uploading this image. Please try again'

  const userHasAvatar = avatarUrl && !avatarUrl.includes('default_avatar')

  const chooseFile = (
    e:
      | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    fileInputRef.current?.click()
    e.currentTarget.blur()
  }

  const removePhoto = (
    e:
      | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
      | React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    setAvatarUrl(defaultAvatar)
    setReadyToSave(true)
    setShouldRemove(true)
    e.currentTarget.blur()
  }

  const sendRemoveRequest = async () => {
    try {
      await updateUser({
        variables: {
          input: {
            avatarUrl: ''
          }
        }
      })

      setErrorMsg(null)
      onDone()
    } catch (e) {
      setErrorMsg(genericErrorMsg)
      notifyError(e)
    }
  }

  const saveAvatar = async (dataUrl: string) => {
    try {
      setProcessing(true)
      const response = await updateUser({
        variables: {
          input: {
            avatarUrl: dataUrl
          }
        }
      })

      setErrorMsg(null)
      onDone(response.data?.updateUser?.user?.avatarUrl)
    } catch (e) {
      setErrorMsg(genericErrorMsg)
      notifyError(e)
    }
  }

  const onChangeAvatar = (
    e: React.ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    e.preventDefault()

    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    const reader = new window.FileReader()

    reader.onload = () => {
      setShouldRemove(false)
      setReadyToSave(true)
      setAvatarUrl((reader.result || '') as string)
      const imageElement: any = cropperRef?.current
      imageElement?.cropper.enable()
    }

    if (files instanceof FileList) {
      reader.readAsDataURL(files[0])
    }
  }

  const submitAvatar = () => {
    if (shouldRemove) {
      sendRemoveRequest()
      return
    }

    const imageElement: any = cropperRef?.current
    const dataUrl = imageElement?.cropper.getCroppedCanvas().toDataURL()
    // Verify that there is imageData (that the user didn't choose a crop
    // size so small there isn't anything left after cropping)
    const imageData = dataUrl && dataUrl.split(',')
    if (imageData && imageData[1]) {
      saveAvatar(dataUrl)
    } else {
      setErrorMsg(genericErrorMsg)
    }
  }

  return (
    <div
      id="imageInput"
      className={`uk-width-expand pb-2.5 xs:pb-4 md:pb-8 ${
        !readyToSave || shouldRemove ? 'cropper-no-border' : ''
      }`}
    >
      <Cropper
        ref={cropperRef}
        style={{ width: '100%', height: 400 }}
        className="avatar-cropper"
        src={avatarUrl}
        background={false}
        center={false}
        checkCrossOrigin={false}
        aspectRatio={1 / 1}
        guides={false}
        highlight={false}
        rotatable={false}
      />

      <div className="sm:text-base--mobile uk-margin-small-top uk-margin-small-bottom text-m-medium sm:text-base">
        <div className="text-rb-gray-400">
          Choose a file to upload for your Reforge profile photo.
        </div>
        {errorMsg && (
          <div className="text-m-small text-rb-destructive-100 sm:text-m-medium">
            {errorMsg}
          </div>
        )}
        <input
          hidden
          ref={fileInputRef}
          type="file"
          accept="image/png,image/jpeg,image/gif,image/bmp"
          onChange={onChangeAvatar}
          data-test="avatar-picker-file-input"
        />
      </div>

      <div className="uk-grid-collapse" uk-grid="margin: mt-7">
        <div
          className={`flex items-center justify-center ${
            isSmallerTabletCustomSize ? 'uk-width-1-1' : ''
          }`}
        >
          <Button
            onClick={chooseFile}
            size="x-small"
            color="teal"
            shape="rounded-full"
            className="min-w-[140px]"
          >
            Choose File
          </Button>

          {userHasAvatar && (
            <Button
              onClick={removePhoto}
              dataTest="remove-photo-button"
              variant="outline"
              size="x-small"
              shape="rounded-full"
              className="ml-5 min-w-[140px]"
            >
              Remove Photo
            </Button>
          )}
        </div>
        <div className="ml-auto flex items-center justify-center">
          <Button
            size="small"
            variant="text-only"
            disabled={processing}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            disabled={processing || !readyToSave}
            isLoadingSpinner={processing}
            onClick={submitAvatar}
            size="small"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AvatarPicker
