import Button from 'components/Button'
import { Modal, ModalContent, ModalHeader } from 'components/Modal'
import WarningIcon from 'components/icons/WarningIcon'

import useModalTracking from 'hooks/useModalTracking'

interface ILeaveCheckoutConfirmationModal {
  isOpen: boolean
  isSmallScreen: boolean
  setIsOpen: (arg0: boolean) => void
  setOpenPreviousModal: (arg0: boolean) => void
  setInitiateFlow: (arg0: boolean) => void
}

export default function LeaveCheckoutConfirmationModal({
  isOpen,
  isSmallScreen,
  setIsOpen,
  setOpenPreviousModal,
  setInitiateFlow
}: ILeaveCheckoutConfirmationModal) {
  useModalTracking(isOpen, {
    modal_group: 'dunning',
    modal_name: 'leave checkout confirm'
  })

  const handleClose = () => {
    setInitiateFlow(false)
    setIsOpen(false)
    setOpenPreviousModal(true)
  }

  const handleConfirm = () => {
    setInitiateFlow(false)
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-screen-lg pt-9"
      scrollContent={false}
      header={false}
    >
      <div className="flex flex-col items-center">
        <ModalHeader>
          <WarningIcon className="h-11 w-11 fill-current text-black" />
        </ModalHeader>
        <ModalContent className="w-full py-3 md:py-10" scrollContent={false}>
          <p className="text-center">Are you sure you want to leave checkout?</p>
          <div className={`flex ${isSmallScreen ? 'justify-center' : 'justify-end'}`}>
            <Button
              onClick={handleClose}
              size={isSmallScreen ? 'small' : 'medium'}
              variant="text-only"
              className={isSmallScreen ? 'mr-5 border-2 border-rb-gray-500' : ''}
            >
              {isSmallScreen ? 'No' : 'Back to Checkout'}
            </Button>
            <Button
              onClick={handleConfirm}
              size={isSmallScreen ? 'small' : 'medium'}
              shape="rounded"
              className="border-rb-blue-100 bg-rb-blue-100"
            >
              {isSmallScreen ? 'Yes' : 'Leave Checkout'}
            </Button>
          </div>
        </ModalContent>
      </div>
    </Modal>
  )
}
