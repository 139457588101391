import { FC, createContext, useCallback, useContext, useState } from 'react'

import useLocalStorage from 'hooks/useLocalStorage'

type KeyValuePair = Record<string, unknown>
interface LocalStorageGlobalContextType {
  localStorageValues: KeyValuePair
  setLocalStorageValue(key: string, value: unknown): void
}
const LocalStorageGlobalContext = createContext<LocalStorageGlobalContextType>({
  localStorageValues: {},
  setLocalStorageValue: () => {}
})

export const LocalStorageGlobalContextProvider: FC = ({ children }) => {
  const [localStorageValues, setLocalStorageValues] = useState<KeyValuePair>({})

  const setLocalStorageValue = useCallback(
    (key: string, value: unknown) => {
      setLocalStorageValues((localStorageValues) => ({
        ...localStorageValues,
        [key]: value
      }))
    },
    [setLocalStorageValues]
  )

  return (
    <LocalStorageGlobalContext.Provider
      value={{ localStorageValues: localStorageValues, setLocalStorageValue }}
    >
      {children}
    </LocalStorageGlobalContext.Provider>
  )
}

export default function useLocalStorageGlobal<T>(key: string, initialValue: T) {
  const [value, setValue] = useLocalStorage(key, initialValue)
  const { setLocalStorageValue, localStorageValues } = useContext(
    LocalStorageGlobalContext
  )
  const setGlobalValue = useCallback(
    (value: T) => {
      setLocalStorageValue(key, value)
      setValue(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, setLocalStorageValue, key]
  )

  const globalValue =
    typeof localStorageValues[key] !== 'undefined' ? localStorageValues[key] : value

  return [globalValue as T, setGlobalValue] as const
}
