import PropTypes from 'prop-types'

import { SelectableTag } from 'components/Tag'

const TagQuestion = ({
  identifier,
  question,
  choices,
  selections = {},
  onSelectionChange,
  noTopPadding = false,
  singularChoice
}) => {
  const handleChoiceChange = (choice, val) => {
    if (choice.choice === singularChoice && val === true) {
      const newSelections = {}
      newSelections[singularChoice] = true
      onSelectionChange(newSelections)
      return
    }

    const newSelections = { ...selections }
    delete newSelections[singularChoice]
    if (val === false) {
      delete newSelections[choice.choice]
    } else {
      newSelections[choice.choice] = val
    }
    onSelectionChange(newSelections)
  }

  return (
    <div className={`pt-6 ${noTopPadding ? 'pt-0' : ''}`}>
      <div className="text-base font-medium text-rb-gray-400">{question}</div>
      <div className="mt-2 flex cursor-pointer flex-wrap">
        {choices.map((choice, idx) => (
          <SelectableTag
            className="mr-2.5 mb-2.5"
            key={`${identifier}_choice${idx}`}
            text={choice.choice}
            onChange={(val) => handleChoiceChange(choice, val)}
            selected={selections[choice.choice]}
          />
        ))}
      </div>
    </div>
  )
}

TagQuestion.propTypes = {
  identifier: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  selections: PropTypes.object,
  onSelectionChange: PropTypes.func.isRequired,
  singularChoice: PropTypes.string,
  noTopPadding: PropTypes.bool
}

export default TagQuestion
