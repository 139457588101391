import React from 'react'
import { useAtBottom, useScrollToBottom } from 'react-scroll-to-bottom'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronDownThinIcon } from 'images/chevron-down.svg'

interface ScrollToBottomButtonProps {
  extraClasses?: string
}

const ScrollToBottomButton = React.memo(({ extraClasses }: ScrollToBottomButtonProps) => {
  const scrollToBottom = useScrollToBottom()
  const [atBottom] = useAtBottom()

  return (
    <button
      className={cn(
        'chromatic-ignore absolute right-6 bottom-4 z-10 flex animate-pulse cursor-pointer items-center justify-center gap-2 rounded-full border border-rb-gray-100 bg-white px-2 py-3 text-2xl text-rb-black shadow-md',
        {
          hidden: atBottom
        },
        extraClasses
      )}
      aria-label="scroll to bottom"
      onClick={scrollToBottom}
    >
      <ChevronDownThinIcon width={20} />
    </button>
  )
})

ScrollToBottomButton.displayName = 'ScrollToBottomButton'

export default ScrollToBottomButton
