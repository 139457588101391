export const parseGuideSlug = (pathname: string) => {
  return parseSlugFor('guides', pathname)
}

export const parseArtifactSlug = (pathname: string) => {
  return parseSlugFor('artifacts', pathname)
}

export const parseProgramSlug = (pathname: string) => {
  return parseSlugFor('programs', pathname)
}

type PathEntityType = 'courses' | 'artifacts' | 'guides' | 'programs'

// Add your entityName here if it follows the pattern of entity/entitySlug, ex courses/:courseSlug
export const parseSlugFor = (entityName: PathEntityType, pathname: string) => {
  const parts = pathname.split('/')
  const entityNameIndex = parts.indexOf(entityName)
  if (entityNameIndex !== -1 && parts.length > entityNameIndex + 1) {
    return parts[entityNameIndex + 1]
  }

  return ''
}

export const parseArtifactSlugFromPath = () => {
  const pathname = window.location.pathname
  return parseArtifactSlug(pathname)
}

export const parseSlugFromPathFor = (entityName: PathEntityType) => {
  const pathname = window.location.pathname
  return parseSlugFor(entityName, pathname)
}
