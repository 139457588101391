import { cn } from 'utils/tailwind'

import { ReactComponent as DiamondIcon } from 'images/icon--diamond-premium.svg'

interface PremiumBadgeProps {
  className?: string
}

const PremiumBadge = ({ className }: PremiumBadgeProps) => {
  return (
    <div
      className={cn(
        'inline-flex items-center justify-center rounded-full bg-rb-blue-50 py-2 px-5 gap-2',
        className
      )}
    >
      <DiamondIcon className="h-4 w-4 shrink-0" />
      <span className="text-sm leading-[1.5] whitespace-nowrap text-ellipsis overflow-hidden">
        Member content
      </span>
    </div>
  )
}

export default PremiumBadge
