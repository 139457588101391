import React from 'react'

import AutosuggestQuestion from 'domains/Questioner/AutosuggestQuestion'
import TextAreaQuestion from 'domains/Questioner/TextAreaQuestion'

import { SKILLS } from 'constants/onboarding'
import { ASK_ME_ABOUT_TEXT_MAX_CHARACTERS } from 'constants/profile'

import { ProfileEditModalUserProfileFieldsFragment } from 'gql'

import {
  Selection,
  selectionsToSortedString,
  stringToSelections
} from 'utils/stringUtils'

interface BioTabProps {
  autofocusField?: string
  profile: ProfileEditModalUserProfileFieldsFragment
  setProfile: React.Dispatch<
    React.SetStateAction<ProfileEditModalUserProfileFieldsFragment>
  >
}
const BioTab = ({ autofocusField, profile, setProfile }: BioTabProps) => {
  return (
    <div data-test="member-profile-edit-bio-tab">
      <div className="pt-6 pb-2 text-base font-medium sm:pt-4 sm:pb-3 tl:pt-6 tl:pb-3 3xl:pt-10 3xl:pb-4">
        Ask me about
      </div>
      <AutosuggestQuestion
        id="edit-profile--bio-and-experience"
        items={SKILLS}
        onSelectionChange={(selections: Selection) =>
          setProfile((existingProfile) => ({
            ...existingProfile,
            areasOfExpertiseTags: selectionsToSortedString(selections)
          }))
        }
        placeholder="Add at least one topic"
        selections={stringToSelections(profile.areasOfExpertiseTags)}
      />
      <TextAreaQuestion
        autofocus={autofocusField === 'ask_me_about_textarea'}
        className="flex-1"
        initialResponse={profile.askMeAboutText}
        maxChars={ASK_ME_ABOUT_TEXT_MAX_CHARACTERS}
        onChange={(val: any) =>
          setProfile((existingProfile) => ({ ...existingProfile, askMeAboutText: val }))
        }
        placeholder="Tell us more about your experience in these areas"
        dataTest="member-profile-edit-modal-experience-input"
      />
    </div>
  )
}

export default BioTab
