import { useEffect } from 'react'
import { ToastOptions } from 'react-toastify'

import { displayToast } from 'components/Toast'

import notifyError from 'utils/errorNotifier'

export type NoticeType = 'notice' | 'warning' | 'error' | 'alert'

const themeByType: Record<NoticeType, ToastOptions['theme']> = {
  notice: 'light',
  warning: 'colored',
  error: 'colored',
  alert: 'colored'
}

const toastType: Record<NoticeType, ToastOptions['type']> = {
  notice: 'default',
  warning: 'warning',
  error: 'error',
  alert: 'error'
}

export interface IFlashMessage {
  message: string
  type: keyof typeof toastType
}

export default function useFlashMessage() {
  useEffect(() => {
    try {
      const storedFlashMessage = window.sessionStorage.getItem('rf-flash-message')
      if (storedFlashMessage) {
        const { message, type = 'notice' }: IFlashMessage = JSON.parse(storedFlashMessage)
        displayToast(message, {
          theme: themeByType[type],
          type: toastType[type]
        })
        window.sessionStorage.removeItem('rf-flash-message')
      }
    } catch (err) {
      notifyError(err)
    }
  }, [])
}
