import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import PremiumBadge from 'components/PremiumBadge'
import { SelectableTag } from 'components/Tag'

import { useSuggestedPromptsQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { cn } from 'utils/tailwind'

import { ReactComponent as RewardIcon } from 'images/icon--reward-stars.svg'

import { USER_MESSAGE_BUBBLE_CLASSES } from '../Chat/helpers'

interface SuggestedPromptsProps {
  pageLocation: string
  showPremiumBadge?: boolean
  showBetaLabel?: boolean
  fullWidth?: boolean
}

export function SuggestedPrompts({
  pageLocation,
  showPremiumBadge,
  showBetaLabel = true,
  fullWidth = true
}: SuggestedPromptsProps) {
  const { showGuideAiSuggestions, aiBeta, freeAi } = useFeatureFlags()
  const { pathname } = useLocation()
  const { openChatAndSendMessage, resetMode } = useGlobalChat()
  const { loading, data } = useSuggestedPromptsQuery({
    variables: {
      path: pathname
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  if (!showGuideAiSuggestions || (!aiBeta && !freeAi)) {
    return null
  }

  if (loading) {
    return <SuggestedPromptsLoading />
  }

  const prompts = data?.suggestedPrompts || []

  return (
    <div>
      {showBetaLabel && (
        <>
          <div className="font-semibold text-m-large mb-2">
            Apply this to you <RewardIcon className="inline-block w-[14px]" />{' '}
            <SelectableTag text="Beta" className="ml-2 cursor-default" />
          </div>
          <p className="text-m-medium mb-3 leading-5">
            Get started with suggested prompts, tailored to you:
          </p>
        </>
      )}
      <div
        className={cn('flex gap-2 flex-col', {
          'flex items-start': !fullWidth
        })}
      >
        {prompts.map((prompt) => (
          <ButtonPrompt
            key={prompt}
            onClick={() => {
              resetMode()
              openChatAndSendMessage(prompt, pageLocation, { isSuggestedFollowup: true })
            }}
          >
            {prompt}
            {' ->'}
          </ButtonPrompt>
        ))}
      </div>
      {showPremiumBadge && <PremiumBadge className="mt-6" />}
    </div>
  )
}

export function SuggestedPromptsLoading() {
  return (
    <div className="mt-4 w-full animate-pulse py-2">
      <div className="flex w-full gap-y-2">
        <div className="flex w-5/6 flex-wrap items-center gap-x-4 gap-y-4 overflow-hidden">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className={cn(
                USER_MESSAGE_BUBBLE_CLASSES,
                'ml-0 flex h-10 w-full grow rounded-lg bg-rb-gray-50'
              )}
            ></div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function ButtonPrompt({
  onClick,
  children,
  className
}: {
  onClick?: () => void
  children: ReactNode
  className?: string
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'rounded-lg border border-rb-teal-50 bg-rb-teal-50/40 px-4 py-[7px] text-left text-base',
        className
      )}
    >
      {children}
    </button>
  )
}
