import React, { createContext, useContext, useState } from 'react'

interface LeftSidebarContextState {
  isLeftSidebarOpen: boolean
  setIsLeftSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const LeftSidebarContext = createContext<LeftSidebarContextState>({
  isLeftSidebarOpen: false,
  setIsLeftSidebarOpen: () => false
})

interface LeftSidebarProviderProps {
  children: React.ReactNode
}

export function LeftSidebarProvider({ children }: LeftSidebarProviderProps) {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false)

  return (
    <LeftSidebarContext.Provider value={{ isLeftSidebarOpen, setIsLeftSidebarOpen }}>
      {children}
    </LeftSidebarContext.Provider>
  )
}

export function useLeftSidebar() {
  return useContext(LeftSidebarContext)
}
