import React from 'react'

import TagQuestion from 'domains/Questioner/TagQuestion'
import TextAreaQuestion from 'domains/Questioner/TextAreaQuestion'
import {
  BUSINESS_MODEL_QUESTION,
  COMPANY_AUDIENCE_QUESTION
} from 'domains/Questioner/constants'

import { COMPANY_PRODUCT_TEXT_MAX_CHARACTERS } from 'constants/profile'

import {
  OnboardingQuestionPartsFragment,
  ProfileEditModalUserProfileFieldsFragment
} from 'gql'

import notifyError from 'utils/errorNotifier'
import {
  Selection,
  selectionsToSortedString,
  stringToSelections
} from 'utils/stringUtils'

interface ProductTabProps {
  questions: OnboardingQuestionPartsFragment[]
  profile: ProfileEditModalUserProfileFieldsFragment
  setProfile: React.Dispatch<
    React.SetStateAction<ProfileEditModalUserProfileFieldsFragment>
  >
}

const ProductTab = ({ questions, profile, setProfile }: ProductTabProps) => {
  if (questions === undefined || questions.length < 2) {
    return null
  }

  const businessModelQuestion = questions.find(
    (q) => q.question === BUSINESS_MODEL_QUESTION
  )
  const companyAudienceQuestion = questions.find(
    (q) => q.question === COMPANY_AUDIENCE_QUESTION
  )

  if (!businessModelQuestion || !companyAudienceQuestion) {
    const error = new Error('Unable to find product questions')
    notifyError(error)
    return null
  }

  return (
    <div className="pt-2" data-test="member-profile-edit-product-tab">
      <TagQuestion
        identifier="business-model"
        question={businessModelQuestion.question}
        choices={businessModelQuestion.choices}
        selections={stringToSelections(profile.businessModelTags)}
        onSelectionChange={(selections: Selection) => {
          setProfile(
            (existingProfile) =>
              existingProfile && {
                ...profile,
                businessModelTags: selectionsToSortedString(selections)
              }
          )
        }}
        noTopPadding
      />
      <TagQuestion
        identifier="company-audience"
        question={companyAudienceQuestion.question}
        choices={companyAudienceQuestion.choices}
        selections={stringToSelections(profile.companyAudienceTags)}
        onSelectionChange={(selections: Selection) => {
          setProfile(
            (existingProfile) =>
              existingProfile && {
                ...existingProfile,
                companyAudienceTags: selectionsToSortedString(selections)
              }
          )
        }}
      />
      <TextAreaQuestion
        autofocus
        className="mt-4 md:mt-6"
        placeholder="Anything you’d like to add about your company’s product and what you do there?"
        initialResponse={profile.companyProductText}
        maxChars={COMPANY_PRODUCT_TEXT_MAX_CHARACTERS}
        onChange={(val: string) =>
          setProfile(
            (existingProfile) =>
              existingProfile && { ...profile, companyProductText: val }
          )
        }
        dataTest="member-profile-edit-modal-product-input"
      />
    </div>
  )
}

export default ProductTab
