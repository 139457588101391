import { AddressElement } from '@stripe/react-stripe-js'
import type {
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions
} from '@stripe/stripe-js'
import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { FormContext, useForm } from 'react-hook-form'

import Button from 'components/Button'
import StyledCheckbox from 'components/StyledCheckbox'

import { onSpaceKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

import SavedTaxId from './components/SavedTaxId'
import TaxIdForm, {
  type Value as TaxIdFormValue,
  taxIdIsNotRequiredSchema,
  taxIdIsRequiredSchema
} from './components/TaxIdForm'
import TrackingContext from './contexts/TrackingContext'
import {
  BillingInfoFormValue,
  FormCompleteness,
  FormState,
  FormValue,
  StripeTaxId
} from './types'
import { isUSAddress } from './utils'

/**
 * Simple checkbox for isB2BPurchase
 */
type CheckboxWithLabelProps = {
  dataTest?: string
  renderLabel: () => React.ReactNode
  checked: boolean
  onChange: () => void
}

function CheckboxWithLabel({
  dataTest,
  renderLabel,
  checked,
  onChange
}: CheckboxWithLabelProps) {
  return (
    <label
      className="flex cursor-pointer items-center"
      data-test={dataTest}
      tabIndex={0}
      onKeyDown={onSpaceKeyPress((event) => {
        // Prevent the nearest scrollable frame from scrolling
        // when checking or unchecking via 'Space'
        event.preventDefault()
      })}
      onKeyUp={onSpaceKeyPress(onChange)}
      role="checkbox"
      aria-checked={checked}
    >
      <StyledCheckbox checked={checked} />
      <span>{renderLabel()}</span>
      <input type="checkbox" className="hidden" checked={checked} onChange={onChange} />
    </label>
  )
}

/**
 * Reducer to hold component state
 */
type Action =
  | {
      type: 'BILLING_ADDRESS_FORM_CHANGED'
      payload: {
        value: BillingInfoFormValue
        isComplete: boolean
      }
    }
  | {
      type: 'TAX_ID_FORM_VALUE_CHANGED'
      payload: { value: TaxIdFormValue }
    }
  | {
      type: 'TAX_ID_FORM_VALIDATION_CHANGED'
      payload: { isValid: boolean }
    }
  | {
      type: 'IS_B2B_PURCHASE_CHECKBOX_VALUE_CHANGED'
      payload: { value: boolean }
    }
  | {
      type: 'DEFAULT_VALUES_LOADED'
      payload: { defaultValues: BillingInfoFormProps['defaultValues'] }
    }

const initialState: FormState = {
  isB2BPurchase: false,
  billingAddress: {
    value: null,
    isComplete: false
  },
  taxId: {
    value: null,
    isValid: false
  }
}

const reducer = (state: FormState, action: Action): FormState => {
  switch (action.type) {
    case 'BILLING_ADDRESS_FORM_CHANGED': {
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          value: action.payload.value,
          isComplete: action.payload.isComplete
        },
        isB2BPurchase: isUSAddress(action.payload.value.address)
          ? false
          : state.isB2BPurchase
      }
    }
    case 'TAX_ID_FORM_VALUE_CHANGED': {
      return {
        ...state,
        taxId: {
          ...state.taxId,
          value: action.payload.value
        }
      }
    }
    case 'TAX_ID_FORM_VALIDATION_CHANGED': {
      return {
        ...state,
        taxId: {
          ...state.taxId,
          isValid: action.payload.isValid
        }
      }
    }
    case 'IS_B2B_PURCHASE_CHECKBOX_VALUE_CHANGED': {
      return {
        ...state,
        isB2BPurchase: action.payload.value
      }
    }

    case 'DEFAULT_VALUES_LOADED': {
      return {
        ...state,
        isB2BPurchase: !!action.payload.defaultValues?.taxId
      }
    }

    default: {
      const exhaustiveCheckAction: never = action
      console.log('Unhandled action type', exhaustiveCheckAction, state)
      return state
    }
  }
}

/**
 * Helper for convert component state to form value sent to parent
 */
function formStateToValue(formState: FormState): FormValue {
  return {
    billingAddress: formState.billingAddress.value,
    isB2BPurchase: formState.isB2BPurchase,
    taxId: formState.taxId.value
  }
}

/**
 * Component
 */
type BillingInfoFormProps = {
  className?: string
  defaultValues?: StripeAddressElementOptions['defaultValues'] & {
    taxId?: StripeTaxId
  }
  onChange: (
    value: FormValue,
    isComplete: { billingAddress: boolean; taxId: boolean }
  ) => void
  onSubmit?: (value: FormValue) => void
  onCancel?: () => void
  onDeleteTaxId: () => any
  isTaxIdDeleteInProgress: boolean
  submitLoading?: boolean
  isTaxIdRequired?: boolean
  shouldCancelOnEscape?: boolean
}

export default function BillingInfoForm({
  className,
  defaultValues,
  onChange,
  onSubmit,
  onCancel,
  onDeleteTaxId = () => {},
  isTaxIdRequired = false,
  isTaxIdDeleteInProgress = false,
  submitLoading = false,
  shouldCancelOnEscape
}: BillingInfoFormProps) {
  const trackingContext = useContext(TrackingContext)
  const [state, dispatch] = useReducer(reducer, initialState)

  /**
   * Notify parent of form state changes
   */
  useEffect(
    () =>
      onChange(formStateToValue(state), {
        billingAddress: state.billingAddress.isComplete,
        taxId: state.taxId.isValid
      } as FormCompleteness),
    [onChange, state]
  )

  /**
   * Ingest defaultValues into component state
   */

  // We only care about defaultValues on initial mount.
  /* eslint-disable react-hooks/exhaustive-deps */
  const memoDefaultValues = useMemo(() => defaultValues, [])
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch({
      type: 'DEFAULT_VALUES_LOADED',
      payload: { defaultValues: memoDefaultValues }
    })
  }, [memoDefaultValues])

  /**
   * Set up form (validation) for tax ID
   *
   * Enables validate on "submit"
   */
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    validationSchema:
      isTaxIdRequired && !defaultValues?.taxId
        ? taxIdIsRequiredSchema
        : taxIdIsNotRequiredSchema
  })

  /**
   * Prep event handlers
   */
  const handleAddressChange = useCallback((event: StripeAddressElementChangeEvent) => {
    dispatch({
      type: 'BILLING_ADDRESS_FORM_CHANGED',
      payload: {
        value: event.value,
        isComplete: event.complete
      }
    })
  }, [])

  const handleDeleteTaxId = useCallback(() => onDeleteTaxId(), [onDeleteTaxId])

  const handleIsB2BPurchaseChange = useCallback(() => {
    const nextstate = !state.isB2BPurchase

    if (nextstate === false && defaultValues?.taxId) {
      handleDeleteTaxId()
    }

    dispatch({
      type: 'IS_B2B_PURCHASE_CHECKBOX_VALUE_CHANGED',
      payload: { value: !state.isB2BPurchase }
    })
  }, [defaultValues?.taxId, handleDeleteTaxId, state.isB2BPurchase])

  const handleTaxIdChange = useCallback((value) => {
    dispatch({ type: 'TAX_ID_FORM_VALUE_CHANGED', payload: { value } })
  }, [])

  const handleSubmit = useCallback(() => {
    methods.handleSubmit(() => {
      if (trackingContext.location) {
        trackCtaClicked({
          cta_location: trackingContext.location,
          cta_type: 'button',
          text: 'save billing address'
        })
      }

      onSubmit?.(formStateToValue(state))
    })()
  }, [methods, trackingContext.location, onSubmit, state])

  /**
   * Render the things
   */
  return (
    <div className={className}>
      <AddressElement
        options={{
          mode: 'billing',
          defaultValues,
          autocomplete: {
            mode: 'google_maps_api',
            apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY || ''
          }
        }}
        onChange={handleAddressChange}
        onEscape={() => shouldCancelOnEscape && onCancel?.()}
      />

      <FormContext {...methods}>
        <div className="my-5">
          {state.billingAddress.value?.address &&
          !isUSAddress(state?.billingAddress?.value?.address) ? (
            <CheckboxWithLabel
              renderLabel={() => "I'm making this purchase on behalf of a business."}
              checked={state.isB2BPurchase}
              onChange={handleIsB2BPurchaseChange}
            />
          ) : null}

          {state.isB2BPurchase || defaultValues?.taxId ? (
            <div className="mt-5">
              {defaultValues?.taxId ? (
                <SavedTaxId
                  taxId={defaultValues?.taxId}
                  onDelete={handleDeleteTaxId}
                  deleteInProgress={isTaxIdDeleteInProgress}
                />
              ) : (
                <TaxIdForm
                  isRequired={isTaxIdRequired}
                  onChange={handleTaxIdChange}
                  onValidation={(isValid) =>
                    dispatch({
                      type: 'TAX_ID_FORM_VALIDATION_CHANGED',
                      payload: { isValid }
                    })
                  }
                />
              )}
            </div>
          ) : null}
        </div>
      </FormContext>

      {(onSubmit || onCancel) && (
        <div className="mt-8 flex items-center">
          {onSubmit && (
            <Button
              size="small"
              disabled={submitLoading || !state.billingAddress.isComplete}
              onClick={handleSubmit}
              isLoadingSpinner={submitLoading}
            >
              Save
            </Button>
          )}

          {onCancel &&
            !!defaultValues?.name &&
            !!defaultValues?.address &&
            (isTaxIdRequired ? !!defaultValues?.taxId : true) && (
              <Button
                variant="text-only"
                onClick={onCancel}
                className="ml-2"
                size="small"
              >
                Cancel
              </Button>
            )}
        </div>
      )}
    </div>
  )
}
