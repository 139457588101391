import { Popover } from '@headlessui/react'
import { toMdast } from 'hast-util-to-mdast'
import { directiveToMarkdown } from 'mdast-util-directive'
import { gfmToMarkdown } from 'mdast-util-gfm'
import { toMarkdown } from 'mdast-util-to-markdown'
import React, { useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactMarkdown, { Options } from 'react-markdown'
import { Link } from 'react-router-dom-v5-compat'
import remarkDirective from 'remark-directive'
import remarkDirectiveRehype from 'remark-directive-rehype'
import remarkGfm from 'remark-gfm'
import supersub from 'remark-supersub'

import ErrorBoundary from 'components/ErrorBoundary'
import { Tag } from 'components/Tag'
import Tooltip from 'components/Tooltip/Tooltip'

import { Message } from 'hooks/ai/useChat'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { cn } from 'utils/tailwind'
import { trackAiChatSourceClicked } from 'utils/tracking/analytics'

import { ReactComponent as ChevronDown } from 'images/icon--chevron-down-thin.svg'
import reforgeSymbol from 'images/reforge-logo-symbol.svg'

import { markdownToHtml } from '../helpers'
import { useCopyable } from '../hooks/useCopyable'
import { ArtifactsData, SourcesData } from '../types'
import GeneratedDraftCta from './GeneratedDraftCta'
import { ASSISTANT_MESSAGE_BUBBLE_CLASSES, USER_MESSAGE_BUBBLE_CLASSES } from './helpers'

function SourceLinkRenderer(props: any) {
  let path = props.href
  try {
    const url = new URL(props.href)
    path = url.pathname
  } catch (e) {
    // do nothing
  }

  return (
    <Link
      to={path}
      rel="noopener noreferrer"
      className="rounded bg-rb-teal-50 px-[0.2rem] py-[0.1rem] text-rb-teal-600"
      {...props}
    >
      {props.children}
    </Link>
  )
}

// the draft node
const markdownAstToString = (node: any) => {
  return toMarkdown(node, {
    extensions: [directiveToMarkdown(), gfmToMarkdown()],
    // Add support for GFM (GitHub Flavored Markdown) features
    listItemIndent: 'one',
    bullet: '-',
    rule: '-'
  })
}

type MessageCardProps = {
  chatId?: string
  message: Message
  onSourceLinkClick?: () => void
  onGeneratedDraftCtaClick?: ({
    message,
    htmlString,
    title
  }: {
    message: Message
    htmlString: string
    title: string
  }) => void
  isLoading?: boolean
  isLast?: boolean
}

export function useMessageCard({
  message,
  onSourceLinkClick,
  onGeneratedDraftCtaClick,
  chatId
}: MessageCardProps) {
  const content = useMemo(
    () =>
      message.content.replaceAll('\\n', '\n').replaceAll('【', '^').replaceAll('】', '^'),
    [message.content]
  )

  const artifacts: ArtifactsData['artifacts'] = useMemo(
    () => message.data?.find((d) => d.type === 'artifacts')?.artifacts ?? [],
    [message.data]
  )

  const sources: SourcesData['sources'] = useMemo(() => {
    const dataSources = message.data?.find((d) => d.type === 'sources')?.sources ?? []
    const usedSources = dataSources.filter(
      (source: SourcesData['sources'][0]) => content.indexOf(`^${source.idx}^`) !== -1
    )
    return usedSources
  }, [message.data, content])

  const sourcesCited: boolean = useMemo(() => {
    return !!sources.length
  }, [sources])

  const components = useMemo(
    () =>
      ({
        a: SourceLinkRenderer,
        draft: ({ title, node }: { title: string; node: any }) => {
          return (
            <GeneratedDraftCta
              onClick={async () => {
                // we converted the node to hast with remark-rehype-directive (html nodes) to enable custom directives
                // now we need to reconvert to mdast (markdown nodes) and then parse to get the markdown string
                const mdString = markdownAstToString(toMdast(node))
                const htmlString = await markdownToHtml(mdString)
                onGeneratedDraftCtaClick?.({
                  message,
                  htmlString: htmlString,
                  title: title
                })
              }}
              label={title}
              isLoading={false} // TODO: try to get loading propagating here.
            />
          )
        },
        sup: (props) => {
          const citationNumber = Number(props.children)

          const sourceData = sources.find((s) => s.idx === citationNumber)
          // use the index of the source in the sources array as the citation number
          const sourceIdx = sources.findIndex((s) => s.idx === citationNumber)

          if (!sourceData) {
            return null
          }

          return (
            <Citation
              citation={`${sourceIdx + 1}`}
              onClick={() => {
                trackAiChatSourceClicked({
                  chat_session_id: chatId,
                  content_id: sourceData.referenceId,
                  content_title: sourceData.title,
                  content_type: sourceData.type,
                  content_sanity_id:
                    sourceData.type === 'guide' ? sourceData.referenceId : undefined
                })
                onSourceLinkClick?.()
              }}
              sourceData={sourceData}
            />
          )
        }
      }) as Options['components'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sources]
  )

  return { content, sources, sourcesCited, components, artifacts }
}

export function MessageCard({
  chatId,
  message,
  onSourceLinkClick,
  onGeneratedDraftCtaClick,
  isLoading,
  isLast
}: MessageCardProps) {
  const [showSourceLinks, setShowSourceLinks] = useState(false)
  const { aiDebug } = useFeatureFlags()
  const { content, sources, sourcesCited, components } = useMessageCard({
    chatId,
    message,
    onSourceLinkClick,
    onGeneratedDraftCtaClick,
    isLoading
  })

  const { copyableRef } = useCopyable()

  if (!content) {
    return null
  }

  return (
    <div>
      {(message.role !== 'user' || aiDebug) && (
        <div className="mb-2 flex items-center gap-4">
          {message.role !== 'user' && (
            <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black ">
              <img src={reforgeSymbol} className="h-[12px] w-[12px]" alt="" />
            </div>
          )}
          {aiDebug && (
            <Popover>
              <Popover.Button as="div" className="hover:cursor-pointer">
                <Tag variant="pink">Mode: {message.mode || 'no-mode'}</Tag>
              </Popover.Button>
              <Popover.Overlay className="fixed inset-0 z-1 bg-black opacity-30" />

              <Popover.Panel className="absolute left-0 top-0 z-[1013] h-96 overflow-y-auto rounded-md border border-rb-gray-100 bg-rb-gray-50 p-2 text-sm">
                <CopyToClipboard
                  text={JSON.stringify(
                    {
                      chatId,
                      messageId: message.id,
                      mode: message.mode,
                      role: message.role,
                      context: message.debug?.context,
                      data: message.data
                    },
                    null,
                    2
                  )}
                >
                  <Tag className="hover:cursor-pointer">Copy to clipboard</Tag>
                </CopyToClipboard>
                <div>
                  <strong>Chat ID:</strong> {chatId}
                </div>
                <div>
                  <strong>Message ID:</strong> {message.id}
                </div>
                <div>
                  <strong>Role:</strong> {message.role}
                </div>
                <div>
                  <strong>Context:</strong> {message.debug?.context}
                </div>
                <div>
                  <strong>Data:</strong> {JSON.stringify(message.data)}
                </div>
              </Popover.Panel>
            </Popover>
          )}
        </div>
      )}
      <div
        ref={copyableRef}
        className={
          message.role === 'user'
            ? cn(USER_MESSAGE_BUBBLE_CLASSES, 'self-end')
            : ASSISTANT_MESSAGE_BUBBLE_CLASSES
        }
      >
        <ChatMessageMarkdown
          key={message.id}
          components={components}
          isLoading={isLoading && isLast && message.role === 'assistant'}
          sourcesCited={sourcesCited}
          showSources={showSourceLinks}
          onToggleSources={() => setShowSourceLinks(!showSourceLinks)}
        >
          {content}
        </ChatMessageMarkdown>
        {sources.length > 0 && !isLoading && (
          <div className={cn('my-6', { hidden: !showSourceLinks })}>
            <div className="font-bold">Sources</div>
            <div>
              {sources.map((source, idx) => (
                <div key={source.referenceId} className="mb-2">
                  <Link
                    onClick={() => {
                      trackAiChatSourceClicked({
                        chat_session_id: chatId,
                        content_id: source.referenceId,
                        content_title: source.title,
                        content_type: source.type,
                        content_sanity_id:
                          source.type === 'guide' ? source.referenceId : undefined
                      })
                      onSourceLinkClick?.()
                    }}
                    to={source.path}
                    className="rounded bg-rb-teal-50 px-[0.3rem] py-[0.1rem] text-rb-teal-600 hover:no-underline"
                  >
                    {idx + 1}. {source.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function Citation({
  citation,
  sourceData,
  onClick
}: {
  citation: string
  sourceData: SourcesData['sources'][0]
  onClick?: () => void
}) {
  return (
    <Tooltip
      backgroundColor="#080A0A"
      className="rounded-lg"
      place="top"
      tooltipBody={<>{sourceData.title} </>}
    >
      <Link
        className="ml-1 block min-w-max rounded bg-rb-teal-50 px-1 text-xs"
        to={sourceData.path}
        onClick={onClick}
      >
        {citation}
      </Link>
    </Tooltip>
  )
}

export const ChatMessageMarkdown = React.memo(function ChatMessageMarkdown({
  children,
  components,
  isLoading,
  sourcesCited = false,
  showSources = false,
  onToggleSources = () => {}
}: {
  children: string
  components: Options['components']
  isLoading?: boolean
  sourcesCited?: boolean
  showSources?: boolean
  onToggleSources?: () => void
}) {
  const classNames = cn('[&>*:last-child]:mb-0', {
    '[&>p:last-of-type]:inline': sourcesCited, // ensures that final paragrapgh element renders inline so that toggle for showing sources appears next to it and not below it
    '[&>*:last-child]:after:inline-block [&>*:last-child]:after:h-3 [&>*:last-child]:after:w-3 [&>*:last-child]:after:animate-pulse [&>*:last-child]:after:rounded-full [&>*:last-child]:after:bg-rb-black/75 [&>*:last-child]:after:pl-1 [&>*:last-child]:after:pt-1 [&>*:last-child]:after:content-["_"]':
      isLoading
  })

  return (
    <ErrorBoundary
      fallbackRender={() => <p>There was a problem showing the Assistant message.</p>}
    >
      <div className={classNames}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkDirective, remarkDirectiveRehype, supersub]}
          components={!isLoading ? components : undefined}
        >
          {children}
        </ReactMarkdown>
        {!isLoading && sourcesCited && (
          <button
            className={cn(
              'ml-2 rounded bg-rb-teal-50 px-[0.5rem] py-[0.4rem] text-rb-teal-600 hover:no-underline'
            )}
            onClick={onToggleSources}
          >
            <ChevronDown width={16} className={showSources ? 'rotate-180' : ''} />
          </button>
        )}
      </div>
    </ErrorBoundary>
  )
})
