import { MouseEventHandler } from 'react'

import Button from 'components/Button'

interface PlaceholderAvatarProps {
  onAddPhotoClicked: MouseEventHandler<HTMLButtonElement>
  width: string
  height: string
  smaller?: boolean
}

const PlaceholderAvatar = (props: PlaceholderAvatarProps) => {
  const { onAddPhotoClicked, width = '120px', height = '120px', smaller = false } = props
  const addText = smaller ? 'Add' : 'Add Photo'

  return (
    <>
      <div
        className="flex items-center justify-center rounded-full bg-rb-gray-500"
        style={{ width, height }}
      >
        <Button
          onClick={onAddPhotoClicked}
          variant="outline"
          shape="rounded-full"
          size="x-small"
          className="px-3"
        >
          {addText}
        </Button>
      </div>
    </>
  )
}

export default PlaceholderAvatar
