import {
  ABOUT_ME_TEXT_MAX_CHARACTERS,
  ASK_ME_ABOUT_TEXT_MAX_CHARACTERS,
  COMMUNITY_INTEREST_TEXT_MAX_CHARACTERS,
  COMPANY_PRODUCT_TEXT_MAX_CHARACTERS,
  LEARNING_GOALS_TEXT_MAX_CHARACTERS
} from 'constants/profile'

import { ProfileEditModalUserProfileFieldsFragment } from 'gql'

export const aboutMeTextValid = (profile: ProfileEditModalUserProfileFieldsFragment) =>
  (profile?.aboutMeText?.length || 0) <= ABOUT_ME_TEXT_MAX_CHARACTERS

export const askMeAboutTextValid = (profile: ProfileEditModalUserProfileFieldsFragment) =>
  (profile?.askMeAboutText?.length || 0) <= ASK_ME_ABOUT_TEXT_MAX_CHARACTERS

export const communityInterestTextValid = (
  profile: ProfileEditModalUserProfileFieldsFragment
) =>
  (profile?.communityInterestText?.length || 0) <= COMMUNITY_INTEREST_TEXT_MAX_CHARACTERS

export const companyProductTextValid = (
  profile: ProfileEditModalUserProfileFieldsFragment
) => (profile?.companyProductText?.length || 0) <= COMPANY_PRODUCT_TEXT_MAX_CHARACTERS

export const learningGoalsTextValid = (
  profile: ProfileEditModalUserProfileFieldsFragment
) => (profile?.learningGoalsText?.length || 0) <= LEARNING_GOALS_TEXT_MAX_CHARACTERS

export const textLengthsAreValid = (
  profile: ProfileEditModalUserProfileFieldsFragment
) => {
  if (
    !profile ||
    !aboutMeTextValid(profile) ||
    !askMeAboutTextValid(profile) ||
    !communityInterestTextValid(profile) ||
    !companyProductTextValid(profile) ||
    !learningGoalsTextValid(profile)
  ) {
    return false
  }

  return true
}

// Use this function if you want to prioritize the position and company from where the contributor
// was working at the time of this work entry.
export const getContributorTimedPositionAtCompany = (contributor: any) => {
  const position =
    contributor?.workEntry?.position || contributor?.user?.role || contributor?.position
  const company =
    contributor?.workEntry?.company?.name ||
    contributor?.user?.companyName ||
    contributor?.companyName

  return formatPositionAtCompany(position, company)
}

// Use this function to get an author's position from their profile; it handles whether this
// author has an augmented "user" or has the profile details injected at the GraphQL layer.
export const getArtifactAuthorPositionAtCompany = (author: any) => {
  const position = author?.user?.role || author?.position
  const company = author?.user?.companyName || author?.companyName

  return formatPositionAtCompany(position, company)
}

// Use this function if you have a user profile object with a role and company name.
export const getUserPositionAtCompany = (user: any) => {
  const position = user?.role
  const company = user?.companyName

  return formatPositionAtCompany(position, company)
}

// This is a helper function if you've already fetched your position and company
// and just want it to look pretty.
export const formatPositionAtCompany = (
  position?: string | null,
  company?: string | null,
  atSymbol: string = '@'
) => {
  if (position && company) {
    return `${position} ${atSymbol} ${company}`
  } else if (position) {
    return position
  } else if (company) {
    return company
  }

  return ''
}
