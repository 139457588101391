import { useEffect, useState } from 'react'

import JoinedTeamCelebrationModal from 'domains/TeamOnboarding/JoinedTeamCelebrationModal'

import { Loading } from 'components'

import { useGetOnboardingEnrollmentInfoQuery } from 'gql'

import useURLParams from 'hooks/useURLParams'

import useFirstSetupFlowModal from './useFirstSetupFlowModal'

export interface OnboardingModalProps {
  showLoader: boolean
}

const OnboardingModal = ({ showLoader }: OnboardingModalProps) => {
  const { getParam } = useURLParams()
  const [showJoinedTeamCelebration, setShowJoinedTeamCelebration] = useState(
    !!getParam('joined-team')
  )

  const { openFirstSetupFlowModal } = useFirstSetupFlowModal()

  const { loading, data } = useGetOnboardingEnrollmentInfoQuery()
  const currentUser = data?.currentUser

  useEffect(() => {
    const execute = async () => {
      if (!currentUser || !currentUser.is || !currentUser.is.member) {
        return
      }

      if (
        currentUser.onboarded === false &&
        !showJoinedTeamCelebration &&
        currentUser?.is?.teamSubscriptionOwner
      ) {
        openFirstSetupFlowModal({
          numSeats: currentUser.subscriptions?.active?.numSeats || 1,
          teamName: currentUser.companyName,
          subscriptionId: currentUser?.subscriptions?.active?.id,
          uniqueEmailDomains: currentUser?.uniqueEmailDomains
        })
      }
    }
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  if (showLoader && loading) return <Loading ghosted className="relative top-1/2" />
  if (!showLoader && loading) return null

  const handleCloseTeamJoinModal = () => {
    setShowJoinedTeamCelebration(false)
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.delete('joined-team')
    const newUrl = currentUrl.href
    window.history.pushState({ path: newUrl }, '', newUrl)

    if (currentUser?.onboarded === false && currentUser?.is?.teamSubscriptionOwner) {
      setTimeout(() => {
        openFirstSetupFlowModal({
          numSeats: currentUser?.subscriptions?.active?.numSeats || 1,
          teamName: currentUser?.companyName || '',
          planName: currentUser?.subscriptions?.active?.planName || '',
          subscriptionId: currentUser?.subscriptions?.active?.id,
          uniqueEmailDomains: currentUser?.uniqueEmailDomains
        })
      }, 100)
    }
  }

  return (
    <>
      {showJoinedTeamCelebration && (
        <JoinedTeamCelebrationModal
          isOpen={showJoinedTeamCelebration}
          hideModal={handleCloseTeamJoinModal}
          teamName={currentUser?.companyName || ''}
        />
      )}
    </>
  )
}

OnboardingModal.defaultProps = {
  showLoader: true
}

export default OnboardingModal
