import {
  FC,
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef
} from 'react'

import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'

import notifyError from 'utils/errorNotifier'

interface ICopyableContext {
  copyableRef: MutableRefObject<HTMLDivElement | null> | null
}

const CopyableContext = createContext<ICopyableContext>({
  copyableRef: null
})

export const CopyableProvider: FC = ({ children }) => {
  const copyableRef = useRef<HTMLDivElement>(null)

  const value = useMemo(() => ({ copyableRef }), [copyableRef])

  return <CopyableContext.Provider value={value}>{children}</CopyableContext.Provider>
}

export function useCopyable() {
  const { copyableRef } = useContext(CopyableContext)

  const copyBufferToClipboard = useCallback(
    async (onSuccess?: (text: string) => void) => {
      if (!copyableRef?.current) return

      const element = copyableRef.current
      const htmlContent = element.innerHTML.replace(
        /href="\/(?!\/)/g,
        `href="${window.location.origin}/`
      )
      const textContent = element.textContent || element.innerText

      const clipboardItemData = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([textContent], {
          type: 'text/plain'
        })
      })

      try {
        await navigator.clipboard.write([clipboardItemData])
        displayToast(<ToastCard type="success" message="Copied to clipboard" />, {
          ...toastOptions,
          toastId: 'reforge-toast'
        })
        onSuccess?.(textContent)
      } catch (error) {
        notifyError(error)
      }
    },
    [copyableRef]
  )

  return { copyableRef, copyBufferToClipboard }
}
