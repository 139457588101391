import { useCallback } from 'react'

import { Loading } from 'components'

import { ReactComponent as CloseIcon } from 'images/icon--close.svg'

import { StripeTaxId } from '../types'
import TaxIdSummary from './TaxIdSummary'

export default function SavedTaxId({
  taxId,
  onDelete,
  deleteInProgress
}: {
  taxId: StripeTaxId
  onDelete: () => any
  deleteInProgress: boolean
}) {
  const handleCancel = useCallback(() => onDelete(), [onDelete])

  return (
    <div>
      <div className="mb-2.5 text-lg font-medium leading-5">Tax ID</div>
      <div className="flex flex-row items-center gap-1">
        <div>
          <TaxIdSummary taxId={taxId} />
        </div>
        <div className="flex items-center">
          {deleteInProgress ? (
            <Loading className="mb-0" ratio={0.4} />
          ) : (
            <button
              aria-label="Delete tax ID"
              title="Delete"
              onClick={handleCancel}
              disabled={deleteInProgress}
            >
              <CloseIcon width={16} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
