// ISO 3166 Alpha-2 country codes
export const countryToCountryLabel = {
  AE: 'United Arab Emirates',
  AT: 'Austria',
  AU: 'Australia',
  BE: 'Belgium',
  BG: 'Bulgaria',
  BR: 'Brazil',
  CA: 'Canada',
  CH: 'Switzerland',
  CL: 'Chile',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  EG: 'Egypt',
  ES: 'Spain',
  EU: 'European Union',
  FI: 'Finland',
  FR: 'France',
  GE: 'Georgia',
  GR: 'Greece',
  HK: 'Hong Kong SAR China',
  HR: 'Croatia',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IS: 'Iceland',
  IT: 'Italy',
  JP: 'Japan',
  KE: 'Kenya',
  KR: 'South Korea',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  MX: 'Mexico',
  MY: 'Malaysia',
  NL: 'Netherlands',
  NO: 'Norway',
  NZ: 'New Zealand',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  RU: 'Russia',
  SA: 'Saudi Arabia',
  SE: 'Sweden',
  SG: 'Singapore',
  SI: 'Slovenia',
  SK: 'Slovakia',
  TH: 'Thailand',
  TR: 'Turkey',
  TW: 'Taiwan',
  UA: 'Ukraine',
  US: 'United States',
  GB: 'United Kingdom',
  ZA: 'South Africa'
}

export type TaxIdOption = {
  country: keyof typeof countryToCountryLabel
  type: string
  label: string
  example: string
}

const options: Array<TaxIdOption> = [
  {
    country: 'AE',
    example: '123456789012345',
    label: 'AE TRN',
    type: 'ae_trn'
  },
  {
    type: 'eu_vat',
    country: 'AT',
    label: 'AT VAT',
    example: 'ATU12345678'
  },
  {
    type: 'au_abn',
    country: 'AU',
    label: 'AU ABN',
    example: '12345678912'
  },
  {
    type: 'au_arn',
    country: 'AU',
    label: 'AU ARN',
    example: '123456789123'
  },
  {
    type: 'eu_vat',
    country: 'BE',
    label: 'BE VAT',
    example: 'BE0123456789'
  },
  {
    type: 'bg_uic',
    country: 'BG',
    label: 'BG UIC',
    example: '123456789'
  },
  {
    type: 'eu_vat',
    country: 'BG',
    label: 'BG VAT',
    example: 'BG0123456789'
  },
  {
    type: 'br_cnpj',
    country: 'BR',
    label: 'BR CNPJ',
    example: '01.234.456/5432-10'
  },
  {
    type: 'br_cpf',
    country: 'BR',
    label: 'BR CPF',
    example: '123.456.789-87'
  },
  {
    type: 'ca_bn',
    country: 'CA',
    label: 'CA BN',
    example: '123456789'
  },
  {
    type: 'ca_gst_hst',
    country: 'CA',
    label: 'CA GST/HST',
    example: '123456789RT0002'
  },
  {
    type: 'ca_pst_bc',
    country: 'CA',
    label: 'CA PST-BC',
    example: 'PST-1234-5678'
  },
  {
    type: 'ca_pst_mb',
    country: 'CA',
    label: 'CA PST-MB',
    example: '123456-7'
  },
  {
    type: 'ca_pst_sk',
    country: 'CA',
    label: 'CA PST-SK',
    example: '1234567'
  },
  {
    type: 'ca_qst',
    country: 'CA',
    label: 'CA QST',
    example: '1234567890TQ1234'
  },
  {
    type: 'ch_vat',
    country: 'CH',
    label: 'CH VAT',
    example: 'CHE-123.456.789 MWST'
  },
  {
    type: 'cl_tin',
    country: 'CL',
    label: 'CL TIN',
    example: '12.345.678-K'
  },
  {
    type: 'eu_vat',
    country: 'CY',
    label: 'CY VAT',
    example: 'CY12345678Z'
  },
  {
    type: 'eu_vat',
    country: 'CZ',
    label: 'CZ VAT',
    example: 'CZ1234567890'
  },
  {
    type: 'eu_vat',
    country: 'DE',
    label: 'DE VAT',
    example: 'DE123456789'
  },
  {
    type: 'eu_vat',
    country: 'DK',
    label: 'DK VAT',
    example: 'DK12345678'
  },
  {
    type: 'eu_vat',
    country: 'EE',
    label: 'EE VAT',
    example: 'EE123456789'
  },
  {
    type: 'eg_tin',
    country: 'EG',
    label: 'EG TIN',
    example: '123456789'
  },
  {
    type: 'es_cif',
    country: 'ES',
    label: 'ES CIF',
    example: 'A12345678'
  },
  {
    type: 'eu_vat',
    country: 'ES',
    label: 'ES VAT',
    example: 'ESA1234567Z'
  },
  {
    type: 'eu_oss_vat',
    country: 'EU',
    label: 'EU OSS VAT',
    example: 'EU123456789'
  },
  {
    type: 'eu_vat',
    country: 'FI',
    label: 'FI VAT',
    example: 'FI12345678'
  },
  {
    type: 'eu_vat',
    country: 'FR',
    label: 'FR VAT',
    example: 'FRAB123456789'
  },
  {
    type: 'gb_vat',
    country: 'GB',
    label: 'GB VAT',
    example: 'GB123456789'
  },
  {
    type: 'ge_vat',
    country: 'GE',
    label: 'GE VAT',
    example: '123456789'
  },
  {
    type: 'eu_vat',
    country: 'GR',
    label: 'GR VAT',
    example: 'EL123456789'
  },
  {
    type: 'hk_br',
    country: 'HK',
    label: 'HK BR',
    example: '12345678'
  },
  {
    type: 'eu_vat',
    country: 'HR',
    label: 'HR VAT',
    example: 'HR12345678912'
  },
  {
    type: 'hu_tin',
    country: 'HU',
    label: 'HU TIN',
    example: '12345678-1-23'
  },
  {
    type: 'eu_vat',
    country: 'HU',
    label: 'HU VAT',
    example: 'HU12345678912'
  },
  {
    type: 'id_npwp',
    country: 'ID',
    label: 'ID NPWP',
    example: '12.345.678.9-012.345'
  },
  {
    type: 'eu_vat',
    country: 'IE',
    label: 'IE VAT',
    example: 'IE1234567AB'
  },
  {
    type: 'il_vat',
    country: 'IL',
    label: 'IL VAT',
    example: '000012345'
  },
  {
    type: 'in_gst',
    country: 'IN',
    label: 'IN GST',
    example: '12ABCDE3456FGZH'
  },
  {
    type: 'is_vat',
    country: 'IS',
    label: 'IS VAT',
    example: '123456'
  },
  {
    type: 'eu_vat',
    country: 'IT',
    label: 'IT VAT',
    example: 'IT12345678912'
  },
  {
    type: 'jp_cn',
    country: 'JP',
    label: 'JP CN',
    example: '1234567891234'
  },
  {
    type: 'jp_rn',
    country: 'JP',
    label: 'JP RN',
    example: '12345'
  },
  {
    type: 'jp_trn',
    country: 'JP',
    label: 'JP TRN',
    example: 'T1234567891234'
  },
  {
    type: 'ke_pin',
    country: 'KE',
    label: 'KE PIN',
    example: 'P000111111A'
  },
  {
    type: 'kr_brn',
    country: 'KR',
    label: 'KR BRN',
    example: '123-45-67890'
  },
  {
    type: 'li_uid',
    country: 'LI',
    label: 'LI UID',
    example: 'CHE123456789'
  },
  {
    type: 'eu_vat',
    country: 'LT',
    label: 'LT VAT',
    example: 'LT123456789123'
  },
  {
    type: 'eu_vat',
    country: 'LU',
    label: 'LU VAT',
    example: 'LU12345678'
  },
  {
    type: 'eu_vat',
    country: 'LV',
    label: 'LV VAT',
    example: 'LV12345678912'
  },
  {
    type: 'eu_vat',
    country: 'MT',
    label: 'MT VAT',
    example: 'MT12345678'
  },
  {
    type: 'mx_rfc',
    country: 'MX',
    label: 'MX RFC',
    example: 'ABC010203AB9'
  },
  {
    type: 'my_frp',
    country: 'MY',
    label: 'MY FRP',
    example: '12345678'
  },
  {
    type: 'my_itn',
    country: 'MY',
    label: 'MY ITN',
    example: 'C 1234567890'
  },
  {
    type: 'my_sst',
    country: 'MY',
    label: 'MY SST',
    example: 'A12-3456-78912345'
  },
  {
    type: 'eu_vat',
    country: 'NL',
    label: 'NL VAT',
    example: 'NL123456789B12'
  },
  {
    type: 'no_vat',
    country: 'NO',
    label: 'NO VAT',
    example: '123456789MVA'
  },
  {
    type: 'nz_gst',
    country: 'NZ',
    label: 'NZ GST',
    example: '123456789'
  },
  {
    type: 'ph_tin',
    country: 'PH',
    label: 'PH TIN',
    example: '123456789012'
  },
  {
    type: 'eu_vat',
    country: 'PL',
    label: 'PL VAT',
    example: 'PL1234567890'
  },
  {
    type: 'eu_vat',
    country: 'PT',
    label: 'PT VAT',
    example: 'PT123456789'
  },
  {
    type: 'eu_vat',
    country: 'RO',
    label: 'RO VAT',
    example: 'RO1234567891'
  },
  {
    type: 'ru_inn',
    country: 'RU',
    label: 'RU INN',
    example: '1234567891'
  },
  {
    type: 'ru_kpp',
    country: 'RU',
    label: 'RU KPP',
    example: '123456789'
  },
  {
    type: 'sa_vat',
    country: 'SA',
    label: 'SA VAT',
    example: '123456789012345'
  },
  {
    type: 'eu_vat',
    country: 'SE',
    label: 'SE VAT',
    example: 'SE123456789123'
  },
  {
    type: 'sg_gst',
    country: 'SG',
    label: 'SG GST',
    example: 'M12345678X'
  },
  {
    type: 'sg_uen',
    country: 'SG',
    label: 'SG UEN',
    example: '123456789F'
  },
  {
    type: 'si_tin',
    country: 'SI',
    label: 'SI TIN',
    example: '12345678'
  },
  {
    type: 'eu_vat',
    country: 'SI',
    label: 'SI VAT',
    example: 'SI12345678'
  },
  {
    type: 'eu_vat',
    country: 'SK',
    label: 'SK VAT',
    example: 'SK1234567891'
  },
  {
    type: 'th_vat',
    country: 'TH',
    label: 'TH VAT',
    example: '1234567891234'
  },
  {
    type: 'tr_tin',
    country: 'TR',
    label: 'TR TIN',
    example: '0123456789'
  },
  {
    type: 'tw_vat',
    country: 'TW',
    label: 'TW VAT',
    example: '12345678'
  },
  {
    type: 'ua_vat',
    country: 'UA',
    label: 'UA VAT',
    example: '123456789'
  },
  {
    type: 'us_ein',
    country: 'US',
    label: 'US EIN',
    example: '12-3456789'
  },
  {
    type: 'eu_vat',
    country: 'GB',
    label: 'XI VAT',
    example: 'XI123456789'
  },
  {
    type: 'kr_brn',
    country: 'ZA',
    label: 'ZA VAT',
    example: '4123456789'
  }
]

export function getOptionByTypeAndCountry(
  type: TaxIdOption['type'] | null | undefined,
  country: string | null | undefined
) {
  return options.find((option) => option.type === type && option.country === country)
}

export default options
