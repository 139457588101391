import { useEffect } from 'react'

import { AlertBanner } from 'components/AlertBanner'

// import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'
// import useMediaQuery from 'hooks/useMediaQuery'
import { track } from 'utils/tracking/analytics'

const BANNER_NAME = 'pending-request-warning'

export default function PendingJoinTeamRequestBanner() {
  useEffect(() => {
    track('Banner Displayed', {
      category: 'app',
      banner_name: BANNER_NAME
    })
  }, [])

  // const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)

  return (
    <AlertBanner
      data-testid="pending-request-warning-banner"
      type="info"
      dismissable={false}
    >
      <div>
        You have a pending request to join a team. If you purchase a membership on your
        own, we will cancel that request.
      </div>
    </AlertBanner>
  )
}
