import { useSaveCustomerTaxIdMutation, useUpdateCustomerContactMutation } from 'gql'

import { tuple } from 'typings/utils'

import { FormValue } from './types'

export function isUSAddress(address: { country: string }) {
  const US_ISO_3166_1_ALPHA_2 = 'US' as const
  return address.country === US_ISO_3166_1_ALPHA_2
}

export const IS_ADDRESS_EDITING_ERROR_MESSAGE =
  'Billing information required (make sure to save)'

export const NO_BILLING_ADDRESS_ERROR_MESSAGE = 'Please enter a billing address'

/**
 * Hook that encapsulates saving all billing info data
 */
export function useSaveBillingInfo() {
  const [updateCustomerContact, { loading: updateCustomerContactLoading }] =
    useUpdateCustomerContactMutation()

  const [saveTaxId, { loading: saveTaxIdLoading }] = useSaveCustomerTaxIdMutation({
    onError: (error: any) => {
      console.error('Error for useSaveCustomerTaxIdMutation', error)
    }
  })

  async function saveAll(values: FormValue) {
    const results = await Promise.all([
      values?.billingAddress?.name &&
      values?.billingAddress?.address.line1 &&
      values?.billingAddress?.address.country
        ? updateCustomerContact({
            variables: {
              input: {
                name: values?.billingAddress?.name,
                address: {
                  line1: values?.billingAddress?.address.line1,
                  line2: values?.billingAddress?.address.line2 || '',
                  city: values?.billingAddress?.address.city,
                  state: values?.billingAddress?.address.state,
                  postalCode: values?.billingAddress?.address.postal_code,
                  country: values?.billingAddress?.address.country
                }
              }
            }
          })
        : Promise.resolve(null),
      values?.taxId?.type && values?.taxId?.value
        ? saveTaxId({
            variables: {
              input: {
                type: values?.taxId?.type,
                value: values?.taxId?.value
              }
            }
          })
        : Promise.resolve(null)
    ])

    return results
  }

  const combinedLoading: boolean = updateCustomerContactLoading || saveTaxIdLoading

  return tuple(saveAll, { loading: combinedLoading })
}
