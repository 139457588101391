import { ApolloCache, ApolloQueryResult } from '@apollo/client'
import produce from 'immer'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { FroalaWrapperNew } from 'domains/Post/FroalaWrapper'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import Link from 'components/Link'
import Modal from 'components/Modal'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'

import {
  CmsContentDocument,
  CmsContentQuery,
  CmsContentQueryVariables,
  PostShowQuery,
  SimilarPostFieldsFragment,
  useCreateTeamPostMutation,
  useUpdateTeamPostMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'
import {
  trackModalDismissed,
  trackModalDisplayed,
  trackPostAction
} from 'utils/tracking/generated/events'

import { PostUpdatePayload, TeamPostPayload } from 'typings/payloads'

export enum ModalAction {
  CREATE = 'create',
  UPDATE = 'update'
}

interface ITopicItem {
  type: string
  id: string
}

interface IAddPostModalState {
  description: string
  descriptionError: string | null
  initialGroupsTopics: ITopicItem[]
  postDisabled: boolean
  selectedItems: ITopicItem[]
  showConfirmation: string | false
  skipForumIndex: boolean
  suggestions: SimilarPostFieldsFragment[]
  searchTitle: string
  title: string
  titleError: string | null
}

export interface AddPostModalProps {
  action?: ModalAction
  afterPostCallback?: (data: unknown) => void
  anchor?: string | null
  basedOn?: string
  cmsSectionId?: string | number | null
  cmsSectionName?: string | null
  cmsSectionContentType?: string | null
  id?: string | number
  initialDescription?: string | null
  initialGroupsTopics?: ITopicItem[]
  initialTitle?: string | null
  referenceImageUrl?: string | null
  refetch?: () => Promise<ApolloQueryResult<PostShowQuery> | void>
  skipForumIndex?: boolean | null
}

interface AddPostModalContextProvidedProps {
  isOpen: boolean
  onRequestClose: () => void
}

const AddPostModal = (props: AddPostModalProps & AddPostModalContextProvidedProps) => {
  const [userTagFound, setUserTagFound] = useState<boolean>(false)

  const initialState = {
    description: '',
    descriptionError: null,
    initialGroupsTopics: [],
    postDisabled: false,
    selectedItems: [],
    showConfirmation: false as const,
    skipForumIndex: false,
    suggestions: [],
    searchTitle: '',
    title: '',
    titleError: null
  }

  const [updateTeamPostMutation] = useUpdateTeamPostMutation()

  const updateCacheOnCreate = (cache: ApolloCache<any>) => {
    const existingData = cache.readQuery<CmsContentQuery, CmsContentQueryVariables>({
      query: CmsContentDocument,
      variables: { cmsSectionId: props.cmsSectionId as string }
    })

    if (!existingData) return

    const newData = produce(existingData, (draft) => {
      draft?.cmsContent?.inlinePostAnchors.push(props.anchor as string)
    })

    cache.writeQuery<CmsContentQuery, CmsContentQueryVariables>({
      query: CmsContentDocument,
      variables: { cmsSectionId: props.cmsSectionId as string },
      data: newData
    })
  }
  const [createTeamPostMutation] = useCreateTeamPostMutation({
    update: updateCacheOnCreate
  })

  const [state, setState] = useReducer(
    (state: IAddPostModalState, newState: Partial<IAddPostModalState>) => ({
      ...state,
      ...newState
    }),
    initialState
  )

  const postTitleInputRef = useRef<HTMLInputElement | null>(null)
  const { action = ModalAction.CREATE, isOpen, onRequestClose } = props

  const { setModalData } = useAddPostModal()
  const history = useHistory()

  useEffect(() => {
    if (props.initialTitle && props.initialTitle.length) {
      setState({ title: props.initialTitle })
      postTitleInputRef.current?.focus()
    }
    if (props.initialDescription && props.initialDescription.length) {
      setState({ description: props.initialDescription })
    }
    if (props.skipForumIndex) {
      setState({ skipForumIndex: props.skipForumIndex })
    }
  }, [props.initialTitle, props.initialDescription, props.skipForumIndex])

  useEffect(() => {
    if (props.initialGroupsTopics) {
      setState({ initialGroupsTopics: props.initialGroupsTopics })
    }
  }, [props.initialGroupsTopics])

  useEffect(() => {
    const isPostDisabled = state.description.length === 0

    setState({ postDisabled: isPostDisabled })
  }, [state.description])

  useEffect(() => {
    if (!isOpen) {
      setModalData({})
      setState({ initialGroupsTopics: [] })
    } else {
      trackModalDisplayed({
        category: 'app',
        location: window.location.pathname,
        modal_group: 'commenting',
        modal_name: 'add_comment_modal',
        related_identifiers: {
          content_id: props.cmsSectionId,
          content_name: props.cmsSectionName,
          content_type: props.cmsSectionContentType
        }
      })
    }
  }, [
    isOpen,
    setModalData,
    props.cmsSectionId,
    props.cmsSectionName,
    props.cmsSectionContentType
  ])

  const save = () => {
    saveTeamPost()
  }

  const cancelPost = () => {
    if (state.postDisabled || action === ModalAction.UPDATE) {
      // not enough info to post so no need to ask for confirmation
      closeModal()
    } else {
      setState({ showConfirmation: 'confirmDelete' })
    }
  }

  const deletePost = () => {
    setState({ showConfirmation: 'deleting' })
    setTimeout(closeModal, 1000)
  }

  const cancelDelete = () => {
    setState({ showConfirmation: false })
  }

  const closeModal = () => {
    onRequestClose()
    setModalData({})
    setState({ showConfirmation: false })

    if (action === ModalAction.CREATE) {
      setState({
        title: '',
        description: '',
        suggestions: []
      })
    }

    trackModalDismissed({
      category: 'app',
      location: window.location.pathname,
      modal_group: 'commenting',
      modal_name: 'add_comment_modal',
      related_identifiers: {
        content_id: props.cmsSectionId,
        content_name: props.cmsSectionName,
        content_type: props.cmsSectionContentType
      }
    })
  }

  const postSaveActions = async (postId: string, draft: boolean) => {
    trackPostAction({
      action,
      group_ids: [],
      topic_ids: [],
      post_id: postId,
      comment_filter: 'Team',
      team_tagged: userTagFound
    })

    if (props.afterPostCallback) {
      props.refetch?.()
      props.afterPostCallback(postId)
      closeModal()

      displayToast(<ToastCard type="success" message="Comment Added" />, toastOptions)
    } else {
      if (action === ModalAction.UPDATE) {
        setState({ showConfirmation: 'updated' })
        setTimeout(() => {
          props.refetch ? props.refetch() : window.location.reload()
          closeModal()
        }, 1000)
      } else {
        if (draft) {
          setState({ showConfirmation: 'savingDraft' })
        } else {
          setState({ showConfirmation: 'posted' })
        }
        const filter = draft ? 'drafts' : 'myPosts'
        closeModal()

        setTimeout(() => {
          const reload =
            window.location.pathname === '/posts/my-contributions' ? '#reload' : ''

          history.push(
            `/posts/my-contributions?page=1&sort_by=recent&filter=${filter}${reload}`
          )
        }, 1000)
      }
    }
    setState({ postDisabled: false })
    setUserTagFound(false)
  }

  const createTeamPost = async (input: TeamPostPayload) => {
    const { errors, data } = await createTeamPostMutation({
      variables: {
        input: input
      }
    })

    if (errors) {
      notifyError(errors)
      return null
    } else {
      if (data?.createTeamPost?.id) {
        postSaveActions(data.createTeamPost.id, false)
      }
    }
  }

  const updateTeamPost = async (input: TeamPostPayload) => {
    input.id = props.id as string
    const { errors, data } = await updateTeamPostMutation({
      variables: {
        input: input as PostUpdatePayload
      }
    })

    if (errors) {
      notifyError(errors)
      return null
    } else {
      if (data?.updateTeamPost?.teamPost?.id) {
        postSaveActions(data.updateTeamPost.teamPost.id, false)
      }
    }
  }

  const saveTeamPost = async () => {
    const input: TeamPostPayload = {
      body: state.description
    }

    if (props.cmsSectionId) {
      input.cmsSectionId = Number(props.cmsSectionId)
    }
    if (props.anchor) {
      input.anchor = props.anchor
    }
    if (props.basedOn) {
      input.basedOn = props.basedOn
    }
    if (props.referenceImageUrl) {
      input.referenceImageUrl = props.referenceImageUrl
    }

    if (action === ModalAction.CREATE) {
      await createTeamPost(input)
    }

    if (action === ModalAction.UPDATE) {
      await updateTeamPost(input)
    }
  }

  const setDescription = (description: string) => {
    setState({ description: description })
  }

  const modalTitle = () => {
    switch (action) {
      case ModalAction.CREATE:
        return 'Add Comment'
      case ModalAction.UPDATE:
        return 'Edit Post'
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={closeModal}
      className="max-w-[1200px] sm:w-full 2xl:w-full"
      fullHeight={false}
      header={true}
    >
      <div className="rounded-sm bg-transparent p-0" data-test="add-post-modal">
        {state.showConfirmation && (
          <div className="flex flex-col items-center bg-white p-5 text-center text-xl text-rb-gray-300 tl:p-11">
            {state.showConfirmation === 'confirmDelete' && (
              <>
                <div className="uk-margin p-4">
                  Are you sure you want to delete this post?
                  <br />
                  You will lose any progress.
                </div>
                <div className="flex justify-center sm:justify-end">
                  <Button
                    size="small"
                    variant="outline"
                    onClick={cancelDelete}
                    className="mr-4 sm:mr-5"
                  >
                    Back To Editing
                  </Button>
                  <Button disabled={state.postDisabled} size="small" onClick={deletePost}>
                    Delete Post
                  </Button>
                </div>
              </>
            )}
            {state.showConfirmation === 'deleting' && (
              <>
                <SVGIcon name="trash" height="60" width="60" fill="#0f0f0f" />
                <div className="uk-margin" style={{ marginBottom: '40px' }}>
                  Deleting Post
                </div>
              </>
            )}
            {state.showConfirmation === 'savingDraft' && (
              <>
                <SVGIcon name="disk" height="60" width="60" fill="#0f0f0f" />
                <div className="uk-margin" style={{ marginBottom: '30px' }}>
                  Saving a draft of your post that you can finish later
                </div>
              </>
            )}
            {state.showConfirmation === 'posted' && (
              <>
                <SVGIcon
                  name="multi-speech-bubble"
                  height="60"
                  width="60"
                  fill="#0f0f0f"
                />
                <div className="uk-margin" style={{ marginBottom: '40px' }}>
                  Great work! Your post has been added to the Reforge community
                </div>
              </>
            )}
            {state.showConfirmation === 'updated' && (
              <>
                <SVGIcon
                  name="multi-speech-bubble"
                  height="60"
                  width="60"
                  fill="#0f0f0f"
                />
                <div className="uk-margin" style={{ marginBottom: '40px' }}>
                  Your post has been updated
                </div>
              </>
            )}
          </div>
        )}
        {!state.showConfirmation && (
          <>
            <div className="px-5 pb-2.5 tl:px-11">
              <h3
                data-test="add-post-modal-title"
                className="mb-2 text-lg font-semibold text-rb-gray-400 tl:text-2xl"
              >
                {modalTitle()}
              </h3>
            </div>
            <div className="flex flex-col tl:flex-row">
              <div
                className={`p-2.5 pb-3 tl:pt-1.5 tl:pr-4 tl:pb-0 tl:pl-11 ${
                  action === ModalAction.CREATE ? 'w-full tl:w-2/3' : 'w-full tl:pr-11'
                }`}
              >
                {isOpen && (
                  <FroalaWrapperNew
                    model={state.description}
                    updateModel={setDescription}
                    className="mb-2.5 tl:mb-4"
                    placeholder="Type @ to mention and discuss with someone on your team"
                    dataTest="add-post-modal-description"
                    setUserTagFound={setUserTagFound}
                  />
                )}
              </div>
              {action === ModalAction.CREATE && (
                <div className="w-full px-2.5 tl:w-1/3 tl:py-4 tl:pr-11 tl:pl-1.5">
                  {!!state.suggestions.length && (
                    <InfoSection
                      title="Similar Posts in the Community"
                      content={
                        <ul className="mt-2.5 mb-0 list-none pl-0 text-m-small sm:text-m-medium">
                          {state.suggestions.map((suggestion, idx) => (
                            <li
                              key={`suggestion${idx}`}
                              className="border-t border-t-white py-2.5 px-0"
                            >
                              <a
                                className="uk-link-reset"
                                target="_blank"
                                rel="noreferrer"
                                href={`/posts/${suggestion.id}`}
                              >
                                <div>{suggestion.title}</div>
                                {(suggestion.reactionsCount > 0 ||
                                  suggestion.responseCount > 0) && (
                                  <div className="uk-margin-small-top flex flex-row items-center">
                                    {suggestion.responseCount > 0 && (
                                      <div className="mr-4 flex items-center">
                                        <SVGIcon
                                          className="rf-icon mr-1"
                                          name="speech-bubble"
                                          fill="#A2A1A2"
                                          height="20"
                                          width="20"
                                        />
                                        <span
                                          className="text-m-medium text-rb-gray-300 sm:text-base"
                                          style={{ lineHeight: '20px' }}
                                        >
                                          {suggestion.responseCount}
                                        </span>
                                      </div>
                                    )}
                                    {suggestion.reactionsCount > 0 && (
                                      <div className="flex items-center">
                                        <SVGIcon
                                          className="rf-icon mr-1"
                                          name="simple-smile"
                                          fill="#A2A1A2"
                                          height="20"
                                          width="20"
                                        />
                                        <span
                                          className="text-m-medium text-rb-gray-300 sm:text-base"
                                          style={{ lineHeight: '20px' }}
                                        >
                                          {suggestion.reactionsCount}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </a>
                            </li>
                          ))}
                        </ul>
                      }
                    />
                  )}

                  {props.referenceImageUrl || props.basedOn ? (
                    <InfoSection
                      title="Commenting on"
                      className={`tl:h-full ${
                        state.suggestions.length ? 'tl:hidden' : undefined
                      }`}
                      content={
                        <>
                          {props.referenceImageUrl && (
                            <img
                              alt=""
                              className="mt-2.5 pt-4"
                              src={props.referenceImageUrl}
                            />
                          )}
                          {props.basedOn && (
                            <div
                              className="mt-2.5 pt-4 text-sm"
                              dangerouslySetInnerHTML={{ __html: props.basedOn }}
                            />
                          )}
                        </>
                      }
                    />
                  ) : (
                    <InfoSection
                      title="A Few Tips For Great Posts"
                      className={`tl:h-full ${
                        state.suggestions.length ? 'tl:hidden' : undefined
                      }`}
                      content={
                        <>
                          <ul className="mt-2.5 mb-0 list-none pl-0 text-sm">
                            <li className="border-t border-t-white py-2.5 px-0">
                              1. Add a clear title. What’s at the heart of your question
                              or post?
                            </li>
                            <li className="border-t border-t-white py-2.5 px-0">
                              2. Context is key. By including necessary details, you can
                              help people help you.
                            </li>
                            <li className="border-t border-t-white py-2.5 px-0">
                              3. Share the love. If you receive great advice, add a
                              response to an Unanswered post.
                            </li>
                          </ul>
                        </>
                      }
                    />
                  )}
                </div>
              )}
            </div>

            <div
              className={twMerge(
                'flex items-center justify-between bg-white p-2.5 pb-4 tl:px-11 tl:pb-11',
                action === ModalAction.UPDATE ? 'pt-[30px]' : 'pt-4'
              )}
            >
              <div className="flex flex-col">
                <h6 className="m-0 text-sm lg:text-base">
                  Only your team will see this comment
                </h6>
                <p className=" pt-2.5 text-sm leading-6 text-rb-gray-300">
                  By adding a comment, you are agreeing to the Reforge{' '}
                  <Link to="/posts/guidelines" target="_blank">
                    Community Guidelines
                  </Link>
                  .
                </p>
              </div>
              <div className="flex">
                <Button
                  variant="text-only"
                  className="mr-2.5 flex h-12 min-w-[115px] tl:px-0"
                  onClick={cancelPost}
                >
                  Cancel
                </Button>
                <Button
                  disabled={state.postDisabled}
                  className="flex h-12 min-w-[115px] px-4 tl:px-0"
                  onClick={save}
                  dataTest="add-post-modal-submit"
                  data-dd-action-name="Create comment"
                >
                  {action === ModalAction.CREATE ? 'Comment' : 'Update Comment'}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

interface AddPostModalContextValue {
  isAddPostModalOpen: boolean
  closeAddPostModal: () => void
  showAddPostModal: () => void
  setModalData: (data: AddPostModalProps) => void
}

export const AddPostModalContext = React.createContext<AddPostModalContextValue>({
  isAddPostModalOpen: false,
  closeAddPostModal: () => {},
  showAddPostModal: () => {},
  setModalData: () => {}
})

export function AddPostModalProvider({
  children,
  isLoggedIn
}: {
  children: React.ReactNode
  isLoggedIn: boolean
}) {
  const [isAddPostModalOpen, setIsAddPostModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const showAddPostModal = useCallback(() => {
    setIsAddPostModalOpen(true)
  }, [setIsAddPostModalOpen])
  const closeAddPostModal = useCallback(() => {
    setIsAddPostModalOpen(false)
  }, [setIsAddPostModalOpen])
  const addPostModalValue: AddPostModalContextValue = {
    isAddPostModalOpen,
    closeAddPostModal,
    showAddPostModal,
    setModalData
  }
  return (
    <AddPostModalContext.Provider value={addPostModalValue}>
      {children}
      {isLoggedIn && (
        <AddPostModal
          {...modalData}
          isOpen={isAddPostModalOpen}
          onRequestClose={closeAddPostModal}
        />
      )}
    </AddPostModalContext.Provider>
  )
}

export function useAddPostModal() {
  return useContext(AddPostModalContext)
}

export default AddPostModal

interface InfoSectionProps {
  title: string
  content: React.ReactNode
  className?: string
}

const InfoSection = ({ title, content, className }: InfoSectionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <section
      className={twMerge(
        'mb-2.5 bg-rb-gray-50 p-2.5 tl:h-[357px] tl:overflow-y-auto tl:p-4',
        className
      )}
    >
      <div className="flex flex-row items-center leading-5">
        <h6 className="m-0 flex-grow text-xs font-medium uppercase tracking-widest">
          {title}
        </h6>
        <div
          className="flex cursor-pointer items-center tl:hidden"
          onClick={() => setIsOpen(!isOpen)}
          tabIndex={0}
          onKeyUp={onEnterKeyPress(() => setIsOpen(!isOpen))}
          role="button"
        >
          {isOpen ? (
            <SVGIcon name="hide-minus" height="20" width="20" fill="#000000" />
          ) : (
            <SVGIcon name="reveal-plus" height="20" width="20" fill="#A2A1A2" />
          )}
        </div>
      </div>

      <div className={twMerge(isOpen ? 'block' : 'hidden', 'tl:block')}>{content}</div>
    </section>
  )
}
