import rehypeStringify from 'rehype-stringify'
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { unified } from 'unified'

import { ModeName } from './GlobalChatProvider'
import { Message } from './types'

const markdownToHtmlParser = unified()
  .use(remarkParse)
  .use(remarkGfm)
  .use(remarkRehype)
  .use(rehypeStringify)

export const markdownToHtml = async function (text: string) {
  const vfile = await markdownToHtmlParser.process(text)
  return String(vfile)
}

// NOTE: For now this is based on assumption that a session is essentially stuck in the first mode
// it gets put into (except default mode)
export const inferMode = ({ messages }: { messages: Message[] }) => {
  const msg = messages.find((message) => message.mode !== 'default')
  const modeAndOptions = msg
    ? { mode: (msg.mode as ModeName) ?? 'default', modeOptions: msg.modeOptions ?? {} }
    : { mode: 'default' as ModeName, modeOptions: {} }

  return modeAndOptions
}
