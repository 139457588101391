const ReactComponent = () => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49935 16L7.49935 2.57255L12.0379 7.11111L13 6.14902L7.31111 0.46013L6.83007 -5.39393e-07L6.34902 0.46013L0.660135 6.14902L1.62223 7.11111L6.16079 2.57255L6.16079 16L7.49935 16Z"
      fill="white"
    />
  </svg>
)

export { ReactComponent }
