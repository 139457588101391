import React, { useCallback, useState } from 'react'

import notifyError from 'utils/errorNotifier'

/**
 * Same functionality as useState, except the value will be persisted in localStorage.
 * @param defaultValue Value that will initially be stored in local storage.
 * @param key Key that will be set in local storage.
 * @returns [val, setVal] like useState does.
 */

export default function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      notifyError(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (value) => {
      try {
        setStoredValue((existingValue) => {
          // Allow value to be a function so we have same API as useState
          const valueToStore = value instanceof Function ? value(existingValue) : value
          // Save to local storage
          window.localStorage.setItem(key, JSON.stringify(valueToStore))
          // Save state
          return valueToStore
        })
      } catch (error) {
        // A more advanced implementation would handle the error case
        return notifyError(error)
      }
    },
    [key]
  )

  return [storedValue, setValue] as const
}
