import { ReactElement } from 'react'

import Banner, { BannerProps, BannerVariantType } from 'components/Banner'
import Button from 'components/Button'

import { ReactComponent as InfoIcon } from 'images/icon--info.svg'
import { ReactComponent as WarningIcon } from 'images/icon--warning.svg'

const iconMapping: Record<NonNullable<AlertBannerProps['type']>, ReactElement> = {
  info: <InfoIcon height={24} width={24} />,
  warning: <WarningIcon height={24} width={24} />,
  error: <WarningIcon height={24} width={24} />
}

export interface AlertBannerProps extends Omit<BannerProps, 'type'> {
  type: BannerVariantType // required!
}

/**
 * AlertBanner
 * @param {type} enum info | warning | error
 * Returns a strictly styled instance of Banner
 * */
export const AlertBanner = ({ cta, type, ...bannerProps }: AlertBannerProps) => {
  if (cta?.props?.children) {
    // if cta exists, let's style it for consistency
    return (
      <Banner
        data-testid="alert-banner"
        icon={iconMapping[type]}
        type={type}
        cta={<Button onClick={cta?.props?.onClick}>{cta?.props?.children}</Button>}
        {...bannerProps}
      />
    )
  }
  return (
    <Banner
      data-testid="alert-banner"
      icon={iconMapping[type]}
      type={type}
      {...bannerProps}
    />
  )
}

export default AlertBanner
