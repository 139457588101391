import { Link } from 'react-router-dom'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { NEW_PLANS_AND_PRICING_INFO_URL } from 'utils/url/constants'

export const LearnMoreAboutPricingAndPackagingLink = () => {
  const handleCtaClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'manage_plan_page',
      text: 'learn more about new pricing and packaging here'
    })
  }

  return (
    <Link
      rel="noreferrer"
      target="_blank"
      onClick={handleCtaClick}
      to={{ pathname: NEW_PLANS_AND_PRICING_INFO_URL }}
    >
      Learn more about new pricing and packaging here.
    </Link>
  )
}
