import { Controller } from 'react-hook-form'
import Select, { Props as SelectProps } from 'react-select'

import withDefaultEnhancers from 'components/forms/enhancers/withDefaultEnhancers'
import { IFormElementBase } from 'components/forms/formTypes'

export interface InputAutocomplete<Option> extends IFormElementBase {
  placeholder?: string
  options: SelectProps<Option>['options']
  getOptionLabel?: SelectProps<Option>['getOptionLabel']
  getOptionValue?: SelectProps<Option>['getOptionValue']
  formatOptionLabel?: SelectProps<Option>['formatOptionLabel']
  defaultValue?: SelectProps<Option>['defaultValue']
  onChange?: (event: any) => void
  onBlur?: (event: any) => void
}

export function InputAutocompleteDropdown<Option>({
  options,
  name,
  getOptionLabel,
  getOptionValue,
  formatOptionLabel,
  defaultValue,
  control,
  placeholder,
  onChange,
  onBlur
}: InputAutocomplete<Option>) {
  return (
    <Controller<typeof Select>
      name={name}
      as={Select}
      control={control}
      onBlur={onBlur}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      onChange={([option]) => {
        onChange && onChange(option)
        return option
      }}
      placeholder={placeholder}
      defaultValue={defaultValue}
      backspaceRemovesValue
      isClearable
      unstyled
      classNames={{
        option: (state) =>
          state.isFocused
            ? 'cursor-pointer bg-white p-2 hover:bg-rb-gray-50 bg-rb-gray-50'
            : 'cursor-pointer bg-white p-2 hover:bg-rb-gray-50'
      }}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: 'white',
          padding: '8px 12px',
          lineHeight: '20px',
          width: '100%',
          outline: 'none',
          fontSize: '16px',
          color: 'rgb(51, 51, 51)',
          boxSizing: 'border-box',
          fontFamily: 'inherit'
        }),
        placeholder: (baseStyles) => ({ ...baseStyles, color: '#9ca3af' }),
        control: (baseStyles) => ({
          ...baseStyles,
          minHeight: 0
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: 'white',
          border: '1px solid rgb(211, 210, 211)',
          borderRadius: '2px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
          left: '0px',
          padding: '12px 8px',
          top: '48px'
        })
      }}
    />
  )
}

const InputAutocompleteDropdownWithEnhancers = withDefaultEnhancers(
  InputAutocompleteDropdown
)

export default InputAutocompleteDropdownWithEnhancers
