import React from 'react'

import ErrorMessage from 'components/ErrorMessage'

import notifyError from 'utils/errorNotifier'

interface ErrorBoundaryProps {
  fallbackRender?: (error?: Error) => React.ReactNode
  FallbackComponent?: React.ComponentType<ErrorBoundaryState>
  children: React.ReactNode | React.ReactNode[]
}

interface ErrorBoundaryState {
  error: Error | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: null
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error: error }
  }

  public componentDidCatch(error: Error) {
    notifyError(error)
  }

  public render() {
    const { fallbackRender, FallbackComponent, children } = this.props
    const { error } = this.state

    if (error) {
      if (fallbackRender) {
        return fallbackRender(error)
      }

      if (FallbackComponent) {
        return <FallbackComponent error={error} />
      }

      return <DefaultFallbackComponent error={error} />
    }

    return children
  }
}

const DefaultFallbackComponent = ({ error }: { error: Error }) => {
  return <ErrorMessage error={error} context={{ sourceComponent: 'ErrorBoundary' }} />
}

export default ErrorBoundary
