import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { useExternalStylesheet } from 'hooks/useExternalStylesheet'

import { StripeTaxId } from '../types'
import FlagIcon from './FlagIcon'
import { getOptionByTypeAndCountry } from './TaxIdForm'

function concealTaxIdValue(taxIdValue: string) {
  const DIGITS_TO_SHOW = 4
  return `******${taxIdValue.slice(-DIGITS_TO_SHOW)}`
}

type TaxIdSummaryProps = { taxId: StripeTaxId; concealValue?: boolean }

export default function TaxIdSummary({ taxId, concealValue = true }: TaxIdSummaryProps) {
  useExternalStylesheet(
    'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css'
  )

  return (
    <div className="flex items-center">
      <div className="mr-1 flex-initial">
        <FlagIcon country={taxId.country} />
      </div>
      <div className="grow">
        <RfParagraphSmall>
          {getOptionByTypeAndCountry(taxId.type, taxId.country)?.label}{' '}
          {concealValue ? concealTaxIdValue(taxId.value) : taxId.value}
        </RfParagraphSmall>
      </div>
    </div>
  )
}
