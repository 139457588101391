import { Modal, ModalContent, ModalHeader } from 'components/Modal'
import ConfettiIcon from 'components/icons/ConfettiIcon'

import useModalTracking from 'hooks/useModalTracking'

export default function PaymentSuccessModal({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  useModalTracking(isOpen, {
    modal_group: 'dunning',
    modal_name: 'payment success confirmation'
  })

  const handleClose = () => {
    setIsOpen(false)
    window.location.reload()
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-screen-lg"
      scrollContent={false}
    >
      <div className="flex flex-col items-center">
        <ModalHeader>
          <ConfettiIcon />
        </ModalHeader>
        <ModalContent className="py-5" scrollContent={false}>
          <p className="text-center font-medium">Thank you for your payment!</p>
          <p className="max-w-xl text-center">
            We’ll send an email with your payment receipt shortly.
          </p>
        </ModalContent>
      </div>
    </Modal>
  )
}
