import Banner from 'components/Banner'
import Button from 'components/Button'

import { CohortsShowingNpsSurveySeasonFieldsFragment } from 'gql'

import { trackNpsSurveyClicked } from 'utils/tracking/analytics'

type SeasonFlagFields = Pick<CohortsShowingNpsSurveySeasonFieldsFragment, 'name' | 'year'>

export const getNPSBannerDismissalFlag = (season: SeasonFlagFields) => {
  return `dismiss-nps-banner-${season.name}-${season.year}`
}

export const getNPSCompletionFlag = (season: SeasonFlagFields) => {
  return `complete-nps-banner-${season.name}-${season.year}`
}

type NpsBannerProps = {
  page: string
  liveProgramName: string
  userId: string
  season: CohortsShowingNpsSurveySeasonFieldsFragment
}

export default function NpsBanner({
  page,
  liveProgramName,
  userId,
  season
}: NpsBannerProps) {
  const text =
    season.npsSurveyText || 'Take our quick survey to help us shape future courses.'

  const handleClick = () => {
    window.localStorage.setItem(npsBannerDismissalFlag, '1')
    window.localStorage.setItem(npsCompletionFlag, '1')

    trackNpsSurveyClicked({
      page: page,
      live_program_name: liveProgramName,
      user_id: userId
    })
    window.open(`${season.npsSurveyLink}?program=${liveProgramName}&pid=${userId}`)
    window.location.reload()
  }

  const npsBannerDismissalFlag = getNPSBannerDismissalFlag(season)
  const npsCompletionFlag = getNPSCompletionFlag(season)

  const isDismissed = window.localStorage.getItem(npsBannerDismissalFlag) === '1'
  if (isDismissed) return null

  return (
    <Banner
      cta={<Button onClick={handleClick}>Begin Survey</Button>}
      onDismiss={() => {
        window.localStorage.setItem(npsBannerDismissalFlag, '1')
      }}
    >
      {text}
    </Banner>
  )
}
