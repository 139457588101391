import RfHeader1Alt from 'components/typography/RfHeader/RfHeader1Alt'

import { onEnterKeyPress } from 'utils/keyboard'

export interface BulkEmailStepProps {
  answer: string
  handleSetAnswer: (key: string, value: string) => void
  skip: () => void
  numSeats: number
}

const BulkEmailStep = ({
  answer,
  handleSetAnswer,
  skip,
  numSeats
}: BulkEmailStepProps) => {
  return (
    <>
      <RfHeader1Alt>Who else is on your team?</RfHeader1Alt>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="emails" className="font-semibold">
            Add teammate by email. Your plan has {numSeats} seats.
          </label>
          <small>Separate email addresses with commas, please!</small>
          <textarea
            id="emails"
            defaultValue={answer}
            placeholder="Ex. joe@example.com, jane@example.com"
            className="autoexpand min-h-[126px] resize-none overflow-hidden rounded border border-gray-300 px-4 py-2 text-black shadow-none outline-none placeholder:text-rb-gray-300 hover:border-2"
            onChange={(e) => handleSetAnswer('emails', e.target.value)}
          />
        </div>
        <div
          role="button"
          onClick={skip}
          onKeyUp={onEnterKeyPress(skip)}
          tabIndex={0}
          className="cursor-pointer text-sm text-rb-gray-300"
        >
          I&#8217;ll do this later
        </div>
      </div>
    </>
  )
}

export default BulkEmailStep
