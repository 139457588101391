import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { stripePromise } from 'GlobalProviders'
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'

import Loading from 'components/Loading'

import { useStripeSetupIntentMutation } from 'gql'

const stripeOptions: StripeElementsOptions = {
  loader: 'always',
  appearance: {
    theme: 'stripe',
    labels: 'above',
    variables: {
      fontFamily: 'Inter, Helvetica Neue, arial, sans-serif',
      borderRadius: '2px',
      colorTextPlaceholder: '#9ca3af',
      fontSmooth: 'never',
      colorDanger: 'rgb(199, 61, 35)',
      spacingGridRow: 'var(--p-spacing5)'
    },

    rules: {
      '.Input': {
        boxShadow: 'none',
        padding: '8.5px 12.5px',
        borderColor: 'rgb(228, 229, 227)'
      },

      '.Input--invalid': {
        color: 'var(--colorText)',
        boxShadow: 'none'
      },

      '.Error': {
        fontSize: 'var(--fontSize3Xs)',
        marginTop: '0.5rem'
      },

      '.Label': {
        fontSize: 'var(--fontSizeMd)',
        fontWeight: '400',
        marginBottom: '0.5rem',
        lineHeight: '1rem'
      }
    }
  }
}

interface StripeElementsProps {
  children: React.ReactNode
}

export const StripeElements = ({ children }: StripeElementsProps) => (
  <StripeSetupIntentProvider>
    <ElementsWithIntent>{children}</ElementsWithIntent>
  </StripeSetupIntentProvider>
)

export const StripeElementsWithoutIntent = ({ children }: StripeElementsProps) => {
  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      {children}
    </Elements>
  )
}

const ElementsWithIntent = ({ children }: StripeElementsProps) => {
  const { stripeClientSecret, createSetupIntent, loading } = useStripeSetupIntent()

  useEffect(() => {
    // create a new setup intent if we don't have one yet
    !loading && !stripeClientSecret && createSetupIntent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!stripeClientSecret) return <Loading />

  const options: StripeElementsOptions = {
    ...stripeOptions,
    clientSecret: stripeClientSecret
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  )
}

const StripeSetupIntentContext = createContext<{
  stripeClientSecret: string | null | undefined
  createSetupIntent: () => void
  loading: boolean
} | null>(null)

export const StripeSetupIntentProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [createSetupIntentMutation, { data, error, loading }] =
    useStripeSetupIntentMutation()

  const createSetupIntent = useCallback(() => {
    createSetupIntentMutation({
      variables: { input: { userId: null } }
    })
  }, [createSetupIntentMutation])

  if (error || data?.stripeSetupIntent?.errors?.length) {
    const errorMessages = data?.stripeSetupIntent?.errors
      ?.map((e) => `Server error in StripeSetupIntent mutation: ${e}`)
      .join(', ')
    console.error(error || errorMessages)
  }

  const stripeClientSecret = data?.stripeSetupIntent?.clientSecret

  const value = useMemo(
    () => ({ stripeClientSecret, createSetupIntent, loading }),
    [stripeClientSecret, createSetupIntent, loading]
  )

  return (
    <StripeSetupIntentContext.Provider value={value}>
      {children}
    </StripeSetupIntentContext.Provider>
  )
}

export const useStripeSetupIntent = () => {
  const context = useContext(StripeSetupIntentContext)

  if (context === null) {
    throw new Error(
      'useStripeSetupIntent must be used within a StripeSetupIntentProvider'
    )
  }

  return context
}
