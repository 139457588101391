import { ColorCircleCheckIcon, EmptyCircleRadioIcon } from 'components/icons'
import RfHeader1Alt from 'components/typography/RfHeader/RfHeader1Alt'

import { onEnterKeyPress } from 'utils/keyboard'

interface StepOption {
  id: number
  title: string
  description: string
  value: boolean
}

interface PassAssignStepProps {
  answer: boolean
  handleSetAnswer: (key: string, value: string) => void
}

interface StepSectionProps {
  option: StepOption
  answer?: boolean
  handleClick: (value: boolean) => void
}

const PassAssignOptions = [
  {
    id: 1,
    title: 'Let team members use them at will',
    description:
      'Team members will be able to use any unassigned passes to enroll in courses without any approval',
    value: false
  },
  {
    id: 2,
    title: 'I would like to assign them',
    description:
      'Team members will be unable to enroll in a live course until you have assigned them a pass',
    value: true
  }
]

const StepSection = ({ option, answer, handleClick }: StepSectionProps) => {
  const selected = option.value.toString() === answer?.toString()

  return (
    <div
      className={`flex cursor-pointer items-center gap-6 rounded-lg border-2  p-6 ${
        selected
          ? 'border-[#61A4A2] bg-[rgba(210,229,228,0.3)]'
          : 'border-gray-100 bg-white'
      }`}
      role="button"
      tabIndex={0}
      onClick={() => handleClick(option.value)}
      onKeyUp={onEnterKeyPress(() => handleClick(option.value))}
    >
      <div className="min-w-[20px] text-rb-teal-600">
        {selected ? (
          <ColorCircleCheckIcon className="text-rb-teal-300" />
        ) : (
          <EmptyCircleRadioIcon />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div
          className={`text-[20px] font-medium leading-7 ${
            selected ? 'text-teal-600' : 'text-black'
          }`}
        >
          {option.title}
        </div>
        <div
          className={`hidden leading-6 sm:flex ${
            selected ? 'text-teal-600' : 'text-rb-gray-300'
          }`}
        >
          {option.description}
        </div>
      </div>
    </div>
  )
}

const TeamPassAssignStep = ({ answer, handleSetAnswer }: PassAssignStepProps) => {
  const handleClick = (value: boolean) => {
    handleSetAnswer('manualAssignPasses', value.toString())
  }
  return (
    <>
      <RfHeader1Alt>How would you like to manage your live course passes?</RfHeader1Alt>
      <div className="flex flex-col gap-2">
        {PassAssignOptions.map((option) => (
          <StepSection
            key={option.id}
            answer={answer}
            option={option}
            handleClick={handleClick}
          />
        ))}
      </div>
    </>
  )
}

export default TeamPassAssignStep
