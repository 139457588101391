import { datadogRum } from '@datadog/browser-rum'
import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import { ModalContent } from 'components/Modal'

import { ReactComponent as PaperPlaneSendWhiteIcon } from 'images/icon--paper-plane-send-white.svg'
import { ReactComponent as PaperPlaneSendIcon } from 'images/icon--paper-plane-send.svg'

import { SendFeedbackProps } from './Feedback'

export function FurtherFeedbackModal({
  chatId,
  messageId,
  sendFeedback,
  removeDislike,
  onClose
}: {
  chatId: string
  messageId: string
  sendFeedback: (props: SendFeedbackProps) => Promise<void>
  removeDislike: () => void
  onClose?: () => void
}) {
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const canSubmit = feedbackMessage.trim().length > 0
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const mounted = useRef<boolean>(true)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const onFeedbackSubmit = async () => {
    datadogRum.addAction('AI sidekick - Submit feedback', {
      chatId,
      feedbackMessage: feedbackMessage,
      thumb: 'down'
    })
    await sendFeedback({ chatId, thumb: 'down', feedbackMessage, messageId })
    setIsSubmitted(true)
    setTimeout(() => {
      if (mounted.current) {
        onClose?.()
      }
    }, 1000)
  }

  return (
    <>
      {isSubmitted ? (
        <ModalContent>
          <div className="flex h-[304px] items-center justify-center pb-14 text-center text-xl font-semibold">
            Thank you for your feedback!
          </div>
        </ModalContent>
      ) : (
        <>
          <div className="flex justify-start px-8 text-center text-lg font-bold md:px-11">
            What do you dislike about this response?
          </div>
          <ModalContent className="flex flex-col gap-4">
            <div className="flex flex-col gap-y-6 pb-6 pt-2">
              <div className="flex justify-start text-center text-rb-gray-300">
                Your feedback will help us improve this AI
              </div>
              <div className="relative flex">
                <textarea
                  className="focus:border=rb-gray-300 w-full resize-none rounded border border-rb-gray-200 p-3"
                  style={{ minHeight: '150px' }}
                  value={feedbackMessage}
                  onChange={(e) => setFeedbackMessage(e.target.value)}
                  placeholder="Leave feedback"
                />
                <div
                  className={twMerge(
                    'absolute right-4 bottom-4 p-2',
                    feedbackMessage.trim() ? 'cursor-pointer rounded bg-rb-teal-600' : ''
                  )}
                  onClick={canSubmit ? onFeedbackSubmit : () => {}}
                >
                  {canSubmit ? (
                    <PaperPlaneSendWhiteIcon width={12} />
                  ) : (
                    <PaperPlaneSendIcon width={12} />
                  )}
                </div>
              </div>
              <Button
                className="justify-content-center rounded-full"
                variant="outline"
                fullWidth={true}
                size="medium"
                onClick={removeDislike}
              >
                Remove Dislike
              </Button>
            </div>
          </ModalContent>
        </>
      )}
    </>
  )
}
