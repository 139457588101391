import { useNavigate } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as PDiamondIcon } from 'images/p-diamond.svg'

interface MembershipCalloutProps {
  className?: string
  completeOnClick?: boolean
  recentChatsCount?: number
  limitExceeded?: boolean
  handleMinimize?: () => void
}

const MembershipCallout = ({
  className,
  recentChatsCount,
  limitExceeded,
  handleMinimize
}: MembershipCalloutProps) => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()
  const NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN = 3
  const userMessagesLeft =
    NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN - (recentChatsCount || 0)

  const ctaHref = currentUser?.is.eligibleForTrial
    ? '/try-reforge'
    : '/subscribe/choose-plan'

  const onClick = () => {
    if (handleMinimize) {
      handleMinimize()
    }

    trackCtaClicked({
      cta_location: 'AI Chat - Upgrade CTA',
      cta_type: 'card',
      text: 'Get access',
      destination: ctaHref
    })

    navigate(ctaHref)
  }

  if (!limitExceeded) {
    return (
      <div
        className={twMerge(
          'flex items-center p-4 transition-colors hover:no-underline bg-rb-blue-50',
          className
        )}
      >
        <div className="grow">
          <p className="text-md mb-0 flex items-center font-bold text-[#090B0A] [&>p]:m-0 [&>p]:block [&>p]:leading-[19px] [&>div]:ml-2 [&>div]:mr-1 [&>img]:h-4 [&>img]:w-4">
            Membership preview
          </p>

          <p className="mb-0 text-xs font-medium leading-[17px] text-[#757B74]">
            {userMessagesLeft}/3 messages left this week
          </p>
        </div>

        <Button
          size="large"
          color="premium"
          variant="fill"
          className="h-[40px] p-4 text-rb-white text-md font-bold"
          onClick={onClick}
        >
          Get access
        </Button>
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        'flex grow flex-col items-center justify-center gap-2 px-4 py-6 bg-rb-blue-50',
        className
      )}
    >
      <div className="flex w-full flex-col items-center gap-2">
        <div className="text-center text-xs font-semibold leading-[18px] text-rb-gray-300">
          You’ve used all of your free messages
        </div>
        <div className="text-center text-xl font-semibold text-rb-gray-400">
          Supercharge your work with expertise from top tech leaders
        </div>
        <div className="text-center text-sm leading-[21px] text-rb-gray-400">
          Unlock full access to courses, content, AI features, and more with one of our
          membership options.
        </div>
      </div>

      <div>
        <Button
          onClick={onClick}
          variant="fill"
          color="premium"
          className="flex h-[40px] items-center gap-2 px-4"
        >
          <span className="text-white font-semibold">
            {currentUser?.is.eligibleForTrial
              ? 'Start 14-day free trial'
              : 'Become a member'}
          </span>
          <PDiamondIcon fill={'white'} className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default MembershipCallout
