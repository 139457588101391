import Button from 'components/Button'
import Modal, { ModalContent } from 'components/Modal'

interface JoinedTeamCelebrationModalProps {
  hideModal: () => void
  isOpen: boolean
  teamName: string
}

export const JoinedTeamCelebrationModal = ({
  hideModal,
  isOpen,
  teamName
}: JoinedTeamCelebrationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={hideModal}
      header={false}
      closeOnEscape={true}
      closeOnOutsideClick={true}
      className="min-h-[490px] w-full rounded-xl 2xs:w-[360px] tl:w-[800px] lg:w-[640px]"
    >
      <ModalContent className="flex flex-col items-center justify-center bg-apply bg-right-top p-5 2xs:p-4 tl:p-8">
        <p className="text-center text-2xl font-medium leading-8">
          Congrats, you&rsquo;re now a part of the{' '}
          <span className="font-semibold">{teamName || ''}</span> membership 🎉
        </p>
        <Button onClick={hideModal} className="mt-12">
          Let&rsquo;s Go
        </Button>
      </ModalContent>
    </Modal>
  )
}

export default JoinedTeamCelebrationModal
