import { ReactNode } from 'react'

import Button, { ButtonProps } from 'components/Button'

import { cn } from 'utils/tailwind'

export interface PillProps {
  id: string
  href?: string
  className?: string
  activeClassName?: string
  onClick?: () => void
  isActive: boolean
  children: ReactNode
  size?: ButtonProps['size']
  dataTest?: string
}

const buttonBaseClassName =
  'text-xs xl:text-md whitespace-nowrap max-h-10 hover:border-rb-black'
const linkBaseClassName =
  'flex items-center justify-center text-xs xl:text-md whitespace-nowrap border-1.5 border-rb-black rounded-full transition duration-200 ease-in-out px-5 py-2 bg-white border-rb-black !no-underline mb-0 max-h-8 md:max-h-10'

const defaultClassName = 'hover:border-rb-black hover:bg-rb-black max-h-10'
const defaultActiveClassName = 'bg-rb-black text-white hover:text-white border-rb-black'
const defaultInActiveClassName =
  'bg-white text-rb-black hover:text-white hover:bg-rb-black'

const Pill = ({
  onClick,
  className,
  isActive,
  activeClassName = defaultActiveClassName,
  children,
  href,
  size = 'small',
  dataTest
}: PillProps) => (
  <Button
    className={cn(
      href ? linkBaseClassName : buttonBaseClassName,
      defaultClassName,
      defaultInActiveClassName,
      className,
      {
        [activeClassName]: isActive
      }
    )}
    variant="outline"
    size={size}
    shape="rounded-full"
    onClick={onClick}
    href={href}
    dataTest={dataTest}
  >
    <span className="font-medium">{children}</span>
  </Button>
)

export default Pill
