import { useLocation } from 'react-router-dom'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { useGlobalChat } from './GlobalChatProvider'
import { GlobalChatUI } from './GlobalChatUI'

export function GlobalChat() {
  const { pathname } = useLocation()
  const isMobile = useMediaQuery('(max-width: 671px)')
  const { currentUser } = useCurrentUser()
  const globalChatProps = useGlobalChat()

  return (
    <GlobalChatUI
      {...globalChatProps}
      currentUser={currentUser}
      isMobile={isMobile}
      pathname={pathname}
    />
  )
}
