import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useProfileEditModal } from 'domains/Profile/ProfileEditModal'

import Banner from 'components/Banner'
import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { useSetDismissedUpdateCompanyBannerAtMutation } from 'gql'

import { getPageFromPath } from 'utils/location'
import {
  trackBannerDismissed,
  trackBannerDisplayed,
  trackCtaClicked
} from 'utils/tracking/analytics'

export interface UpdateCompanyBannerProps {
  companyName: string | undefined | null
}

export function UpdateCompanyBanner({ companyName }: UpdateCompanyBannerProps) {
  const { openProfileEditModal } = useProfileEditModal()
  useEffect(() => {
    trackBannerDisplayed({ category: 'app', banner_name: 'update company name' })
  }, [])

  const history = useHistory()
  const [setDismissedUpdateCompanyBanner] = useSetDismissedUpdateCompanyBannerAtMutation({
    variables: { input: {} }
  })

  const onCtaClick = () => {
    trackCtaClicked({
      cta_location: getPageFromPath(),
      cta_type: 'button',
      destination: window.location.href,
      text: 'update profile'
    })
    history.push('/account#edit_profile')
    openProfileEditModal()
    setDismissedUpdateCompanyBanner()
  }

  return (
    <Banner
      dismissable
      onDismiss={() => {
        trackBannerDismissed({ category: 'app', banner_name: 'update company name' })
        setDismissedUpdateCompanyBanner()
      }}
      cta={<Button onClick={onCtaClick}>Update Profile</Button>}
    >
      <UpdateCompanyCopy companyName={companyName} />
    </Banner>
  )
}

function UpdateCompanyCopy({ companyName }: UpdateCompanyBannerProps) {
  return (
    <span className="flex items-center">
      <SVGIcon name="pencil-thinner" className="fill-current" />
      <div className="ml-5">
        <h3 className="mb-1 text-lg font-semibold md:mb-0">
          {companyName
            ? `Do you still work at ${companyName}?`
            : "It looks like you still haven't added your company info."}
        </h3>
        <p className="mb-0 text-base font-normal">
          Keep your profile and company information up-to-date
        </p>
      </div>
    </span>
  )
}
