import { useEffect, useState } from 'react'

import AddPaymentAndPayModal from 'domains/Subscription/AddPaymentAndPayModal'
import LeaveCheckoutConfirmationModal from 'domains/Subscription/LeaveCheckoutConfirmationModal'
import PaymentSuccessModal from 'domains/Subscription/PaymentSuccessModal'
import UpdatePaymentAndPayModal from 'domains/Subscription/UpdatePaymentAndPayModal'

import { ErrorMessage } from 'components'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { StripePaymentMethod, useDunningFlowQuery } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { formatMoney } from 'utils/moneyUtils'

const DunningFlowContainer = ({
  initiateFlow,
  setInitiateFlow
}: {
  initiateFlow: boolean
  setInitiateFlow: (arg0: boolean) => void
}) => {
  const { data, loading, error } = useDunningFlowQuery()

  if (loading) {
    // we don't want to show anything if we're still loading because loading spinners show up in weird places, disturbing the UI
    return null
  }

  if (error) {
    console.error(error)
    return <ErrorMessage />
  }

  if (!data?.currentUser) {
    return <ErrorMessage />
  }

  if (!data?.currentUser?.subscriptions?.active) {
    return <ErrorMessage />
  }

  const paymentMethods = data.currentUser.paymentMethods || []
  const activeSubscription = data.currentUser.subscriptions.active
  const amountDueNow = activeSubscription.dunningAmountDueInCents || 0
  const formattedAmountDueNow = formatMoney(amountDueNow / 100)
  const isPaidMonthly = activeSubscription.isPaidMonthly
  const subscriptionId = activeSubscription.id

  const recurringAmount = activeSubscription.stripeUpcomingInvoice?.subtotal || 0
  const formattedRecurringAmount = formatMoney(recurringAmount / 100)
  const billingDate =
    activeSubscription.stripeUpcomingInvoice?.date || activeSubscription.expiresAt

  return (
    <DunningFlow
      initiateFlow={initiateFlow}
      setInitiateFlow={setInitiateFlow}
      amountDueNow={formattedAmountDueNow}
      isPaidMonthly={isPaidMonthly}
      subscriptionId={subscriptionId}
      recurringAmount={formattedRecurringAmount}
      billingDate={billingDate}
      paymentMethods={paymentMethods}
    />
  )
}

const DunningFlow = ({
  initiateFlow,
  setInitiateFlow,
  amountDueNow,
  isPaidMonthly,
  subscriptionId,
  recurringAmount,
  billingDate,
  paymentMethods
}: {
  amountDueNow: string
  initiateFlow: boolean
  isPaidMonthly: boolean
  paymentMethods: StripePaymentMethod[]
  setInitiateFlow: (arg0: boolean) => void
  subscriptionId: string
  recurringAmount: string
  billingDate: string
}) => {
  const [openAddPaymentAndPayModal, setOpenAddPaymentAndPayModal] = useState(false)
  const [openUpdatePaymentAndPayModal, setOpenUpdatePaymentAndPayModal] = useState(false)
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false)
  const [openLeaveCheckoutModal, setOpenLeaveCheckoutModal] = useState(false)
  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)

  useEffect(() => {
    const hasPaymentMethodOnFile = !!paymentMethods.length

    if (initiateFlow && hasPaymentMethodOnFile) {
      setOpenUpdatePaymentAndPayModal(true)
      setInitiateFlow(false)
    }
    if (initiateFlow && !hasPaymentMethodOnFile) {
      setOpenAddPaymentAndPayModal(true)
      setInitiateFlow(false)
    }
  }, [initiateFlow, paymentMethods, setInitiateFlow])

  // Payment methods are sorted in GraphQL backend, putting default method first.
  const defaultPaymentMethod = paymentMethods.length > 0 ? paymentMethods[0] : undefined

  return (
    <>
      <AddPaymentAndPayModal
        amountDue={amountDueNow}
        recurringAmount={recurringAmount}
        billingDate={billingDate}
        isOpen={openAddPaymentAndPayModal}
        isPaidMonthly={isPaidMonthly}
        setOpenLeaveCheckoutModal={setOpenLeaveCheckoutModal}
        setOpenPaymentSuccessModal={setOpenPaymentSuccessModal}
        setIsOpen={setOpenAddPaymentAndPayModal}
        setInitiateFlow={setInitiateFlow}
        subscriptionId={subscriptionId}
      />
      <UpdatePaymentAndPayModal
        amountDue={amountDueNow}
        recurringAmount={recurringAmount}
        billingDate={billingDate}
        isOpen={openUpdatePaymentAndPayModal}
        isPaidMonthly={isPaidMonthly}
        isSmallScreen={isSmallScreen}
        paymentMethod={defaultPaymentMethod}
        setIsOpen={setOpenUpdatePaymentAndPayModal}
        setInitiateFlow={setInitiateFlow}
        setOpenAddPaymentAndPayModal={setOpenAddPaymentAndPayModal}
        setOpenPaymentSuccessModal={setOpenPaymentSuccessModal}
      />
      <PaymentSuccessModal
        isOpen={openPaymentSuccessModal}
        setIsOpen={setOpenPaymentSuccessModal}
      />
      <LeaveCheckoutConfirmationModal
        isOpen={openLeaveCheckoutModal}
        isSmallScreen={isSmallScreen}
        setIsOpen={setOpenLeaveCheckoutModal}
        setOpenPreviousModal={setInitiateFlow}
        setInitiateFlow={setInitiateFlow}
      />
    </>
  )
}

export default DunningFlowContainer
