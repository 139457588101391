import { ReactComponent as ArrowRightIcon } from 'arrow-right.svg'
import React from 'react'

// Adjust the path as necessary

interface PersonalizationCtaProps {
  onClick: () => void
}

const PersonalizationCta = ({ onClick }: PersonalizationCtaProps) => {
  return (
    <button
      className="flex w-full items-center justify-between gap-2.5 rounded-lg bg-rb-teal-200 px-4 py-3 text-white"
      onClick={onClick}
    >
      <div className="flex flex-col text-left">
        <span className="font-semibold">{"Let's personalize your conversations"}</span>
        <span className="text-sm">
          {
            "Tell us more about you. We'll tailor responses and suggest prompts to your unique context."
          }
        </span>
      </div>
      <ArrowRightIcon width={16} className="h-10 w-10" />
    </button>
  )
}

export default PersonalizationCta
