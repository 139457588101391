import { useEffect, useRef } from 'react'

import { SelectableTag } from 'components/Tag'

import {
  PreviousCompaniesFieldsFragment,
  useAddUserCompanyMutation,
  useDeleteUserCompanyMutation
} from 'gql'

interface ProfilePreviousCompaniesInputProps {
  previousCompanies: PreviousCompaniesFieldsFragment[]
  freeVariant?: boolean
}

export default function ProfilePreviousCompaniesInput({
  previousCompanies,
  freeVariant = false
}: ProfilePreviousCompaniesInputProps) {
  const companyInput = useRef<HTMLInputElement>(null)
  const [addUserCompany] = useAddUserCompanyMutation()
  const [deleteUserCompany] = useDeleteUserCompanyMutation()

  const addCompanyHandler = () => {
    if (!companyInput.current) return
    const companyName = companyInput.current.value
    companyInput.current.value = ''
    if (companyName === '') return
    if (previousCompanies.map((pc) => pc.company.name).includes(companyName)) return

    addUserCompany({
      variables: {
        input: {
          companyName
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('selected_company', addCompanyHandler)
    return () => {
      window.removeEventListener('selected_company', addCompanyHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousCompanies])

  const deleteCompany = (userCompanyId: string) => {
    if (!userCompanyId) return

    deleteUserCompany({
      variables: {
        input: {
          id: userCompanyId
        }
      }
    })
  }

  const getPreviousCompanyName = (company: PreviousCompaniesFieldsFragment) => {
    if (company.company && company.company.name) {
      return company.company.name
    }
    return ''
  }

  return (
    <div className="relative">
      <label className="text-sm font-semibold text-rb-black" htmlFor="previousCompanies">
        Where else have you gained your experience?
      </label>
      <div className="mb-1 ml-1 inline-block text-xs italic text-rb-gray-300">
        Press the <b>return</b> key to add
      </div>
      <input
        id="previousCompaniesInput"
        ref={companyInput}
        name="previous-companies-input"
        className={
          freeVariant
            ? 'js-reforge-prev-company-autocomplete focus:shadow-outline block w-full appearance-none border border-rb-gray-250 py-[9px] pl-4 text-sm placeholder-rb-gray-300 shadow placeholder:italic focus:border-rb-teal-300 focus:outline-none focus:ring-1 focus:ring-rb-teal-300'
            : 'uk-input js-reforge-prev-company-autocomplete sm:text-base--mobile text-m-medium sm:text-base'
        }
        type="text"
        placeholder="Meta, Amplitude, Stripe, ..."
        autoComplete="off"
      />
      <div className="ml-0 flex flex-wrap">
        {previousCompanies.map((userCompany) => (
          <SelectableTag
            selected
            key={`com${userCompany.id}-tag`}
            className="mt-2.5 mr-2.5"
            onChange={() => deleteCompany(userCompany.id)}
            text={getPreviousCompanyName(userCompany)}
          />
        ))}
      </div>
    </div>
  )
}
