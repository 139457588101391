import { twMerge } from 'tailwind-merge'
import { debounce } from 'throttle-debounce'
import Tribute, { TributeItem } from 'tributejs'

import { UserMention, useTeamMentionSearchLazyQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

export const scrollToActiveFroalaElement = () => {
  const focusedElement = document.activeElement
  if (!focusedElement) return null

  const froalaBox = focusedElement.closest('.fr-box')
  if (focusedElement.classList.contains('fr-element') && froalaBox) {
    const froalaRect = froalaBox.getBoundingClientRect()
    const pageHeader = document.getElementById('page-header')
    if (!pageHeader) return

    const headerRect = pageHeader.getBoundingClientRect()
    const windowHeight = window.innerHeight

    let diff = 0

    if (froalaRect.top < headerRect.height) {
      diff = froalaRect.top - headerRect.height - 50 // - 50 for extra padding
    } else if (froalaRect.bottom > windowHeight) {
      diff = froalaRect.bottom - windowHeight + 50 // +50 for extra padding
    }

    window.scrollBy(0, diff)
  }
}

/* eslint-disable camelcase */
export interface FroalaHash {
  access_key: string
  acl: string
  bucket: string
  key_start: string
  policy: string
  signature: string
}
/* eslint-enable camelcase */

type UseMentionQuery = typeof useTeamMentionSearchLazyQuery

interface FroalaConfig {
  froalaKey: string
  placeholder?: string
  froalaHash: FroalaHash
  menuContainerId?: string
  autofocus?: boolean
  editorInitializedCallback?: (editor: unknown) => void
  mentionQuery?: UseMentionQuery
  configOverwrites?: object
  disableMentions?: boolean
  setUserTagFound?: (value: boolean) => void
  tributeClasses?: string
}

export const useFroalaConfig = ({
  froalaKey,
  placeholder,
  froalaHash,
  menuContainerId,
  autofocus,
  editorInitializedCallback,
  mentionQuery = useTeamMentionSearchLazyQuery,
  configOverwrites,
  disableMentions = false,
  setUserTagFound,
  tributeClasses
}: FroalaConfig) => {
  const [getUserMentions] = mentionQuery()

  const mentions = async (query: string, callback: (users: UserMention[]) => void) => {
    try {
      const response = await getUserMentions({
        variables: {
          nameQuery: query
        }
      })

      const userMentions = (response.data && response.data.teamMentionSearch) || []

      callback(userMentions)
    } catch (e) {
      notifyError(e)
    }
  }

  const debouncedMentions = debounce(500, mentions)

  const config = {
    toolbarButtons: [
      'bold',
      'italic',
      'fontSize',
      'insertImage',
      'align',
      'quote',
      'insertLink',
      'outdent',
      'indent',
      'formatOL',
      'formatUL'
    ],
    attribution: false,
    key: froalaKey,
    charCounterCount: false,
    placeholderText: placeholder || '',
    pastePlain: false,
    pasteDeniedAttrs: ['class', 'id', 'style'],
    wordDeniedAttrs: ['class', 'id', 'style'],
    linkAlwaysBlank: true,
    quickInsertTags: [''],
    imageUploadToS3: {
      bucket: froalaHash.bucket,
      region: 's3',
      keyStart: froalaHash.key_start,
      params: {
        acl: froalaHash.acl,
        AWSAccessKeyId: froalaHash.access_key,
        policy: froalaHash.policy,
        signature: froalaHash.signature
      }
    },
    height: 200,
    heightMax: 200,
    events: {
      initialized: async function () {
        // Froala fails to initialize in time even in its own initalize function
        // https://github.com/froala/react-froala-wysiwyg/issues/189
        // https://codesandbox.io/s/lively-breeze-l9z82?file=/src/index.js:1270-1322
        await new Promise((resolve) => setTimeout(resolve, 1))
        const editor = this

        if (!disableMentions) {
          const tribute = new Tribute({
            trigger: '@',
            allowSpaces: true,
            values: function (query, callback) {
              debouncedMentions(query, callback)
            },
            lookup: 'name',
            fillAttr: 'name',
            containerClass: twMerge('tribute-container', tributeClasses),
            menuContainer:
              document.getElementById(menuContainerId || '') || document.body,
            menuItemTemplate: function (
              item: TributeItem<{ string: string; title: string }>
            ) {
              return `${item.string} <small>${item.original.title}</small>`
            },
            // @ts-expect-error
            menuItemLimit: 10,
            selectClass: 'highlight',
            selectTemplate: function (
              item: TributeItem<{ id: number; slug: string; name: string }>
            ) {
              setUserTagFound?.(true)
              return `<span class="fr-deletable fr-tribute" contenteditable="false"><a data-mention="mention" data-id="${item.original.id}" href="https://reforge.com/directory/${item.original.slug}" target="_blank">@${item.original.name}</a></span>`
            }
          })

          tribute.attach(editor.el)

          editor.events.on(
            'keydown',
            function (e: { which: number }) {
              if (e.which === 13 && tribute.isActive) {
                return false
              }
            },
            true
          )
        }

        if (autofocus && editor.events) {
          editor.events.focus(true)
        }
        if (editorInitializedCallback) {
          editorInitializedCallback(editor)
        }
      }
    },
    ...configOverwrites
  }

  return {
    config
  }
}
