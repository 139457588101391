import {
  CurrentLiveProgramsPartsFragment,
  CurrentUserBannerPartsFragment,
  Maybe,
  UpcomingSeasonEnrollmentFieldsFragment
} from 'gql'

import useCanUserEnrollAfterCoreCohortWeeks from 'hooks/enrollment/useCanUserEnrollAfterCoreCohortWeeks'

interface UseTopBannerControlsProps {
  user?: Maybe<CurrentUserBannerPartsFragment>
  enrollmentSeason?: Maybe<UpcomingSeasonEnrollmentFieldsFragment>
  currentLivePrograms?: CurrentLiveProgramsPartsFragment[]
  preEnrollmentSeason?: Maybe<UpcomingSeasonEnrollmentFieldsFragment>
}

const isContentViewerPage = () => {
  const { pathname } = window.location

  const contentViewerPages = ['/m/concept', '/m/project', '/c/']

  return (
    contentViewerPages.find((page) => {
      if (pathname.includes(page)) return true
    }) || false
  )
}

export const useTopBannerControls = ({
  user,
  enrollmentSeason,
  currentLivePrograms
}: UseTopBannerControlsProps) => {
  const canUserFinishedWithCoreCohortWeeksEnroll = useCanUserEnrollAfterCoreCohortWeeks({
    currentLivePrograms
  })

  const isAUserCohortOngoing = !!currentLivePrograms?.some(
    (program) => program?.cohort?.isOngoing
  )

  const userCanEnroll =
    !!user?.can.enrollInCohort &&
    (isAUserCohortOngoing || !canUserFinishedWithCoreCohortWeeksEnroll
      ? false
      : !!enrollmentSeason &&
        !user.is.enrolledInEnrollmentSeason &&
        !user.is.enrolledInActiveCourse)

  const shouldShowEnrollmentBanner =
    userCanEnroll && !isContentViewerPage() && !!enrollmentSeason

  return {
    shouldShowEnrollmentBanner,
    userCanEnroll
  }
}
