import {
  DndContext,
  type DraggableSyntheticListeners,
  MouseSensor,
  TouchSensor,
  useDraggable,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import type { Coordinates } from '@dnd-kit/utilities'
import React, { useEffect, useState } from 'react'

import Button from 'components/Button'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as CloseIcon } from 'images/icon--close.svg'
import { ReactComponent as ExpandIcon } from 'images/icon--expand-modal.svg'
import { ReactComponent as HamburgerIcon } from 'images/icon--hamburger.svg'
import { ReactComponent as ShrinkIcon } from 'images/icon--shrink-modal.svg'
import { ReactComponent as PencilWriteIcon } from 'images/pencil-write-icon-2.svg'

import { getXRelativeToWindow } from './helpers'

export const DraggableChatLayout = ({
  isFullScreen = false,
  children,
  top,
  left,
  onClose,
  onExpand,
  onShrink,
  onClick,
  showHeaderMenu = true,
  onMenuSideBarOpen,
  onMenuSideBarClose,
  menuSideBarIsOpen,
  startNewChat,
  activeSession
}: {
  isFullScreen?: boolean
  children: React.ReactNode
  top?: number
  left?: number
  onClose?: () => void
  onExpand?: () => void
  onShrink?: () => void
  onClick?: () => void
  showHeaderMenu?: boolean
  onMenuSideBarOpen: () => void
  onMenuSideBarClose: () => void
  menuSideBarIsOpen: boolean
  startNewChat: () => void
  activeSession: boolean
}) => {
  const { listeners, setNodeRef, transform } = useDraggable({
    id: 'draggable'
  })

  return (
    <div
      ref={setNodeRef}
      className={cn('bg-blur fixed z-[1011] w-96', {
        'inset-0 h-full w-screen lg:bg-rb-black/60 lg:p-16 lg:pb-0 lg:backdrop-blur':
          isFullScreen
      })}
      style={
        {
          top,
          left,
          '--translate-x': `${transform?.x ?? 0}px`,
          '--translate-y': `${transform?.y ?? 0}px`,
          'transform': 'translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)',
          'height': isFullScreen ? '100%' : '90%'
        } as React.CSSProperties
      }
      onClick={onClick}
      role="none"
    >
      <div
        className={cn('h-full rounded-lg border border-rb-gray-100 bg-white shadow-lg', {
          'rounded-b-none': isFullScreen
        })}
      >
        {showHeaderMenu && (
          <HeaderMenu
            className="h-12"
            isfullScreen={isFullScreen}
            key="header-menu"
            onClose={() => onClose?.()}
            onExpand={() => onExpand?.()}
            onShrink={() => onShrink?.()}
            onMenuSideBarClose={onMenuSideBarClose}
            onMenuSideBarOpen={onMenuSideBarOpen}
            menuSideBarIsOpen={menuSideBarIsOpen}
            startNewChat={startNewChat}
            listeners={listeners}
            activeSession={activeSession}
          />
        )}
        <div
          className={cn(
            'relative flex h-[calc(100%_-_3rem)] flex-row items-center gap-y-6'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

interface DraggableChatWrapperProps {
  children: React.ReactNode
  isFullScreen?: boolean
  showHeaderMenu?: boolean
  onClick?: () => void
  onExpand?: () => void
  onShrink?: () => void
  onClose?: () => void
  onMenuSideBarOpen: () => void
  onMenuSideBarClose: () => void
  menuSideBarIsOpen: boolean
  startNewChat: () => void
  activeSession: boolean
}

export const DraggableChatWrapper = ({
  isFullScreen = false,
  children,
  showHeaderMenu,
  onClick,
  onExpand,
  onShrink,
  onClose,
  onMenuSideBarOpen,
  onMenuSideBarClose,
  menuSideBarIsOpen,
  startNewChat,
  activeSession
}: DraggableChatWrapperProps) => {
  const [{ x, y }, setCoordinates] = useState<Coordinates>(() => {
    if (isFullScreen) {
      return { x: 0, y: 0 }
    }
    const x = getXRelativeToWindow(50)
    return { x, y: 70 }
  })
  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  useEffect(() => {
    if (isFullScreen) {
      setCoordinates({ x: 0, y: 0 })
    } else {
      const x = getXRelativeToWindow(50)
      setCoordinates({ x, y: 70 })
    }
  }, [isFullScreen])

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      const chatWidth = 400
      if (!isFullScreen && x + chatWidth > windowWidth) {
        setCoordinates({ x: getXRelativeToWindow(), y })
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <DndContext
      key="dnd-context"
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) => {
          return {
            x: x + delta.x,
            y: y + delta.y
          }
        })
      }}
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
    >
      <DraggableChatLayout
        isFullScreen={isFullScreen}
        top={y}
        left={x}
        onClose={onClose}
        onExpand={onExpand}
        onShrink={onShrink}
        showHeaderMenu={showHeaderMenu}
        onClick={onClick}
        menuSideBarIsOpen={menuSideBarIsOpen}
        onMenuSideBarOpen={onMenuSideBarOpen}
        onMenuSideBarClose={onMenuSideBarClose}
        startNewChat={startNewChat}
        activeSession={activeSession}
      >
        {children}
      </DraggableChatLayout>
    </DndContext>
  )
}

const HeaderMenu = ({
  className,
  isfullScreen = false,
  onClose,
  onExpand,
  onShrink,
  startNewChat,
  menuSideBarIsOpen,
  onMenuSideBarOpen,
  onMenuSideBarClose,
  listeners,
  activeSession
}: {
  className?: string
  isfullScreen: boolean
  listeners: DraggableSyntheticListeners
  onClose: () => void
  onExpand: () => void
  onShrink: () => void
  menuSideBarIsOpen: boolean
  onMenuSideBarOpen: () => void
  onMenuSideBarClose: () => void
  startNewChat: () => void
  activeSession: boolean
}) => {
  const isMobile = useMediaQuery('(max-width: 671px)')

  return (
    <div
      className={cn('flex items-center gap-2 border-b border-rb-gray px-2', className)}
    >
      <div className="relative flex gap-2 items-center">
        <Button
          variant="text-only"
          className="h-8 w-8 shrink-0 p-0"
          size="x-small"
          onClick={(e) => {
            e.stopPropagation()

            menuSideBarIsOpen ? onMenuSideBarClose() : onMenuSideBarOpen()
          }}
        >
          <HamburgerIcon className="h-4 w-4 fill-rb-gray-400" />
        </Button>
        <button onClick={startNewChat} className="h-5 w-5 mb-0.5">
          <PencilWriteIcon
            className={!activeSession ? 'fill-rb-gray-400' : 'fill-[#B5B8B4]'}
          />
        </button>
      </div>
      <div
        className="flex w-full cursor-grab items-center gap-2 h-full"
        {...listeners}
      ></div>
      <div className="flex shrink-0 gap-x-3">
        {!isMobile && (
          <div className="relative">
            <Button
              variant="text-only"
              className="h-8 w-8 shrink-0 p-0"
              size="x-small"
              onClick={(e) => {
                e.stopPropagation()
                isfullScreen ? onShrink() : onExpand()
              }}
            >
              {isfullScreen ? <ShrinkIcon width={16} /> : <ExpandIcon width={14} />}
            </Button>
          </div>
        )}
        <Button
          variant="text-only"
          className="h-8 w-8 shrink-0 p-0"
          size="x-small"
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        >
          <CloseIcon width={16} />
        </Button>
      </div>
    </div>
  )
}
