import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { InputAutocompleteDropdown as BaseInputAutocompleteDropdown } from 'components/forms/InputAutocompleteDropdown'
import { InputText as BaseInputText } from 'components/forms/InputText'
import withContainer from 'components/forms/enhancers/withContainer'
import withLabel from 'components/forms/enhancers/withLabel'
import withValidation from 'components/forms/enhancers/withValidation'

import { useExternalStylesheet } from 'hooks/useExternalStylesheet'

import FlagIcon from '../FlagIcon'
import options, { TaxIdOption, countryToCountryLabel } from './options'
import type { TaxIdType, TaxIdValue } from './types'

const labelClassNames = 'text-base font-normal block mb-2 leading-4'
const InputAutocompleteDropdown = withValidation(
  withLabel(withContainer(BaseInputAutocompleteDropdown), labelClassNames)
)

const InputText = withValidation(
  withLabel(withContainer(InputTextPreventPropsIntoDOM), labelClassNames)
)

// Usually the withValidationIcon HOC would prevent these props from hitting
// the DOM. However, to ensure a consistent experience in the BillingInfoForm,
// we do this manually here.
function InputTextPreventPropsIntoDOM({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isValid,
  hasError,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...props
}: React.ComponentProps<typeof BaseInputText> & {
  isValid?: boolean
  hasError?: boolean
}) {
  return <BaseInputText {...props} />
}

type TaxIdTypeOptionLabelProps = {
  option: TaxIdOption
  context: 'menu' | 'value'
}
export function TaxIdTypeOptionLabel({ option, context }: TaxIdTypeOptionLabelProps) {
  return (
    <div>
      <div className="flex items-center">
        <div className="mr-1 flex-initial">
          <FlagIcon country={option.country} />
        </div>
        <div className="grow">{option.label}</div>
      </div>
      {context === 'menu' && (
        <div className="text-xs">{countryToCountryLabel[option.country]}</div>
      )}
    </div>
  )
}

const optionSchema = yup
  .object()
  .shape({
    country: yup.string(),
    value: yup.string(),
    label: yup.string(),
    example: yup.string()
  })
  .nullable()

const valueSchema = yup.string()

export const taxIdIsNotRequiredSchema = yup.object().shape({
  taxIdType: optionSchema,
  taxIdValue: valueSchema
})

export const taxIdIsRequiredSchema = yup.object().shape({
  taxIdType: optionSchema.default(undefined).required('Required'),
  taxIdValue: yup.string().required('Required')
})

export type State = {
  taxIdType: TaxIdType | null
  taxIdValue: TaxIdValue | null
}

export type Value = {
  type?: TaxIdOption['type'] | null | undefined
  value?: State['taxIdValue'] | null | undefined
  country?: TaxIdOption['country'] | null | undefined
}

const noop = () => {}

type TaxIdFormProps = {
  onChange?: (nextValue: Value) => any
  onValidation?: (isValid: boolean) => any
  isRequired?: boolean
}

export default function TaxIdForm({
  onChange = noop,
  onValidation = noop
}: TaxIdFormProps) {
  const methods = useFormContext<State>()
  const { formState, watch, triggerValidation } = methods

  const values = watch()
  const { dirty, isValid } = formState

  // Avoid infinite loop if useCallback is not used.
  /* eslint-disable react-hooks/exhaustive-deps */
  const memoOnValidation = useMemo(() => onValidation, [])
  const memoOnChange = useMemo(() => onChange, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    memoOnValidation(isValid)
  }, [memoOnValidation, isValid])

  useEffect(() => {
    memoOnChange({
      type: values.taxIdType?.type ?? null,
      country: values.taxIdType?.country ?? null,
      value: values.taxIdValue
    })
    if (dirty) {
      // We want to validate both internal fields when one is changed.
      // This keeps `onValidation(isValid) up to date.
      triggerValidation()
    }
  }, [memoOnChange, values.taxIdType, values.taxIdValue, dirty, triggerValidation])

  useEffect(() => {
    triggerValidation()
  }, [triggerValidation])

  useExternalStylesheet(
    'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css'
  )

  return (
    <div className="w-full">
      <div className="flex w-full flex-row space-x-2">
        {/* onMouseDown, onTouchStart handlers prevents any modal from unexpectedly closing when removing the selected option
              in InputAutocompleteDropdown. See implementation of useOnClickOutside for more details on why. */}
        <div
          className="basis-1/2"
          onMouseDown={(event) => event.stopPropagation()}
          onTouchStart={(event) => event.stopPropagation()}
        >
          <InputAutocompleteDropdown
            id="taxIdTypeInput"
            name="taxIdType"
            label="Tax ID Type"
            options={options}
            formatOptionLabel={(option: TaxIdOption, formatOptionLabelMeta) => (
              <TaxIdTypeOptionLabel
                option={option}
                context={formatOptionLabelMeta.context}
              />
            )}
            getOptionValue={(option: TaxIdOption) =>
              `${option.label} ${countryToCountryLabel[option.country]}`
            }
          />
        </div>
        <div className="basis-1/2">
          <InputText
            id="taxIdValueInput"
            name="taxIdValue"
            label="Tax ID #"
            placeholder={values.taxIdType?.example ?? 'Select type...'}
          />
        </div>
      </div>
    </div>
  )
}
