import { useCallback, useContext } from 'react'

import Button from 'components/Button'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { BillingAddressFragment } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

import TrackingContext from '../../contexts/TrackingContext'
import { StripeTaxId } from '../../types'
import TaxIdSummary from '../TaxIdSummary'

interface BillingInfoSummaryProps {
  fullName: string
  billingAddress: BillingAddressFragment
  taxId?: StripeTaxId
  onUpdateClick: () => void
}

const BillingInfoSummary = ({
  fullName,
  billingAddress,
  taxId,
  onUpdateClick
}: BillingInfoSummaryProps) => {
  const trackingContext = useContext(TrackingContext)

  const handleUpdateClick = useCallback(() => {
    if (trackingContext.location) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: trackingContext.location,
        text: 'update billing'
      })
    }

    onUpdateClick()
  }, [onUpdateClick, trackingContext.location])

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <RfParagraphMediumBold>{fullName}</RfParagraphMediumBold>
        <Button variant="text-only" onClick={handleUpdateClick} size="x-small">
          Update
        </Button>
      </div>
      <RfParagraphSmall>{billingAddress.line1}</RfParagraphSmall>
      {billingAddress.line2 && (
        <RfParagraphSmall>{billingAddress.line2}</RfParagraphSmall>
      )}
      <RfParagraphSmall>{`${billingAddress.postal_code} ${billingAddress.city}${
        billingAddress.state ? ` ${billingAddress.state}` : ''
      }, ${billingAddress.country}`}</RfParagraphSmall>
      {taxId ? (
        <div className="mt-1">
          <TaxIdSummary taxId={taxId} />
        </div>
      ) : null}
    </div>
  )
}

export default BillingInfoSummary
