import React, { useEffect, useState } from 'react'

import ProfilePreviousCompaniesInput from 'domains/Profile/ProfilePreviousCompaniesInput'
import TextAreaQuestion from 'domains/Questioner/TextAreaQuestion'
import AvatarPicker from 'domains/User/AvatarPicker'
import UserAvatarInfo from 'domains/User/UserAvatarInfo'

import Dropdown from 'components/forms/Dropdown'
import SlackIcon from 'components/icons/SlackIcon'

import {
  ABOUT_ME_TEXT_MAX_CHARACTERS,
  SLACK_RESPONSE_TIME_OPTIONS
} from 'constants/profile'

import {
  PreviousCompaniesFieldsFragment,
  ProfileEditModalUserFieldsFragment,
  ProfileEditModalUserProfileFieldsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useSearchCompany } from 'hooks/useSearchCompany'

function FieldErrorMessage({ message }: { message: string }) {
  return <div className="text-rose-600 text-xs">{message}</div>
}

interface GeneralTabProps {
  activeTab: number
  avatarUrl?: string
  isEditingImage: boolean
  onNewAvatar: (dataUrl: string) => void
  previousCompanies: PreviousCompaniesFieldsFragment[]
  profile: ProfileEditModalUserProfileFieldsFragment
  setIsUpdatingAvatar: (isUpdatingAvatar: boolean) => void
  setProfile: React.Dispatch<
    React.SetStateAction<ProfileEditModalUserProfileFieldsFragment>
  >
  timezones: string[]
  user: ProfileEditModalUserFieldsFragment
  formErrors: Record<string, string>
}

const GeneralTab = ({
  activeTab,
  avatarUrl,
  isEditingImage,
  onNewAvatar,
  previousCompanies,
  profile,
  setIsUpdatingAvatar,
  setProfile,
  timezones,
  user,
  formErrors
}: GeneralTabProps) => {
  const [sliderFocused, setSliderFocused] = useState(false)
  const handleOnFocus = () => setSliderFocused(true)
  const handleOnBlur = () => setSliderFocused(false)

  const allowDirectMessages = profile.allowDirectMessages || false
  const slackResponseTime = profile.responseTime

  const { companies, companiesLoading } = useSearchCompany()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (companiesLoading) return

    const allowFormSubmitOnEnter = false
    const usingWithForm = false

    window.Reforge.autocomplete(
      '.js-reforge-company-autocomplete',
      companies,
      10,
      allowFormSubmitOnEnter,
      usingWithForm
    )
    window.Reforge.autocomplete(
      '.js-reforge-prev-company-autocomplete',
      companies,
      10,
      allowFormSubmitOnEnter,
      usingWithForm
    )
  }, [companiesLoading, companies])

  return (
    <>
      {isEditingImage && (
        <>
          <hr className="border-t-1.25 border-solid border-rb-gray-50" />
          <AvatarPicker
            avatarUrl={avatarUrl}
            onCancel={() => setIsUpdatingAvatar(false)}
            onDone={onNewAvatar}
          />
        </>
      )}

      <div
        className={isEditingImage || activeTab !== 1 ? 'uk-hidden' : ''}
        data-test="member-profile-edit-general-tab"
      >
        <div className="flex flex-col items-center sm:flex-row sm:items-start">
          <UserAvatarInfo
            tailwind="flex w-full pb-4 sm:w-1/2"
            user={profile}
            avatarUrl={avatarUrl}
            onEditAvatarClick={() => setIsUpdatingAvatar(true)}
            editableAvatar
          />
          {user.slackUserId && (
            <div className="mb-2 w-full sm:w-1/2">
              <div className="flex pb-2">
                <SlackIcon />
                <div className="pl-2 text-sm font-semibold text-rb-black">
                  Slack Preferences
                </div>
              </div>
              <div className="flex items-center pb-4">
                <label className="relative inline-block h-6 w-[50px]">
                  <input
                    type="checkbox"
                    className="h-0 w-0 opacity-0"
                    checked={allowDirectMessages}
                    onChange={() => {
                      setProfile((existingProfile) => ({
                        ...existingProfile,
                        allowDirectMessages: !profile.allowDirectMessages,
                        slackAllowDirectMessages: !profile.allowDirectMessages
                      }))
                    }}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                  />
                  <span
                    className={`absolute inset-0 cursor-pointer rounded-[15px] ${
                      allowDirectMessages
                        ? 'bg-rb-blue-100 before:translate-x-[26px]'
                        : 'bg-rb-gray-100'
                    } ${
                      sliderFocused ? 'outline-reforge-blue shadow-slider' : ''
                    } duration-400 before:absolute before:bottom-0.5 before:left-0.5 before:h-5 before:w-5 before:rounded-[50%] before:bg-white before:duration-400 before:content-['']`}
                  />
                </label>
                <div className="pl-1 text-xs font-medium xs:text-sm">
                  Allow others to message me from my profile{' '}
                </div>
              </div>
              {allowDirectMessages && (
                <Dropdown
                  options={SLACK_RESPONSE_TIME_OPTIONS}
                  placeholder="What is your Slack availability?"
                  selection={slackResponseTime}
                  setSelection={(value = '') => {
                    setProfile((existingProfile) => ({
                      ...existingProfile,
                      responseTime: value as string
                    }))
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className="mb-6 flex flex-col sm:flex-row">
          <div className="mb-6 w-full sm:mb-0 sm:w-1/2 sm:pr-5">
            <label className="text-sm font-semibold text-rb-black" htmlFor="firstName">
              First Name *
            </label>
            <input
              id="firstName"
              className="uk-input text-m-medium sm:text-base"
              name="firstName"
              type="text"
              value={profile.firstName || ''}
              disabled={currentUser?.is?.provisionedByScim}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  firstName: e.target.value
                }))
              }
            />
            {formErrors.firstName && <FieldErrorMessage message={formErrors.firstName} />}
          </div>
          <div className="w-full sm:w-1/2">
            <label className="text-sm font-semibold text-rb-black" htmlFor="lastName">
              Last Name *
            </label>
            <input
              id="lastName"
              className="uk-input text-m-medium sm:text-base"
              name="lastName"
              type="text"
              value={profile.lastName || ''}
              disabled={currentUser?.is?.provisionedByScim}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  lastName: e.target.value
                }))
              }
            />
            {formErrors.lastName && <FieldErrorMessage message={formErrors.lastName} />}
          </div>
        </div>

        <div className="mb-6 flex flex-col sm:flex-row">
          <div className="mb-6 w-full sm:mb-0 sm:w-1/2 sm:pr-5">
            <label className="text-sm font-semibold text-rb-black" htmlFor="location">
              Where Are You Located?
            </label>
            <input
              id="locationInput"
              className="uk-input text-m-medium sm:text-base"
              name="location"
              type="text"
              placeholder="e.g. San Francisco, CA"
              value={profile.location || ''}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  location: e.target.value
                }))
              }
            />
          </div>
          {!!timezones && (
            <div className="w-full sm:w-1/2">
              <label className="text-sm font-semibold text-rb-black" htmlFor="location">
                Time Zone
              </label>
              <select
                id="timezoneInput"
                className="uk-select text-m-medium sm:text-base"
                name="timezone"
                value={profile.timezone || ''}
                onChange={(e) =>
                  setProfile((existingProfile) => ({
                    ...existingProfile,
                    timezone: e.target.value
                  }))
                }
              >
                <option value="" />
                {timezones &&
                  timezones.map((tz: string) => (
                    <option key={tz} value={tz}>
                      {tz.replace(/_/g, ' ')}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>

        <div className="mb-6 flex flex-col sm:flex-row">
          <div className="mb-6 w-full sm:mb-0 sm:w-1/2 sm:pr-5">
            <label className="text-sm font-semibold text-rb-black" htmlFor="role">
              Current Role or Title *
            </label>
            <input
              id="roleInput"
              className="uk-input text-m-medium sm:text-base"
              name="role"
              type="text"
              value={profile.role || ''}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  role: e.target.value
                }))
              }
            />
            {formErrors.role && <FieldErrorMessage message={formErrors.role} />}
          </div>
          <div className="relative w-full sm:w-1/2">
            <label className="text-sm font-semibold text-rb-black" htmlFor="companyName">
              Current Company *
            </label>
            <input
              id="companyInput"
              className="uk-input js-reforge-company-autocomplete text-m-medium sm:text-base"
              name="companyName"
              type="text"
              value={profile.companyName || ''}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  companyName: e.target.value
                }))
              }
            />
            {formErrors.companyName && (
              <FieldErrorMessage message={formErrors.companyName} />
            )}
          </div>
        </div>

        <div className="mb-6 flex flex-col sm:flex-row">
          <ProfilePreviousCompaniesInput previousCompanies={previousCompanies} />
        </div>

        <div className="mb-6">
          <TextAreaQuestion
            overflowYAuto
            labelClassName="text-sm font-semibold text-rb-black mb-0"
            className="flex-1 text-sm tl:text-base"
            label={`Short bio ${currentUser?.is.creator ? ' *' : ''}`}
            placeholder="What do you want other Reforge members to know?"
            maxChars={ABOUT_ME_TEXT_MAX_CHARACTERS}
            initialResponse={profile.aboutMeText}
            onChange={(val: string) =>
              setProfile((existingProfile) => ({
                ...existingProfile,
                aboutMeText: val
              }))
            }
            dataTest="member-profile-edit-modal-bio-input"
          />
          {formErrors.aboutMeText && (
            <FieldErrorMessage message={formErrors.aboutMeText} />
          )}
        </div>

        <div className="my-6 flex flex-col sm:flex-row">
          <div className="mb-6 w-full sm:mb-0 sm:w-1/2 sm:pr-5">
            <label className="text-sm font-semibold text-rb-black" htmlFor="linkedin">
              LinkedIn
            </label>
            <input
              id="linkedinInput"
              className="uk-input text-m-medium sm:text-base"
              name="linkedin"
              placeholder="https://www.linkedin.com/in/username/"
              type="text"
              value={profile.linkedin || ''}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  linkedin: e.target.value
                }))
              }
            />
          </div>
          <div className="w-full sm:w-1/2">
            <label className="text-sm font-semibold text-rb-black" htmlFor="twitter">
              X (formerly Twitter)
            </label>
            <input
              id="twitterInput"
              className="uk-input text-m-medium sm:text-base"
              name="twitter"
              placeholder="https://www.x.com/username"
              type="text"
              value={profile.twitter || ''}
              onChange={(e) =>
                setProfile((existingProfile) => ({
                  ...existingProfile,
                  twitter: e.target.value
                }))
              }
            />
          </div>
        </div>

        {currentUser?.is.creator && (
          <div className="my-6 flex flex-col sm:flex-row">
            <div className="mb-6 w-full sm:mb-0 sm:w-1/2 sm:pr-5">
              <label className="text-sm font-semibold text-rb-black" htmlFor="ctaText">
                Profile call to action label
              </label>
              <input
                id="cta-text-input"
                className="uk-input text-m-medium sm:text-base"
                name="ctaText"
                placeholder="Work with me"
                type="text"
                value={profile.ctaText || ''}
                onChange={(e) =>
                  setProfile((existingProfile) => ({
                    ...existingProfile,
                    ctaText: e.target.value
                  }))
                }
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label className="text-sm font-semibold text-rb-black" htmlFor="ctaUrl">
                Profile call to action link
              </label>
              <input
                id="cta-url-input"
                className="uk-input text-m-medium sm:text-base"
                name="ctaUrl"
                placeholder="e.g. http://calendly.com OR mailto:marketing@reforge.com"
                type="text"
                value={profile.ctaUrl || ''}
                onChange={(e) =>
                  setProfile((existingProfile) => ({
                    ...existingProfile,
                    ctaUrl: e.target.value
                  }))
                }
              />
              {formErrors.ctaUrl && <FieldErrorMessage message={formErrors.ctaUrl} />}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default GeneralTab
