import React, { useCallback, useEffect, useState } from 'react'

import { Loading } from 'components'
import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { ModalContent } from 'components/Modal'

import { AiPersonalization, ArtifactTopicFilter } from 'gql'

import PersonalizeContent from './PersonalizeContent'

interface PersonalizeProps {
  onSave?: () => void
  onCancel?: () => void
  dedupedTopicsAndFunctions: ArtifactTopicFilter[]
  loading: boolean
  formIsDirty: boolean
  setFormIsDirty: (formIsDirty: boolean) => void
  refetch?: () => void
  personalizationData?: AiPersonalization | null
}

export const Personalize = ({
  onSave,
  onCancel,
  dedupedTopicsAndFunctions,
  loading,
  personalizationData,
  formIsDirty,
  setFormIsDirty
}: PersonalizeProps) => {
  const handleSave = () => {
    onSave?.()
  }

  const handleCancel = () => {
    onCancel?.()
  }

  return (
    <div className="rounded-lg bg-white p-6 shadow-md h-full">
      {loading ? (
        <Loading />
      ) : (
        <PersonalizeContent
          onSave={handleSave}
          dedupedTopicsAndFunctions={dedupedTopicsAndFunctions}
          onCancel={handleCancel}
          formIsDirty={formIsDirty}
          setFormIsDirty={setFormIsDirty}
          personalizationData={personalizationData}
        />
      )}
    </div>
  )
}

interface CancelPersonalizationConfirmationModalProps {
  onConfirm: () => void
  onClose: () => void
}
export const CancelPersonalizationConfirmationModal = ({
  onConfirm,
  onClose
}: CancelPersonalizationConfirmationModalProps) => {
  return (
    <>
      <div className="flex justify-start px-8 text-center text-lg font-bold md:px-11">
        You have unsaved changes.
      </div>
      <ModalContent className="flex flex-col gap-4">
        <div className="py-6">
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to cancel personalization changes? All unsaved changes
              will be lost.
            </p>
          </div>
          <div className="mt-4 flex justify-end space-x-4 pb-4">
            <Button variant="outline" color="default" onClick={onClose}>
              Back
            </Button>
            <Button variant="fill" color="default" onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </ModalContent>
    </>
  )
}

export function usePersonalizationConfirmationModal() {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmCallback, setConfirmCallback] = useState<() => void>()

  const handleClose = useCallback(() => {
    setIsModalOpen(false)
    closeGlobalModal()
  }, [closeGlobalModal, setIsModalOpen])

  const handleConfirm = useCallback(() => {
    confirmCallback && confirmCallback()
    handleClose()
  }, [confirmCallback, handleClose])

  const requestConfirmation = useCallback(
    (onConfirm: () => void) => {
      setConfirmCallback(() => onConfirm)
      setIsModalOpen(true)
    },
    [setIsModalOpen, setConfirmCallback]
  )

  useEffect(() => {
    if (isModalOpen) {
      openGlobalModal(
        <CancelPersonalizationConfirmationModal
          onConfirm={handleConfirm}
          onClose={handleClose}
        />,
        {
          className:
            'w-full h-full max-h-full sm:h-full sm:max-h-full lg:h-auto lg:max-h-[calc(100%-80px)] lg:max-w-md lg:rounded-xl',
          containerClass: 'backdrop-blur-sm',
          fullWidth: true
        }
      )
    }
  }, [isModalOpen, openGlobalModal, handleClose, handleConfirm])

  return { requestConfirmation }
}
