import { useEffect, useState } from 'react'

const isBrowser = typeof window !== 'undefined'

// source: https://github.com/streamich/react-use/blob/master/src/useMedia.ts
const getInitialState = (query: string, defaultState?: boolean) => {
  // Prevent a React hydration mismatch when a default value is provided by not defaulting to window.matchMedia(query).matches.
  if (defaultState !== undefined) {
    return defaultState
  }

  if (isBrowser) {
    return window.matchMedia(query).matches
  }

  // A default value has not been provided, and you are rendering on the server, warn of a possible hydration mismatch when defaulting to false.
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      '`useMedia` When server side rendering, defaultState should be defined to prevent a hydration mismatches.'
    )
  }

  return false
}

// You can use this hook to conditionally render components based on media queries
export default function useMediaQuery(query: string, defaultState?: boolean) {
  const [matches, setMatches] = useState(getInitialState(query, defaultState))

  useEffect(() => {
    let mounted = true
    const mql = window.matchMedia(query)
    const onChange = () => {
      if (!mounted) {
        return
      }
      setMatches(!!mql.matches)
    }

    if (mql.addEventListener) {
      mql.addEventListener('change', onChange)
    } else if (mql.addListener) {
      mql.addListener(onChange)
    }
    setMatches(mql.matches)

    return () => {
      mounted = false
      if (mql.removeEventListener) {
        mql.removeEventListener('change', onChange)
      } else if (mql.removeListener) {
        mql.removeListener(onChange)
      }
    }
  }, [query])

  return matches
}
