import { Dispatch, SetStateAction, createContext } from 'react'

interface PageLoadContextType {
  setManualPageLoadTrigger: Dispatch<SetStateAction<boolean>>
  triggerPageLoadEvent: () => void
  youtubeIframeScriptLoaded: boolean
}

const PageLoadContext = createContext<PageLoadContextType>({
  setManualPageLoadTrigger: () => {},
  triggerPageLoadEvent: () => {},
  youtubeIframeScriptLoaded: false
})

export default PageLoadContext
