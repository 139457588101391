import { useEffect, useState } from 'react'

import AddPaymentAndRenewModal from 'domains/Subscription/AddPaymentAndRenewModal/AddPaymentAndRenewModal'
import ReactivationModal from 'domains/Subscription/ReactivationModal'
import ReactivationSuccessModal from 'domains/Subscription/ReactivationSuccessModal'

import { SubscriptionReactivationFieldsFragment, useUserPaymentMethodsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { FULL_DATE_FORMAT, formatInTimezone } from 'utils/date'
import notifyError from 'utils/errorNotifier'

interface IReactivationFlow {
  hasPaymentMethod: boolean
  isSmallScreen: boolean
  setShouldInitiateReactivationFlow: (arg0: boolean) => void
  shouldInitiate: boolean
  subscription: SubscriptionReactivationFieldsFragment
}

export default function ReactivationFlow({
  hasPaymentMethod,
  isSmallScreen,
  setShouldInitiateReactivationFlow,
  shouldInitiate,
  subscription
}: IReactivationFlow) {
  const [openReactivationModal, setOpenReactivationModal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [openAddPaymentAndRenewModal, setOpenAddPaymentAndRenewModal] = useState(false)
  const [, setLastFour] = useState<string | undefined>(undefined) // TODO - what's this used for? can we delete it? deleting has ramifications in the getLastFour method below, the useUserPaymentMethodsQuery hook and <ReactivationModal> and <AddPaymentAndRenewModal> rendered below
  const { currentUser } = useCurrentUser()

  const {
    loading: isFetchingLastFour,
    error,
    data
  } = useUserPaymentMethodsQuery({
    variables: { userId: currentUser!.id }
  })

  useEffect(() => {
    if (shouldInitiate && !hasPaymentMethod) {
      setOpenAddPaymentAndRenewModal(true)
    }
    if (shouldInitiate && hasPaymentMethod) {
      setOpenReactivationModal(true)
    }
  }, [shouldInitiate, hasPaymentMethod])

  const getLastFour = () => {
    if (error) return notifyError(error)
    if (data?.userPaymentMethods?.length) {
      setLastFour(data.userPaymentMethods[0].card?.last4)
    }
  }

  const upcomingChargeDate = formatInTimezone(
    subscription.expiresAt,
    null,
    FULL_DATE_FORMAT
  )

  return (
    <>
      {hasPaymentMethod ? (
        <ReactivationModal
          getLastFour={getLastFour}
          isOpen={openReactivationModal}
          setIsOpen={setOpenReactivationModal}
          setOpenConfirmationModal={setOpenConfirmationModal}
          setShouldInitiateReactivationFlow={setShouldInitiateReactivationFlow}
          subscription={subscription}
        />
      ) : (
        <AddPaymentAndRenewModal
          setOpenConfirmationModal={setOpenConfirmationModal}
          getLastFour={getLastFour}
          isOpen={openAddPaymentAndRenewModal}
          isPaidMonthly={!!subscription.isPaidMonthly}
          isSmallScreen={isSmallScreen}
          setIsOpen={setOpenAddPaymentAndRenewModal}
          setShouldInitiateReactivationFlow={setShouldInitiateReactivationFlow}
          subscriptionId={subscription.id}
        />
      )}
      {!isFetchingLastFour && (
        <ReactivationSuccessModal
          isOpen={openConfirmationModal}
          numSeats={subscription.renewSeatCount}
          setIsOpen={setOpenConfirmationModal}
          upcomingChargeDate={upcomingChargeDate}
        />
      )}
    </>
  )
}
