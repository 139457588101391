import { createContext } from 'react'

type BillingInfoFormTracking = {
  location: string | null
}

const TrackingContext = createContext<BillingInfoFormTracking>({
  location: null
})

export default TrackingContext
