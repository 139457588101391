import { useEffect } from 'react'

import { CurrentUserDocument, useTimezonesQuery, useUpdateTimezoneMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const useTimezoneDetection = (onTimezoneUpdate?: (timezone: string) => void) => {
  const { currentUser } = useCurrentUser()
  const [updateTimezone, { called }] = useUpdateTimezoneMutation({
    refetchQueries: [{ query: CurrentUserDocument, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true
  })
  const { data: timezonesData } = useTimezonesQuery()
  const timezones = timezonesData?.timezones
  const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currentUserHasNoTimezone =
    currentUser?.profile &&
    currentUser.profile?.timezone === null &&
    'timezone' in currentUser?.profile

  useEffect(() => {
    if (currentUserHasNoTimezone && timezones?.includes(detectedTimezone) && !called) {
      updateTimezone({
        variables: {
          input: {
            timezone: detectedTimezone
          }
        },
        onCompleted: () => {
          onTimezoneUpdate?.(detectedTimezone)
        }
      })
    }
  }, [
    timezones,
    called,
    currentUserHasNoTimezone,
    updateTimezone,
    detectedTimezone,
    onTimezoneUpdate
  ])
}

export default useTimezoneDetection
