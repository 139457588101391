import Pluralize from 'react-pluralize'
import { useNavigate } from 'react-router-dom-v5-compat'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { PURCHASE_FLOW_URL } from 'utils/url'

interface TrialBannerProps {
  trialStatus: string | null | undefined
  daysLeftInTrial: number | null | undefined
}

export default function TrialBanner({ trialStatus, daysLeftInTrial }: TrialBannerProps) {
  const navigate = useNavigate()
  const trialHasExpired = trialStatus === 'expired'

  const InfoDropdown = () => {
    return (
      <div
        className="w-auto max-w-sm bg-rb-gray-400 px-5 text-xs text-white"
        uk-dropdown="pos: bottom; offset: 25;"
      >
        <div className="absolute -top-2 left-1/2 -ml-2 h-4 w-4 rotate-45 bg-rb-gray-400 shadow-popupMenuTooltip" />

        <div className="uk-margin-small-bottom">HOW YOUR TRIAL WORKS:</div>
        <div className="uk-margin-small-bottom">
          We hope you enjoy exploring Reforge content during your free trial. Please note
          that some content is only accessible once you become a Member.
        </div>
        <div className="uk-margin-small-bottom flex flex-row">
          <div className="uk-margin-small-right flex items-center">
            <div className="rb-gray-500-circle rb-gray-500-circle--30 flex items-center justify-center">
              <SVGIcon name="private-user-lock" width="16" height="20" fill="white" />
            </div>
          </div>
          <div className="grow">
            Indicates content that can be unlocked for unlimited access during your trial.
            Unlocking this content is deducted from your remaining content access.
          </div>
        </div>
        <div className="flex flex-row">
          <div className="uk-margin-small-right flex items-center">
            <div className="rb-gray-500-circle rb-gray-500-circle--30 flex items-center justify-center">
              <SVGIcon name="private-user-lock" width="16" height="20" fill="white" />
            </div>
          </div>
          <div className="grow">
            Indicates content or experiences you do not have access to during your trial.
            To uphold the integrity of our community, some content and experiences are
            only offered after joining.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${
        trialHasExpired ? 'bg-rb-orange-50' : 'bg-rb-blue-50'
      } uk-width-1-1 flex flex-row items-center py-2.5 pr-2.5 pl-2.5 xs:pr-4 xs:pl-4 md:pr-8 md:pl-8 tl:py-5`}
    >
      <div className="uk-margin-small-right grow">
        <div className="flex items-center text-m-medium text-rb-black sm:text-base">
          {trialHasExpired ? (
            <>
              <SVGIcon
                fill="currentColor"
                name="diamond-exclamation"
                height="24"
                width="24"
              />
              <div className="ml-3 text-rb-black">
                Your trial has expired. Join now to take full advantage of all Reforge
                offers.
              </div>
            </>
          ) : (
            <>
              <div>
                You have{' '}
                <b>
                  <Pluralize singular="day" count={daysLeftInTrial} />
                </b>{' '}
                remaining in your trial.
              </div>
              <div className="uk-margin-small-left uk-visible@s">
                <SVGIcon
                  name="question-icon"
                  height="18"
                  width="18"
                  fill="currentColor"
                />
                <InfoDropdown />
              </div>
            </>
          )}
        </div>
      </div>

      <Button
        onClick={() => navigate(PURCHASE_FLOW_URL)}
        shape="rounded-full"
        size="small"
        className="hover:border-white hover:bg-white hover:text-rb-gray-500"
      >
        {trialHasExpired ? 'Join' : 'Join Now'}
      </Button>
    </div>
  )
}
