import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'

export default function ReforgeCardElement(props: {
  errorMessage?: string | null
  onChange: (e: StripeCardElementChangeEvent) => void
}) {
  const { errorMessage, onChange } = props

  const options = {
    hidePostalCode: true,
    style: {
      base: {
        'fontSize': '16px',
        'color': '#424770',
        'letterSpacing': '0.025em',
        'fontFamily': 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  }

  return (
    <>
      <label htmlFor="card-element" className="text-m-small font-medium sm:text-m-medium">
        Credit or Debit Card
      </label>
      <CardElement
        className="chromatic-ignore "
        key="card-element"
        id="card-element"
        options={options}
        onChange={onChange}
      />
      <div className="uk-width-1-1 uk-margin-small-top">
        {errorMessage && <div className="uk-alert uk-alert-danger">{errorMessage}</div>}
      </div>
    </>
  )
}
