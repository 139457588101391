import React from 'react'

import TagQuestion from 'domains/Questioner/TagQuestion'
import TextAreaQuestion from 'domains/Questioner/TextAreaQuestion'

import { NOT_SOCIAL_ANSWER } from 'constants/onboarding'
import {
  COMMUNITY_INTEREST_TEXT_MAX_CHARACTERS,
  LEARNING_GOALS_TEXT_MAX_CHARACTERS
} from 'constants/profile'

import {
  OnboardingQuestionPartsFragment,
  ProfileEditModalUserProfileFieldsFragment
} from 'gql'

import {
  Selection,
  selectionsToSortedString,
  stringToSelections
} from 'utils/stringUtils'

interface WhyImHereTabProps {
  autofocusField?: string
  questions: OnboardingQuestionPartsFragment[]
  profile: ProfileEditModalUserProfileFieldsFragment
  setProfile: React.Dispatch<
    React.SetStateAction<ProfileEditModalUserProfileFieldsFragment>
  >
}

const WhyImHereTab = ({
  autofocusField,
  profile,
  questions,
  setProfile
}: WhyImHereTabProps) => {
  if (questions === undefined || questions.length < 4) {
    return null
  }

  const q2 = questions[3]

  return (
    <div data-test="member-profile-edit-reason-tab">
      <TagQuestion
        identifier="community-interests"
        noTopPadding
        question={q2.question}
        choices={q2.choices}
        selections={stringToSelections(profile.communityInterestTags)}
        onSelectionChange={(selections: Selection) =>
          setProfile(
            (existingProfile) =>
              existingProfile && {
                ...existingProfile,
                communityInterestTags: selectionsToSortedString(selections)
              }
          )
        }
        singularChoice={NOT_SOCIAL_ANSWER}
      />
      <TextAreaQuestion
        autofocus={
          autofocusField === undefined || autofocusField === 'connections_textarea'
        }
        placeholder="Tell us more about the connections you’re interested in making"
        maxChars={COMMUNITY_INTEREST_TEXT_MAX_CHARACTERS}
        initialResponse={profile.communityInterestText}
        onChange={(val: string) =>
          setProfile(
            (existingProfile) =>
              existingProfile && { ...existingProfile, communityInterestText: val }
          )
        }
      />
      <TextAreaQuestion
        autofocus={autofocusField === 'excited_to_learn_textarea'}
        className="pt-4"
        label="What do you hope to learn from the content and the Reforge community you’ve joined?"
        placeholder="I’m excited to learn..."
        maxChars={LEARNING_GOALS_TEXT_MAX_CHARACTERS}
        initialResponse={profile.learningGoalsText}
        onChange={(val: string) =>
          setProfile(
            (existingProfile) =>
              existingProfile && { ...existingProfile, learningGoalsText: val }
          )
        }
      />
    </div>
  )
}

export default WhyImHereTab
