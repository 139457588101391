import { ApolloError, useApolloClient } from '@apollo/client'
import { FC, createContext, useCallback, useContext, useEffect, useMemo } from 'react'

import { AiRecentChatsDocument, AiRecentChatsQuery, useAiRecentChatsQuery } from 'gql'

import { mapNodes } from 'utils/mapNodes'

export type RecentChat = {
  extId: string
  title: string
  createdAt: Date
}

type TRecentChatsContext = {
  recentChats: RecentChat[]
  loading: boolean
  error: ApolloError | undefined
  hasNextPage: boolean
  loadMore: () => void
}

const RecentChatsContext = createContext<TRecentChatsContext | null>(null)

interface RecentChatsProviderProps {
  currentChat?: RecentChat
  userId?: string
  isChatOpen: boolean
}

export const RecentChatsContextProvider: FC<RecentChatsProviderProps> = ({
  children,
  currentChat,
  isChatOpen,
  userId
}) => {
  const apolloClient = useApolloClient()
  const { data, fetchMore, loading, error } = useAiRecentChatsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { first: 20 },
    skip: !userId || !isChatOpen
  })
  const hasNextPage = !!data?.recentChats.pageInfo.hasNextPage

  const recentChats = useMemo<RecentChat[]>(() => {
    if (!data?.recentChats) return []

    return mapNodes(data.recentChats).map((chat) => ({
      ...chat,
      createdAt: new Date(chat.createdAt)
    }))
  }, [data])

  // prepend current chat session as soon as user sends input, don't wait for stream to close
  useEffect(() => {
    if (!currentChat || !data?.recentChats) return

    const currentChatInRecentChats = recentChats.find(
      (recentChat) => recentChat.extId === currentChat?.extId
    )
    if (currentChatInRecentChats) return

    apolloClient.cache.writeQuery<AiRecentChatsQuery>({
      query: AiRecentChatsDocument,
      data: {
        ...data,
        recentChats: {
          ...data.recentChats,
          edges: [
            {
              __typename: 'AiChatEdge',
              node: {
                __typename: 'AiChat',
                ...currentChat,
                createdAt: currentChat.createdAt.toISOString()
              }
            }
          ]
        }
      }
    })
  }, [currentChat, recentChats, data, apolloClient.cache])

  const loadMore = useCallback(() => {
    if (!data || !hasNextPage) return

    fetchMore({
      variables: { after: data.recentChats.pageInfo.endCursor }
    })
  }, [data, hasNextPage, fetchMore])

  const value: TRecentChatsContext = useMemo(
    () => ({
      recentChats,
      loading,
      error,
      hasNextPage,
      loadMore
    }),
    [recentChats, loading, error, hasNextPage, loadMore]
  )

  return (
    <RecentChatsContext.Provider value={value}>{children}</RecentChatsContext.Provider>
  )
}

export const useRecentChats = () => {
  const context = useContext(RecentChatsContext)
  if (!context) {
    throw new Error('useRecentChats must be used within a RecentChatsContextProvider')
  }
  return context
}
