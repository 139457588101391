import 'froala-editor/js/plugins.pkgd.min.js'
import { useEffect } from 'react'
import FroalaEditor from 'react-froala-wysiwyg'
import { useGlobalState } from 'state'

import { useTeamMentionSearchLazyQuery } from 'gql'

import { FroalaHash, scrollToActiveFroalaElement, useFroalaConfig } from './helpers'

interface FroalaWrapperNewProps {
  model?: string
  updateModel: (nextModel: string) => void
  autofocus?: boolean
  editorInitializedCallback?: (editor: unknown) => void
  className?: string
  placeholder?: string
  replyToName?: string
  menuContainerId?: string
  dataTest?: string
  setUserTagFound?: (value: boolean) => void
}

const FroalaWrapperNew = ({
  model,
  updateModel,
  autofocus,
  editorInitializedCallback,
  className,
  placeholder,
  menuContainerId,
  replyToName,
  dataTest,
  setUserTagFound
}: FroalaWrapperNewProps) => {
  const [envVariables] = useGlobalState('envVariables')
  const froalaKey = envVariables.froalaKey || ''
  const froalaHash: FroalaHash = JSON.parse(envVariables.froalaHash) || {}

  const tagFound = !!document
    .getElementsByClassName('fr-wrapper')[0]
    ?.getElementsByClassName('fr-tribute').length

  const { config } = useFroalaConfig({
    froalaKey,
    placeholder,
    froalaHash,
    menuContainerId,
    autofocus,
    editorInitializedCallback,
    mentionQuery: useTeamMentionSearchLazyQuery,
    setUserTagFound
  })

  useEffect(() => {
    if (!tagFound) setUserTagFound?.(false)
  }, [setUserTagFound, tagFound])

  useEffect(() => {
    scrollToActiveFroalaElement()
  }, [])

  const handleModelChange = (model: string) => {
    updateModel(model)
  }

  return (
    <div data-test={dataTest} className={`froala-wrapper-new ${className || ''}`}>
      <div className="pb-2.5">{replyToName}</div>
      <FroalaEditor
        tag="textarea"
        config={config}
        model={model}
        onModelChange={handleModelChange}
      />
    </div>
  )
}

export default FroalaWrapperNew
