import { SVGIcon } from 'components/Icon'

type StyledCheckboxProps = {
  checked: boolean
  className?: string
}

export default function StyledCheckbox(props: StyledCheckboxProps) {
  const { checked, className } = props

  return (
    <span className={className}>
      {checked && (
        <SVGIcon
          className="uk-margin-small-right cursor-pointer"
          name="checkbox-ticked"
          fill="#1E1E1E"
          width="20"
          height="20"
        />
      )}
      {!checked && (
        <SVGIcon
          className="uk-margin-small-right cursor-pointer"
          name="checkbox-empty"
          fill="#D3D2D3"
          width="20"
          height="20"
        />
      )}
    </span>
  )
}
