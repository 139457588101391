// useDelayMessageTimer.ts
import { useEffect, useRef, useState } from 'react'

import { Message } from '../types'
import { mostRecentAssistantMessage } from './helpers'

const TIMER_DELAY = 5000 // 5 seconds

export const useDelayMessageTimer = ({
  isLoading,
  messages
}: {
  isLoading: boolean
  messages: Message[]
}) => {
  const [showDelayMessage, setShowDelayMessage] = useState<boolean>(false)
  const delayTimerRef = useRef<number | null>(null)

  // Clear timer if bot responds
  useEffect(() => {
    if (mostRecentAssistantMessage({ messages })) {
      if (delayTimerRef.current) {
        clearTimeout(delayTimerRef.current)
        delayTimerRef.current = null
      }
      setShowDelayMessage(false)
    }
  }, [messages])

  // Start timer after user sends message (to give feedback on long response)
  useEffect(() => {
    if (isLoading) {
      delayTimerRef.current = window.setTimeout(() => {
        setShowDelayMessage(true)
      }, TIMER_DELAY)
    }

    return () => {
      if (delayTimerRef.current) {
        clearTimeout(delayTimerRef.current)
        delayTimerRef.current = null
      }
    }
  }, [isLoading])

  return { showDelayMessage }
}
