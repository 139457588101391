import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { getErrorString } from 'components/ErrorMessage'

import { ReactComponent as WarningIcon } from 'images/icon--warning.svg'

const iconMap = {
  error: <WarningIcon className="w-4 fill-current" />
} as const

const styles = {
  error: 'text-rb-destructive-100'
} as const

export type AlertProps = {
  children: ReactNode
  className?: string
  icon?: ReactNode
  type?: 'error'
}
export function Alert({ children, className, type = 'error', icon }: AlertProps) {
  if (!children) return null

  const defaultIcon = icon ?? iconMap[type]

  return (
    <div
      className={twMerge('flex items-start text-sm', styles[type], className)}
      role="alert"
    >
      {defaultIcon && <div className="mr-2 mt-[2px]">{defaultIcon}</div>}
      <div className="flex-1">{getErrorString(children)}</div>
    </div>
  )
}
