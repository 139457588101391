import { useEffect } from 'react'

import SimpleModal from 'components/SimpleModal'

import { trackModalDisplayed } from 'hooks/useModalTracking'

export interface SubscriptionExpiredModalProps {
  onClose: () => void
  onStartPurchaseFlow: () => void
}

/**
 * SubscriptionExpiredModal
 * Users see this modal after first visit to the site after their subscription expires
 * Allows them to begin the upgrade flow
 * */
export const SubscriptionExpiredModal = ({
  onClose,
  onStartPurchaseFlow
}: SubscriptionExpiredModalProps) => {
  useEffect(() => {
    trackModalDisplayed({
      modal_group: 'reactivation_flow',
      modal_name: 'subscription_expired'
    })
  }, [])

  return (
    <SimpleModal
      renderHeaderIcon={() => 'AlertIcon'}
      renderHeaderText={() => 'Oh no! Your subscription has expired'}
      renderBodyText={() => (
        <div>
          You’ve been automatically downgraded to a free account. Reactivate your
          subscription to get access to guides, on-demand courses and more!
        </div>
      )}
      renderFooterActionButtons={() => [
        {
          size: 'small',
          variant: 'outline',
          onClick: onClose,
          children: 'Close'
        },
        {
          size: 'small',
          onClick: onStartPurchaseFlow,
          children: 'View plans'
        }
      ]}
    />
  )
}

export default SubscriptionExpiredModal
