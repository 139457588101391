import { ReactNode } from 'react'

import Button from 'components/Button'

import { cn } from 'utils/tailwind'

export interface TabProps {
  href?: string
  onClick?: () => void
  isActive?: boolean
  label: string | ReactNode
  size?: 'small' | 'default'
  dataTest?: string
}

const Tab = ({
  onClick,
  isActive,
  label,
  href,
  size = 'default',
  dataTest
}: TabProps) => (
  <Button
    className={cn(
      'h-8 shrink-0 border border-rb-gray px-6 py-0 text-center text-xs font-normal leading-[1.5] hover:border-rb-gray hover:bg-rb-gray-50 active:border-rb-gray md:text-sm',
      {
        'md:text-xs': size === 'small',
        'bg-rb-gray hover:bg-rb-gray': isActive
      }
    )}
    variant="outline"
    shape="rounded-full"
    onClick={onClick}
    href={href}
    dataTest={dataTest}
    data-test-active={isActive}
  >
    <span className="font-medium">{label}</span>
  </Button>
)

export default Tab
