import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { AlertBanner } from 'components/AlertBanner'
import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'
import { PLAN_TEAM, PlanType } from 'constants/planType'

import useMediaQuery from 'hooks/useMediaQuery'
import useOpenPurchaseFlow from 'hooks/useOpenPurchaseFlow'

import { track, trackCtaClicked } from 'utils/tracking/analytics'

export interface ExpiredBannerProps {
  planType: PlanType
  isSubscriptionOwner: boolean
  hasPaymentMethod: boolean
}

export default function ExpiredBanner({
  planType,
  isSubscriptionOwner,
  hasPaymentMethod
}: ExpiredBannerProps) {
  const history = useHistory()
  const { openPurchaseFlow, destination: purchaseFlowDestination } = useOpenPurchaseFlow({
    reactivate: true
  })

  useEffect(() => {
    track('Banner Displayed', { banner_name: 'expired', category: 'app' })
  }, [])

  const shouldUseShortCta = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const isPlanManagerWithoutPayment =
    planType === PLAN_TEAM && isSubscriptionOwner && !hasPaymentMethod

  const getCtaText = () => {
    if (!shouldUseShortCta && isPlanManagerWithoutPayment) {
      return 'View Details'
    }
    return 'Reactivate'
  }

  const handleClick = () => {
    const trackClick = (destination: string) =>
      trackCtaClicked({
        cta_location: 'expired banner',
        cta_type: 'button',
        text: 'reactivate',
        destination
      })

    if (isPlanManagerWithoutPayment) {
      const destination = '/billing'
      trackClick(destination)
      history.push(destination)
    } else {
      trackClick(purchaseFlowDestination)
      openPurchaseFlow()
    }
  }

  return (
    <AlertBanner
      type="error"
      cta={
        <Button shape="rounded-full" onClick={handleClick}>
          {getCtaText()}
        </Button>
      }
      dismissable={false}
    >
      Your subscription has expired and you&apos;ve been downgraded to a free account.
      Reactivate now for access to guides, on-demand courses, and more.
    </AlertBanner>
  )
}
