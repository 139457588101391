import { differenceInMonths } from 'date-fns'

type shouldShowRenewalBannerArgs = {
  activeSubscription: {
    isExpired?: boolean | null
    daysLeft: number
    isDunning: boolean
  }
  isCanceling: boolean
  isRenewalBannerDismissed: boolean
  isBilledViaInvoice: boolean
  hasOpenInvoices: boolean
}

export const getShouldShowRenewalBanner = ({
  activeSubscription,
  isCanceling,
  isRenewalBannerDismissed,
  isBilledViaInvoice,
  hasOpenInvoices
}: shouldShowRenewalBannerArgs): boolean => {
  return !!(
    !isRenewalBannerDismissed &&
    !isCanceling &&
    !activeSubscription.isExpired &&
    (activeSubscription.daysLeft <= 30 ||
      (isBilledViaInvoice && hasOpenInvoices && !activeSubscription.isDunning))
  )
}

export function shouldShowUpdateCompanyBanner(
  dismissedUpdateCompanyBannerAt: string | undefined | null,
  companyNameUpdatedAt: string | undefined | null
) {
  const today = new Date()
  const dimissedDate = new Date(dismissedUpdateCompanyBannerAt || today)
  const updatedDate = new Date(companyNameUpdatedAt || today)
  const MONTHS_BEFORE_STALE = 6

  if (!dismissedUpdateCompanyBannerAt && !companyNameUpdatedAt) {
    return true
  }

  if (!dismissedUpdateCompanyBannerAt) {
    return differenceInMonths(today, updatedDate) >= MONTHS_BEFORE_STALE
  }

  if (!companyNameUpdatedAt) {
    return differenceInMonths(today, dimissedDate) >= MONTHS_BEFORE_STALE
  }

  return (
    differenceInMonths(today, dimissedDate) >= MONTHS_BEFORE_STALE &&
    differenceInMonths(today, updatedDate) >= MONTHS_BEFORE_STALE
  )
}
