import { Modal, ModalContent } from 'components/Modal'
import { CheckIcon } from 'components/icons'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useModalTracking from 'hooks/useModalTracking'

import { getPageFromPath } from 'utils/location'
import { trackCtaClicked, trackModalDismissed } from 'utils/tracking/analytics'

interface ReactivationSuccessModalProps {
  isOpen: boolean
  numSeats: number
  setIsOpen: (arg0: boolean) => void
  upcomingChargeDate: string
}

export default function ReactivationSuccessModal({
  isOpen,
  setIsOpen,
  upcomingChargeDate
}: ReactivationSuccessModalProps) {
  const { showRestartFlow } = useFeatureFlags()

  useModalTracking(isOpen, {
    modal_group: 'reactivation_flow',
    modal_name: 'reactivation_success'
  })

  const handleClose = () => {
    trackModalDismissed({
      category: 'app',
      modal_group: 'reactivation_flow',
      modal_name: 'reactivation_success'
    })
    setIsOpen(false)
    window.location.reload()
  }

  const handleLinkClick = () => {
    trackCtaClicked({
      cta_location: getPageFromPath(),
      cta_type: 'link',
      text: 'Billing page'
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-screen-md"
      scrollContent={false}
    >
      <ModalContent>
        <CheckIcon className="mx-auto mb-[50px] max-h-10 w-14 fill-current text-rb-success-100 sm:mb-10" />
        <h1 className="text-center text-xl font-semibold leading-7">
          Your subscription has been {showRestartFlow ? 'restarted' : 'reactivated'}!
        </h1>
        <p className="mb-[74px] text-center text-sm font-normal">
          Your subscription will renew on {upcomingChargeDate}. You can make updates to
          your plan and payment method from the{' '}
          <a
            href="/billing"
            className="text-rb-teal-200 hover:cursor-pointer hover:text-rb-teal-600"
            onClick={handleLinkClick}
          >
            Billing page
          </a>
          .
        </p>
      </ModalContent>
    </Modal>
  )
}
