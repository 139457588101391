import { useApolloClient } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { publicApolloClient } from 'apolloClient'
import { useEffect } from 'react'

import { useDatadog } from 'components/DatadogProvider'

import { useDatadogUserSessionQuery } from 'gql'

export const SetupDatadogUserSession = () => {
  const { isInitialized } = useDatadog()
  const apolloclient = useApolloClient()
  const { data } = useDatadogUserSessionQuery({
    skip: apolloclient === publicApolloClient
  })

  const currentUser = data?.currentUser

  useEffect(() => {
    if (isInitialized && currentUser) {
      datadogRum.setUser({
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.fullName || undefined,
        accessPolicy: currentUser.accessPolicyKind,
        planName: currentUser.subscriptions?.active?.planName,
        subscriptionId: currentUser.subscriptions?.active?.id,
        teamName: currentUser.subscriptions?.active?.name
      })
    }
  }, [isInitialized, currentUser])

  return null
}
