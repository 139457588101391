// we will need to move this to a different location once we remove onboarding steps

export const TEAM_SETUP_STEP = 1
export const TEAM_ASSIGN_PASS_STEP = 2
export const BULK_EMAIL_STEP = 3
export const TOPIC_STEP = 4

export const STEP_NAMES = ['team setup', 'assign_pass', 'bulk_email', 'usage']

export const BUSINESS_MODEL_OPTIONS = [
  'Advertising',
  'Sales-led',
  'Marketplace',
  'Transactional (non-marketplace)',
  'Subscription'
]

export const AUDIENCE_OPTIONS = [
  'Business-to-Business (B2B)',
  'Business-to-Consumer (B2C)'
]

export const OLD_TOPIC_AND_FUNCTION_IDS = [
  'cf3258f0-6441-4add-acc0-71b0ca994c6a',
  'e1151550-2b02-4027-8422-b2461ae768ed',
  'f92e78fd-7aa0-4387-aa9a-0a23fecb3ca1',
  '2fcb9b0f-03bd-4290-9cec-85ffff764492',
  '5594c4d5-18e5-4f7d-aa11-0799cd2cab2f',
  '62a78181-2ec6-4d88-b216-42f644a4e3bd',
  '97bed3fc-3c63-4f78-ba3b-e42e2e46bfb1',
  'b6c4482a-a3c6-45fd-af25-7c33e8220ab4',
  'cab2daaa-a5c0-4986-89d2-b9a68a471f1f',
  '57b6b9bd-a947-45e0-9b3b-8a7e3749a0c4',
  '6259aac0-9e82-472a-b96a-60185261a8c3',
  'a68dbc12-87ff-4a8b-847d-ccb5e6d51650',
  '7da83bea-b4f6-4947-bd54-6510295cc14f',
  '5a857989-a856-4282-b5e7-9e94b054b935',
  '7210df9f-a7ea-49d6-a775-d74089252e4f',
  '7f9b7bb9-9e93-449b-bd42-43d3dd3c79fe',
  '3f6c92db-13e8-419e-ad1e-a35f438ac90a',
  'f7001311-ea91-4990-9d7c-60eb41fe731d',
  '3b46f30b-73ab-4ba1-aca5-5c7060c111f9',
  'b4491e14-7e14-4b60-8295-306a58d6f99b',
  'faa0bee5-917e-413e-9473-2cb79be52229',
  '5512809e-d0d6-4345-a46c-d708c8b7798f',
  'ac9c920e-4ef3-4352-aad9-c6306ecfea23',
  '0b9f1ab9-2f77-4578-80cb-1dada00950d1',
  '49897b8d-a892-48c6-b9d6-322b89c02129',
  'drafts.cab2daaa-a5c0-4986-89d2-b9a68a471f1f', // Some users somehow were assigned a draft of the Experimentation topic
  'd22e8ed9-a52d-4cc7-a95f-0fbb1fe8645f',
  '295ed7ae-e4e7-47ef-a5bf-4302ac1affe1',
  '0039a4c5-2c64-4420-b1af-564b75321420',
  'b2140562-5cc8-4985-98e9-1f86a875c3ec',
  'd88e2418-99ca-4cb5-a7f0-1d0e9e9d6be3',
  '39ab846d-b7f0-4fc2-8317-138065bbabbd',
  'a2287f6b-37ae-4de2-bf9d-e957728eaed4',
  'cc0a393f-e300-4c80-8b28-21ec6a7e0550',
  '902184a4-b554-4689-a1f8-84e888a5818a',
  '8909fadc-11ec-47e4-88f4-0231a786a652',
  'c68399c1-507b-4d78-97e6-028c73fa8f00',
  'a5619d72-1fff-4add-834f-a97775d9156f',
  '4e2db8bc-128c-46c2-a882-b01d8c41dce3',
  '47537632-8f3f-4106-a447-a347a7ca72b2',
  '3657902d-3de6-4ecd-b5dc-ba02383d915c',
  '4a605709-d787-42e5-b0c9-fec9732bc77a',
  '0ce6ed4d-91ca-49f7-8a5d-4421231f5e68',
  '1e59c28b-81df-458a-8057-6fc887a43d28',
  '16106d14-7e1d-44cf-92a2-ad0cdf90d5ad'
]
