import { useCallback, useEffect, useReducer, useState } from 'react'

import { Loading } from 'components'
import { Alert } from 'components/Alert'

import {
  type BillingAddressFragment,
  CustomerContactQuery,
  TaxIdsDocument,
  TaxIdsQuery,
  useCustomerContactLazyQuery,
  useDeleteCustomerTaxIdMutation,
  useTaxIdsLazyQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import BillingInfoForm from './BillingInfoForm'
import BillingInfoSummary from './components/BillingInfoSummary'
import TrackingContext from './contexts/TrackingContext'
import { FormValue, StripeTaxId } from './types'
import { isUSAddress, useSaveBillingInfo } from './utils'

/**
 * Use a state machine here to simplify UI logic.
 */
const ViewStates = {
  IsLoading: 'IsLoading',
  IsViewing: 'IsViewing',
  IsAdding: 'IsAdding',
  IsEditing: 'IsEditing'
} as const

type ViewEvents =
  | {
      type: 'SAVED_BILLING_INFO_LOADED'
      payload: {
        currentUser?: CustomerContactQuery['currentUser']
        taxIds?: TaxIdsQuery['taxIds']
      }
    }
  | {
      type: 'BILLING_INFO_SUCCESSFULLY_SAVED'
    }
  | {
      type: 'UPDATE_BTN_CLICKED'
    }
  | {
      type: 'CANCEL_BTN_CLICKED'
    }

function viewStateMachineReducer(state: keyof typeof ViewStates, event: ViewEvents) {
  switch (event.type) {
    case 'SAVED_BILLING_INFO_LOADED': {
      const billingAddress = event.payload.currentUser?.stripeCustomer?.address

      if (billingAddress && isUSAddress(billingAddress)) {
        return ViewStates.IsViewing
      }

      if (
        billingAddress &&
        !isUSAddress(billingAddress) &&
        event.payload.taxIds?.length === 0
      ) {
        return ViewStates.IsEditing
      }

      if (!billingAddress) {
        return ViewStates.IsAdding
      }

      return ViewStates.IsViewing
    }

    case 'BILLING_INFO_SUCCESSFULLY_SAVED': {
      return ViewStates.IsViewing
    }

    case 'UPDATE_BTN_CLICKED': {
      return ViewStates.IsEditing
    }

    case 'CANCEL_BTN_CLICKED': {
      return ViewStates.IsViewing
    }

    default: {
      const exhaustiveCheckEvent: never = event
      console.log('Unhandled event type', exhaustiveCheckEvent, state)
      return state
    }
  }
}

export type ExternalValues = {
  billingAddress?: BillingAddressFragment
  taxId?: StripeTaxId
}

interface StandaloneBillingInfoFormProps {
  className?: string
  onSave?: (values: ExternalValues, isComplete: boolean) => void
  onLoad?: (values: ExternalValues, isComplete: boolean) => void
  onTaxIdDelete?: () => void
  onEditStateChange?: (editState: boolean) => void
  showIsRequiredError?: boolean
  trackingLocation: string
}

export function StandaloneBillingInfoForm({
  className,
  onSave,
  onLoad,
  onTaxIdDelete,
  onEditStateChange,
  showIsRequiredError,
  trackingLocation
}: StandaloneBillingInfoFormProps) {
  /**
   * Prep state
   */
  const [error, setError] = useState<string | null>(null)

  const setErrorMessage = (message?: string) => {
    setError(
      message ||
        'Sorry, there was an issue saving your billing info. Please try again, or contact us at hello@reforge.com.'
    )
  }

  const [viewState, dispatchViewEvent] = useReducer(
    viewStateMachineReducer,
    ViewStates.IsLoading
  )

  const [isB2BPurchase, setIsB2BPurchase] = useState<boolean>(false)
  const [taxId, setTaxId] = useState<FormValue['taxId'] | null>(null)

  const user = useAssertCurrentUser()

  /**
   * Notify parent if form is in "is editing" state
   */
  useEffect(() => {
    onEditStateChange?.(viewState === ViewStates.IsEditing)
  }, [onEditStateChange, viewState])

  /**
   * Set up queries
   */
  const [
    fetchCustomerContact,
    { data: customerContactQueryData, refetch: refetchCustomerContactQuery }
  ] = useCustomerContactLazyQuery()

  const [fetchTaxIds, { data: taxIdsQueryData, refetch: refetchTaxIdsQueryData }] =
    useTaxIdsLazyQuery()

  /**
   * Prep query data for render
   */
  const name = customerContactQueryData?.currentUser?.stripeCustomer?.name || undefined
  const savedBillingAddress =
    customerContactQueryData?.currentUser?.stripeCustomer?.address || undefined
  const savedTaxId = taxIdsQueryData?.taxIds?.[0]

  const defaultValues = { name, address: savedBillingAddress, taxId: savedTaxId }
  const isTaxIdRequired = isB2BPurchase && !defaultValues.taxId

  /**
   * Run above queries on initial load only
   * (on viewState === ViewStates.IsLoading)
   */
  useEffect(() => {
    if (viewState !== ViewStates.IsLoading) return

    async function fetchData() {
      const initLoadRes = await Promise.all([fetchCustomerContact(), fetchTaxIds()])
      const [{ data: customerContactData }, { data: taxIdsData }] = initLoadRes

      dispatchViewEvent({
        type: 'SAVED_BILLING_INFO_LOADED',
        payload: {
          currentUser: customerContactData?.currentUser,
          taxIds: taxIdsData?.taxIds
        }
      })

      const savedBillingAddress =
        customerContactData?.currentUser?.stripeCustomer?.address ?? undefined

      const savedTaxId = taxIdsData?.taxIds?.[0]

      onLoad?.(
        {
          billingAddress: savedBillingAddress,
          taxId: savedTaxId
        },
        !!savedBillingAddress && (isTaxIdRequired ? !!savedTaxId : true)
      )
    }

    fetchData()
  }, [
    fetchCustomerContact,
    fetchTaxIds,
    onLoad,
    isTaxIdRequired,
    user.is.subscriptionOwner,
    viewState
  ])

  /**
   * Prep mutations
   */
  const [deleteTaxId, { loading: deleteTaxIdLoading }] = useDeleteCustomerTaxIdMutation({
    refetchQueries: [{ query: TaxIdsDocument }],
    awaitRefetchQueries: true
  })

  const [saveAll, { loading: submitLoading }] = useSaveBillingInfo()

  /**
   * Prep event handlers
   */
  const handleDeleteTaxId = useCallback(async () => {
    if (savedTaxId) {
      try {
        const deleteTaxIdResult = await deleteTaxId({
          variables: { input: { taxId: savedTaxId.id } }
        })

        if (deleteTaxIdResult?.errors?.length === 1) {
          if (
            // If for some reason the txi_* doesn't exist in Stripe,
            // assume there's nothing to delete and keep going.
            // Otherwise, alert the user.
            !deleteTaxIdResult.errors[0].message.includes(
              'does not have a Tax ID object txi_'
            )
          ) {
            setErrorMessage()
            console.error('deleteTaxId mutation failed', deleteTaxIdResult)
          }

          return
        }

        onTaxIdDelete?.()
      } catch {
        setErrorMessage()
      }
    }
  }, [deleteTaxId, savedTaxId, onTaxIdDelete])

  const handleChange = useCallback((value: FormValue) => {
    setIsB2BPurchase(value.isB2BPurchase)
    setTaxId(value.taxId)
  }, [])

  const onSubmit = async (value: FormValue) => {
    try {
      const [updateCustomerContactRes, saveTaxIdRes] = await saveAll(value)

      if (updateCustomerContactRes?.errors) {
        setErrorMessage()
        console.error('updateCustomerContact mutation failed', updateCustomerContactRes)
        return
      }

      if (saveTaxIdRes?.errors) {
        let message

        if (saveTaxIdRes.errors.toString().includes(`Invalid value for ${taxId?.type}`)) {
          message =
            'Tax ID is invalid. Please try again, or contact us at hello@reforge.com.'
        }

        setErrorMessage(message)
        console.error('saveTaxId mutation failed', saveTaxIdRes)
        return
      }

      setError(null)

      let newBillingAddress
      let newTaxId

      if (updateCustomerContactRes?.data) {
        newBillingAddress = updateCustomerContactRes.data.updateCustomerContact.address
        refetchCustomerContactQuery()
      }

      if (saveTaxIdRes?.data) {
        newTaxId = saveTaxIdRes.data.saveCustomerTaxId?.taxId
        refetchTaxIdsQueryData()
      }

      dispatchViewEvent({ type: 'BILLING_INFO_SUCCESSFULLY_SAVED' })

      onSave?.(
        { billingAddress: newBillingAddress, taxId: newTaxId },
        !!newBillingAddress && (isTaxIdRequired ? !!newTaxId : true)
      )
    } catch (e) {
      setErrorMessage(e?.message)
      console.error('A mutation failed in StandaloneBillingInfoForm', e)
    }
  }

  /**
   * Render the things
   */

  if (viewState === ViewStates.IsLoading) return <Loading />

  return (
    <TrackingContext.Provider value={{ location: trackingLocation }}>
      <div className="min-h-[72px] w-full">
        {viewState === ViewStates.IsEditing || viewState === ViewStates.IsAdding ? (
          <BillingInfoForm
            className={className}
            defaultValues={defaultValues}
            onChange={handleChange}
            onSubmit={onSubmit}
            onCancel={() => dispatchViewEvent({ type: 'CANCEL_BTN_CLICKED' })}
            onDeleteTaxId={handleDeleteTaxId}
            isTaxIdRequired={isTaxIdRequired}
            isTaxIdDeleteInProgress={deleteTaxIdLoading}
            submitLoading={submitLoading}
            shouldCancelOnEscape={viewState === ViewStates.IsEditing}
          />
        ) : null}

        {viewState === ViewStates.IsViewing && name && savedBillingAddress ? (
          <BillingInfoSummary
            onUpdateClick={() => dispatchViewEvent({ type: 'UPDATE_BTN_CLICKED' })}
            fullName={name}
            billingAddress={savedBillingAddress}
            taxId={savedTaxId}
          />
        ) : null}

        {error && <Alert className="mt-4">{error}</Alert>}

        {showIsRequiredError && !error && (
          <Alert className="mt-4">Billing information is required</Alert>
        )}
      </div>
    </TrackingContext.Provider>
  )
}
