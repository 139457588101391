import { ReactComponent as PaperAndPencilIcon } from 'paper-and-pencil-icon.svg'
import React from 'react'

import Loading from 'components/Loading'

interface GeneratedDraftCtaProps {
  onClick: () => void
  label: string
  isLoading: boolean
}

export const GeneratedDraftCta = ({
  onClick,
  label,
  isLoading
}: GeneratedDraftCtaProps) => {
  return (
    <button
      className="flex w-full max-w-[384px] items-center justify-start gap-3 rounded-lg border border-rb-teal-50 h-14 shadow-sm my-4"
      onClick={onClick}
    >
      <div className="bg-rb-teal-50 flex items-center h-full px-4 rounded-l shrink-0">
        <PaperAndPencilIcon fill="#2D2F2F" width={16} className="h-6 w-6" />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col bg-teal-50/20 space-y-1 rounded-r truncate mr-4">
          <div className="flex flex-col text-left text-sm font-semibold text-rb-black truncate">
            {label}
          </div>
          <div className="flex flex-col text-left text-xs text-rb-gray-300">
            Click to edit
          </div>
        </div>
      )}
    </button>
  )
}

export default GeneratedDraftCta
