import { RumEvent, datadogRum } from '@datadog/browser-rum'
import React, { useContext, useEffect, useState } from 'react'

import ErrorBoundary from 'components/ErrorBoundary'

const DatadogContext = React.createContext({ isInitialized: false })

interface DatadogProviderProps {
  children: React.ReactNode
}

const DD_RUM_CLIENT_TOKEN = process.env.DD_RUM_CLIENT_TOKEN
const DD_RUM_APPLICATION_ID = process.env.DD_RUM_APPLICATION_ID
const DD_ENV = process.env.DD_ENV

const parseDomain = (url: string) => {
  // Given a URL, parse the domain without sub-domains
  // Examples:
  // http://localhost:300 => localhost
  // https://www.reforge.com => reforge.com
  // http://js.stripe.com => stripe.com
  let domain = new URL(url).hostname
  const parts = domain.split('.')
  domain = parts.slice(Math.max(parts.length - 2, 0)).join('.')
  return domain
}

const eventHandler = (event: RumEvent, context: any) => {
  // Pre-filter error messages from being sent for common ignorable errors
  if (event.type === 'error') {
    const ignoredMessages = [
      'unhandledrejection handler',
      'Found schema mismatch requiring more than one reload',
      'Network request failed',
      'Unauthenticated user.',
      'Failed to fetch',
      'Loading chunk',
      'UnknownError',
      "Failed to execute 'abort' on",
      'fullscreen mode'
    ]
    ignoredMessages.forEach((message) => {
      if (event.error.message.includes(message)) {
        // Don't send the ignored errors to DD
        return false
      }
    })
  } else if (event.type === 'resource') {
    // Pre-filter resource events for vendors, etc.
    // Note: Remove or tweak if we find we need more of the resource events for session replays, etc.
    // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
    const allowedDomains = [
      'goatsofreforge.com',
      'localhost',
      'reforge.com',
      'sanity.io',
      'stripe.com'
    ]
    const domain = parseDomain(event.resource.url)
    if (!allowedDomains.includes(domain)) {
      // Stop the event from being propagated to DD
      return false
    }
    // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#context
    if (['fetch', 'xhr'].includes(event.resource.type)) {
      // event.context should exist for these events but the linter complained
      if (event.context === undefined) {
        event.context = {}
      }

      if (event.resource.type === 'xhr') {
        // Available in context
        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
        if (event.resource.url.includes('/graphql')) {
          // TODO: Find a way to enrich with the POST body for GraphQL debugging
          event.context.responseJSON = JSON.stringify(context.xhr.response)
        }
        event.context.responseStatus = context.xhr.status
      } else if (event.resource.type === 'fetch') {
        // Available in context
        // https://developer.mozilla.org/en-US/docs/Web/API/Request
        // https://developer.mozilla.org/en-US/docs/Web/API/Response
        event.context.responseHeaders = Object.fromEntries(context.response.headers)
      }
    }
  }
  // True means the event will be eligible for sending to DD (default)
  return true
}

const DatadogProvider = ({ children }: DatadogProviderProps) => {
  const [datadogRumInitialized, setDatadogRumInitialized] = useState(false)
  const shouldStartRum =
    !!DD_RUM_CLIENT_TOKEN && !!DD_RUM_APPLICATION_ID && !!DD_ENV && !datadogRumInitialized

  useEffect(() => {
    if (shouldStartRum) {
      datadogRum.init({
        applicationId: DD_RUM_APPLICATION_ID,
        clientToken: DD_RUM_CLIENT_TOKEN,
        version: process.env.HEROKU_RELEASE_VERSION || 'localhost',
        site: 'datadoghq.com',
        service: 'reforge-react-app',
        env: DD_ENV,
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: eventHandler
      })

      setDatadogRumInitialized(true)

      datadogRum.startSessionReplayRecording()
    }
  }, [shouldStartRum])

  return (
    <ErrorBoundary>
      <DatadogContext.Provider value={{ isInitialized: datadogRumInitialized }}>
        {children}
      </DatadogContext.Provider>
    </ErrorBoundary>
  )
}

export const useDatadog = () => {
  return useContext(DatadogContext)
}

export default DatadogProvider
