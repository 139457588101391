import { useEffect } from 'react'
import UIkit from 'uikit'

interface IProgramNotificationModal {
  notificationName: string
  imageUrl: string
  title: string
  message: string
}

const ProgramNotificationModal = ({
  notificationName,
  imageUrl,
  title,
  message
}: IProgramNotificationModal) => {
  const elementId = 'modal-program-notification'
  let dismissalCookieKey: string
  let hasDismissed = false
  if (notificationName) {
    dismissalCookieKey = `dismiss-${notificationName}`
    hasDismissed = window.localStorage.getItem(dismissalCookieKey) === '1'
  }

  useEffect(() => {
    if (imageUrl && message) {
      setTimeout(() => {
        showProgramNotificationModal()
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function showProgramNotificationModal() {
    const modalProgramNotification = document.getElementById(elementId)
    if (!hasDismissed && modalProgramNotification) {
      UIkit.modal(modalProgramNotification).show()
      if (dismissalCookieKey) {
        window.localStorage.setItem(dismissalCookieKey, '1')
      }
    }
  }

  return (
    <div id={elementId} uk-modal={'{"container": false}'}>
      <div className="uk-modal-dialog min-w-[300px] py-16 px-12 text-center font-sans text-black tl:min-w-[800px]">
        <button className="uk-modal-close-default" type="button" uk-close="true" />
        <div className="" style={{ backgroundImage: `url(${imageUrl}` }} />
        <h2 className="text-xl font-semibold">{title}</h2>
        <p className="text-sm" dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  )
}

export default ProgramNotificationModal
