import React from 'react'

import Button from 'components/Button'
import { ModalContent, ModalFooter } from 'components/Modal'

import { ReactComponent as CheckIcon } from 'images/icon--checkmark-thin.svg'
import { ReactComponent as AlertIcon } from 'images/p-alert.svg'

const headerIconToJSX = {
  CheckIcon: <CheckIcon className="w-14 fill-current text-rb-success-200" />,
  AlertIcon: <AlertIcon className="w-14 fill-current" />
}

type HeaderIconOption = keyof typeof headerIconToJSX

export default function SimpleModal({
  renderHeaderIcon,
  renderHeaderText,
  renderBodyText,
  renderFooterActionButtons
}: {
  renderHeaderIcon?: () => HeaderIconOption
  renderHeaderText?: () => string
  renderBodyText?: () => string | React.ReactElement<any>
  renderFooterActionButtons?: () => React.ComponentProps<typeof Button>[]
}) {
  return (
    <div>
      <ModalContent className="flex flex-col justify-between">
        <div className="flex flex-col items-center">
          {renderHeaderIcon && headerIconToJSX[renderHeaderIcon()] ? (
            <div className="flex w-full justify-center">
              {headerIconToJSX[renderHeaderIcon()]}
            </div>
          ) : null}

          {renderHeaderText ? (
            <div className="text-center text-xl font-semibold [&:not(:first-child)]:pt-[20px]">
              {renderHeaderText()}
            </div>
          ) : null}

          {renderBodyText ? (
            <div className="max-w-md text-center text-sm [&:not(:first-child)]:pt-[10px]">
              {renderBodyText()}
            </div>
          ) : null}
        </div>
      </ModalContent>

      <ModalFooter>
        {renderFooterActionButtons ? (
          <div className="flex w-full justify-center gap-4 pt-6 pb-5 md:pt-0 md:pb-0">
            {renderFooterActionButtons().map((buttonProps) => (
              <Button key={`button ${buttonProps.children}`} {...buttonProps} />
            ))}
          </div>
        ) : null}
      </ModalFooter>
    </div>
  )
}
