const REDISPLAY_DAYS = 7

export enum DismissedState {
  firstDismissal = '1',
  secondDismissal = '2'
}

export const _getCookieKey = (cookiePrefix: string, subscriptionExpiresAt: string) =>
  `${cookiePrefix}-${subscriptionExpiresAt}`

export const _createDismissTwoStageBanner = (cookiePrefix: string) => {
  return (subscriptionExpiresAt: string, daysLeft: number): void => {
    const cookieKey = _getCookieKey(cookiePrefix, subscriptionExpiresAt)
    const currentCookieValue = window.localStorage.getItem(cookieKey)

    let nextCookieValue = DismissedState.firstDismissal

    if (!currentCookieValue) {
      nextCookieValue =
        daysLeft > REDISPLAY_DAYS
          ? DismissedState.firstDismissal
          : DismissedState.secondDismissal
    } else if (currentCookieValue === DismissedState.firstDismissal) {
      nextCookieValue = DismissedState.secondDismissal
    } else {
      nextCookieValue = currentCookieValue as DismissedState
    }

    window.localStorage.setItem(cookieKey, nextCookieValue)
  }
}

export const _createGetIsTwoStageBannerDismissed = (cookiePrefix: string) => {
  return (subscriptionExpiresAt: string, daysLeft: number): boolean => {
    const currentCookieValue = window.localStorage.getItem(
      _getCookieKey(cookiePrefix, subscriptionExpiresAt)
    )

    if (currentCookieValue === DismissedState.secondDismissal) {
      return true
    } else if (currentCookieValue === DismissedState.firstDismissal) {
      return daysLeft > REDISPLAY_DAYS
    } else {
      return false
    }
  }
}

const RENEWAL_PREFIX = 'dismiss-renewal-banner'
export const dismissRenewalBanner = _createDismissTwoStageBanner(RENEWAL_PREFIX)
export const getIsRenewalBannerDismissed =
  _createGetIsTwoStageBannerDismissed(RENEWAL_PREFIX)

const EXPIRATION_PREFIX = 'dismiss-upcoming-expiration'
export const dismissUpcomingExpirationBanner =
  _createDismissTwoStageBanner(EXPIRATION_PREFIX)
export const getIsUpcomingExpirationBannerDismissed =
  _createGetIsTwoStageBannerDismissed(EXPIRATION_PREFIX)
