import { useCallback, useState } from 'react'

import LeaveCheckoutConfirmationModal from 'domains/Subscription/LeaveCheckoutConfirmationModal'
import { ExternalValues, StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm'
import {
  IS_ADDRESS_EDITING_ERROR_MESSAGE,
  NO_BILLING_ADDRESS_ERROR_MESSAGE
} from 'domains/User/BillingInfoForm/utils'

import Button from 'components/Button'
import { Modal, ModalContent, ModalTitle } from 'components/Modal'
import { StripeElements } from 'components/StripeElements'
import StyledCheckbox from 'components/StyledCheckbox'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { BillingAddressFragment, StripePaymentMethod } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'
import useModalTracking from 'hooks/useModalTracking'

import { getPageFromPath } from 'utils/location'
import { sendData } from 'utils/sendData'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import {
  DefaultPaymentMethodPayload,
  LegacyUpdatePaymentMethodPayload
} from 'typings/payloads'

export interface UpdatePaymentAndPayModalProps {
  amountDue: string
  recurringAmount: string
  billingDate: string
  paymentMethod?: StripePaymentMethod
  isOpen: boolean
  isPaidMonthly: boolean
  isSmallScreen: boolean
  setIsOpen: (arg0: boolean) => void
  setInitiateFlow: (arg0: boolean) => void
  setOpenAddPaymentAndPayModal: (arg0: boolean) => void
  setOpenPaymentSuccessModal: (arg0: boolean) => void
}

export const UpdatePaymentAndPayModal = ({
  amountDue,
  recurringAmount,
  billingDate,
  paymentMethod,
  isOpen,
  isPaidMonthly,
  isSmallScreen,
  setIsOpen,
  setInitiateFlow,
  setOpenAddPaymentAndPayModal,
  setOpenPaymentSuccessModal
}: UpdatePaymentAndPayModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [openConfirmLeaveCheckoutModal, setOpenConfirmLeaveCheckoutModal] =
    useState(false)
  const [expirationDate, setExpirationDate] = useState('')
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState(true)
  const [isAddressEditing, setIsAddressEditing] = useState(false)
  const [billingAddress, setBillingAddress] = useState<BillingAddressFragment | null>(
    null
  )

  useModalTracking(isOpen, {
    modal_group: 'dunning',
    modal_name: 'update payment'
  })

  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const ctaDisabled = !paymentMethod || !!errorMessage || loading

  const updateDefaultPaymentMethod = (paymentMethod: StripePaymentMethod) => {
    const data: DefaultPaymentMethodPayload = { default_payment_method: true }
    sendData(
      `/api/v1/stripe_payments/payment_methods/${paymentMethod.id}`,
      'PATCH',
      data,
      (errorMessage: any, responseText: any) => {
        if (errorMessage) {
          setLoading(false)
          setErrorMessage(errorMessage.errors)
          return
        }
        if (responseText) {
          setLoading(false)
          setIsOpen(false)
          setErrorMessage(null)
          setOpenPaymentSuccessModal(true)
        }
      }
    )
  }

  const updatePaymentMethod = (paymentMethod: StripePaymentMethod) => {
    setLoading(true)
    const data: LegacyUpdatePaymentMethodPayload = {
      expiry_date: expirationDate,
      default_payment_method: true,
      payment_method_id: paymentMethod.id
    }
    sendData(
      `/api/v1/stripe_payments/payment_methods/${paymentMethod.id}`,
      'PATCH',
      data,
      (errorMessage: any, responseText: any) => {
        if (errorMessage) {
          setLoading(false)
          setErrorMessage(errorMessage.errors)
          return
        }
        if (responseText) {
          if (isDefaultPaymentMethod) {
            updateDefaultPaymentMethod(paymentMethod)
          } else {
            setLoading(false)
            setIsOpen(false)
            setErrorMessage(null)
            setOpenPaymentSuccessModal(true)
          }
        }
      }
    )
  }

  const handleCtaClick = (paymentMethod?: StripePaymentMethod) => {
    if (!validateAddressFormIsComplete()) {
      return
    }

    trackCtaClicked({
      cta_location: getPageFromPath(),
      cta_type: 'button',
      text: 'Update Card'
    })
    paymentMethod && updatePaymentMethod(paymentMethod)
  }

  const handleAddCardClick = () => {
    setIsOpen(false)
    setOpenAddPaymentAndPayModal(true)
  }

  const handleExpirationChange = (e: any) => {
    setErrorMessage(null)
    setExpirationDate(e.target.value)
  }

  const handleClose = () => {
    setInitiateFlow(false)
    setIsOpen(false)
    setOpenConfirmLeaveCheckoutModal(true)
  }

  const validateAddressFormIsComplete = () => {
    if (!billingAddress) {
      setErrorMessage(NO_BILLING_ADDRESS_ERROR_MESSAGE)
      return false
    }
    if (isAddressEditing) {
      setErrorMessage(IS_ADDRESS_EDITING_ERROR_MESSAGE)
      return false
    }
    setErrorMessage(null)
    return true
  }

  const handleSavedBillingAddress = ({
    values,
    isComplete
  }: {
    values: ExternalValues
    isComplete: boolean
  }) => {
    if (isComplete && values.billingAddress) {
      setBillingAddress(values.billingAddress)
      setErrorMessage(null)
    }
  }

  const handleEditStateChange = useCallback((editState) => {
    setIsAddressEditing(editState)
    setErrorMessage(null)
  }, [])

  const thClassName =
    'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 !text-xs !font-medium !text-rb-gray-300 !tracking-widest'
  const tdClassName = 'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5'

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        className="max-w-2xl"
        scrollContent={false}
      >
        <div className="flex flex-col pb-8 md:pb-11">
          <ModalTitle className="px-8 pb-5 md:px-11">
            {isMobile
              ? 'Update Payment and Renew'
              : 'Update Payment and Renew Subscription'}
          </ModalTitle>
          <ModalContent scrollContent={false}>
            <>
              <p className="text-sm">
                Enter a new expiration date, change primary payment method, or{' '}
                <button
                  className="font-medium text-rb-teal-200 hover:cursor-pointer"
                  onClick={handleAddCardClick}
                >
                  add a new card
                </button>
                .
              </p>
              <p className="text-sm">
                Upon updating your payment method, you will be charged {amountDue} + sales
                tax and then {recurringAmount} + sales tax{' '}
                {isPaidMonthly ? 'a month for the next 12 months ' : `on ${billingDate} `}
                for your Reforge subscription, which will automatically renew every 12
                months. To manage this subscription, visit the Billing page.
              </p>

              <div className="mb-8">
                <StripeElements>
                  <StandaloneBillingInfoForm
                    trackingLocation="update-payment-and-pay-modal"
                    onSave={(values, isComplete) =>
                      handleSavedBillingAddress({ values, isComplete })
                    }
                    onLoad={(values, isComplete) =>
                      handleSavedBillingAddress({ values, isComplete })
                    }
                    onEditStateChange={handleEditStateChange}
                  />
                </StripeElements>
              </div>

              <table className="uk-table uk-table-striped uk-table-small uk-margin-small text-m-small text-rb-gray-300 sm:text-m-medium tl:text-sm">
                <thead>
                  <tr>
                    <th className={thClassName}>Type</th>
                    <th className={thClassName}>Brand</th>
                    <th className={thClassName}>Last&nbsp;4</th>
                    <th className={thClassName}>Expires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={tdClassName}>
                      {capitalizeFirstLetter(paymentMethod?.type || '')}
                    </td>
                    <td className={tdClassName}>
                      {capitalizeFirstLetter(paymentMethod?.card?.brand || '')}
                    </td>
                    <td className={tdClassName}>
                      {capitalizeFirstLetter(paymentMethod?.card?.last4 || '')}
                    </td>
                    <td className={tdClassName}>
                      <input
                        type="text"
                        className="uk-input"
                        name="expiration-date-input"
                        style={{ width: '80px', height: '30px' }}
                        placeholder="MM/YYYY"
                        value={expirationDate}
                        onChange={(e) => handleExpirationChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {errorMessage && (
                <div className="my-4 bg-rb-warning-50 p-2 text-rb-warning-100">
                  {errorMessage}
                </div>
              )}
              <div className="uk-margin-top flex-end flex flex-wrap items-center justify-end">
                <label
                  className="mb-2.5 flex-auto flex-1 lg:flex lg:items-center"
                  onClick={() => {
                    setIsDefaultPaymentMethod(!isDefaultPaymentMethod)
                  }}
                >
                  <StyledCheckbox checked={isDefaultPaymentMethod} />
                  Set as primary payment method
                </label>

                <Button
                  size="small"
                  variant="text-only"
                  disabled={loading}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  size="small"
                  disabled={ctaDisabled}
                  onClick={() => handleCtaClick(paymentMethod)}
                >
                  Update Card
                </Button>
              </div>
            </>
          </ModalContent>
        </div>
      </Modal>
      <LeaveCheckoutConfirmationModal
        isOpen={openConfirmLeaveCheckoutModal}
        isSmallScreen={isSmallScreen}
        setIsOpen={setOpenConfirmLeaveCheckoutModal}
        setOpenPreviousModal={setIsOpen}
        setInitiateFlow={setInitiateFlow}
      />
    </>
  )
}

export default UpdatePaymentAndPayModal
