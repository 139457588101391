export const twitterInputToUrl = (input: string) => {
  return input && input.indexOf('twitter') > -1
    ? input
    : `https://www.twitter.com/${input}`
}

export const linkedInInputToUrl = (input: string) => {
  return input && input.indexOf('pub/') > -1
    ? `https://www.linkedin.com/${input}`
    : `https://www.linkedin.com/in/${input}`
}
