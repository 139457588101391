import { CurrentLiveProgramsPartsFragment } from 'gql'

interface UseCanUserEnrollAfterCoreCohortWeeksProps {
  currentLivePrograms?: CurrentLiveProgramsPartsFragment[]
}

const useCanUserEnrollAfterCoreCohortWeeks = ({
  currentLivePrograms
}: UseCanUserEnrollAfterCoreCohortWeeksProps) => {
  const hasUserFinishedCohortCoreWeeks = !!currentLivePrograms?.some(
    (program) => program.cohort.coreWeeksHaveEnded
  )

  const isPostCoreWeeksEnrollmentOpen = !!currentLivePrograms?.some(
    (program) => program.cohort.postCoreWeeksEnrollmentIsOpen
  )

  const canUserEnrollAfterCoreCohortWeeks = hasUserFinishedCohortCoreWeeks
    ? isPostCoreWeeksEnrollmentOpen
    : true

  return canUserEnrollAfterCoreCohortWeeks
}

export default useCanUserEnrollAfterCoreCohortWeeks
