import React, { useEffect, useState } from 'react'

import { useProfileEditModal } from 'domains/Profile/ProfileEditModal'
import PlaceholderAvatar from 'domains/User/PlaceholderAvatar'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { SVGIcon } from 'components/Icon'

import { UserInfoPartsFragment, UserInfoProfilePartsFragment } from 'gql'

import { linkedInInputToUrl, twitterInputToUrl } from 'utils/social'
import { track } from 'utils/tracking/segment'

interface UserAvatarInfoProps {
  avatarUrl: string | null | undefined
  editableProfile?: boolean
  editableAvatar?: boolean
  onEditAvatarClick?: React.MouseEventHandler<HTMLButtonElement>
  withBorder?: boolean
  tailwind?: string
  user: UserInfoPartsFragment | UserInfoProfilePartsFragment
}

const UserAvatarInfo = (props: UserAvatarInfoProps) => {
  const {
    avatarUrl,
    editableProfile,
    editableAvatar,
    onEditAvatarClick,
    withBorder,
    tailwind
  } = props

  const profile = 'profile' in props.user ? props.user.profile : props.user

  const [user, setUser] = useState(props.user)

  useEffect(() => {
    setUser(props.user)
  }, [props.user])

  const { openProfileEditModal } = useProfileEditModal()

  const userHasAvatar = avatarUrl && !avatarUrl.includes('default_avatar')

  const handleEditClick = () => {
    openProfileEditModal()
  }
  const styles =
    tailwind ||
    `uk-width-expand@l uk-grid-collapse ${
      withBorder
        ? 'border-solid border-1.25 border-rb-gray-400 p-2.5 sm:p-3.5 tl:p-5 3xl:p-6'
        : ''
    }`
  return (
    <>
      <div className={styles} uk-grid={tailwind ? null : ''}>
        <div data-test="account-profile-avatar" className="mr-4 sm:mr-6">
          {userHasAvatar && (
            <BadgeableAvatar
              user={user}
              avatarUrl={avatarUrl}
              width="80"
              height="80"
              onEditClick={onEditAvatarClick}
              editable={editableAvatar}
              fullName={profile.firstName}
            />
          )}
          {!userHasAvatar && onEditAvatarClick && (
            <PlaceholderAvatar
              onAddPhotoClicked={onEditAvatarClick}
              width="80px"
              height="80px"
              smaller
            />
          )}
        </div>
        <div className="uk-width-expand">
          <ProfileName user={profile} />

          <div>
            {profile.role} {profile.companyName && <span>@ {profile.companyName}</span>}
          </div>

          {profile.previousCompanies && profile.previousCompanies.length > 0 && (
            <div
              data-test="profile-previous-companies"
              className="text-m-small leading-5 sm:text-m-medium"
              style={{ maxWidth: '200px' }}
            >
              {profile.previousCompanies
                .map((previousCompany) => previousCompany.company.name)
                .join(', ')}
            </div>
          )}

          <div className="text-m-small leading-5 text-rb-gray-300 sm:text-m-medium">
            {profile.location || ''}
          </div>
        </div>
        {editableProfile && (
          <div className="cursor-pointer" onClick={handleEditClick}>
            <SVGIcon name="pencil" width="16" height="16" />
          </div>
        )}
      </div>
    </>
  )
}

interface ProfileNameProps {
  user: {
    firstName?: string | null
    lastName?: string | null
    linkedin?: string | null
    twitter?: string | null
  }
}

const ProfileName = ({
  user: { firstName, lastName, linkedin, twitter }
}: ProfileNameProps) => {
  const twitterUrl = twitter && twitterInputToUrl(twitter)
  const linkedInUrl = linkedin && linkedInInputToUrl(linkedin)

  const clickedSocial = (src: string) => {
    // @ts-ignore - 'Clicked Social' event is not defined in Segment JIRA#REF-5159
    track('Clicked Social', { src })
    window.open(src)
  }

  return (
    <div className="flex items-center">
      <div className="mr-2.5 text-lg font-semibold leading-5">
        {firstName}&nbsp;{lastName}
      </div>
      {linkedInUrl && (
        <button className="mr-3" onClick={() => clickedSocial(linkedInUrl)}>
          <span>
            <SVGIcon
              className="fill-rb-gray-400 hover:fill-rb-linkedin-blue"
              name="linkedin-medium"
              width="18"
              height="18"
            />
          </span>
        </button>
      )}
      {twitterUrl && (
        <a
          className="text-rb-gray-400 hover:text-rb-blue"
          onClick={() => clickedSocial(twitterUrl)}
        >
          <span>
            <SVGIcon name="twitter-filled" width="18" height="18" />
          </span>
        </a>
      )}
    </div>
  )
}

export default UserAvatarInfo
