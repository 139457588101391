import Bugsnag from '@bugsnag/js'
import { useEffect } from 'react'

import { useCurrentUser } from 'hooks/useCurrentUser'

const SetupBugsnagUser = () => {
  const isBugsnagStarted = Bugsnag.isStarted()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (isBugsnagStarted && currentUser) {
      Bugsnag.setUser(
        currentUser.id,
        currentUser.contact.primaryEmail,
        currentUser.fullName || undefined
      )
    }
  }, [isBugsnagStarted, currentUser])

  return null
}

export default SetupBugsnagUser
