import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useCallback, useState } from 'react'

import { ExternalValues, StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm'
import {
  IS_ADDRESS_EDITING_ERROR_MESSAGE,
  NO_BILLING_ADDRESS_ERROR_MESSAGE
} from 'domains/User/BillingInfoForm/utils'
import ReforgeCardElement from 'domains/User/ReforgeCardElement'

import Button from 'components/Button'
import { Modal, ModalContent, ModalTitle } from 'components/Modal'
import { StripeElements } from 'components/StripeElements'
import StyledCheckbox from 'components/StyledCheckbox'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  BillingAddressFragment,
  useStripeSetupIntentMutation,
  useUpdatePaymentMethodMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'
import useModalTracking from 'hooks/useModalTracking'

import { getPageFromPath } from 'utils/location'
import { trackCtaClicked } from 'utils/tracking/analytics'

export interface AddPaymentAndPayModalProps {
  amountDue: string
  recurringAmount: string
  billingDate: string
  isOpen: boolean
  isPaidMonthly: boolean
  setIsOpen: (arg0: boolean) => void
  setInitiateFlow: (arg0: boolean) => void
  setOpenLeaveCheckoutModal: (openLeaveCheckoutModal: boolean) => void
  setOpenPaymentSuccessModal: (openPaymentSuccesModal: boolean) => void
  subscriptionId: string
}

const AddPaymentAndPayContainer = ({
  amountDue,
  recurringAmount,
  billingDate,
  isOpen,
  isPaidMonthly,
  setIsOpen,
  setInitiateFlow,
  setOpenLeaveCheckoutModal,
  setOpenPaymentSuccessModal,
  subscriptionId
}: AddPaymentAndPayModalProps) => {
  return (
    <AddPaymentAndPayModal
      amountDue={amountDue}
      recurringAmount={recurringAmount}
      billingDate={billingDate}
      isOpen={isOpen}
      isPaidMonthly={isPaidMonthly}
      setIsOpen={setIsOpen}
      setInitiateFlow={setInitiateFlow}
      setOpenLeaveCheckoutModal={setOpenLeaveCheckoutModal}
      setOpenPaymentSuccessModal={setOpenPaymentSuccessModal}
      subscriptionId={subscriptionId}
    />
  )
}

const AddPaymentAndPayModal = ({
  amountDue,
  recurringAmount,
  billingDate,
  isOpen,
  isPaidMonthly,
  setIsOpen,
  setInitiateFlow,
  setOpenLeaveCheckoutModal,
  setOpenPaymentSuccessModal
}: {
  amountDue: string
  recurringAmount: string
  billingDate: string
  isOpen: boolean
  isPaidMonthly: boolean
  setIsOpen: (arg0: boolean) => void
  setInitiateFlow: (arg0: boolean) => void
  setOpenLeaveCheckoutModal: (openLeaveCheckoutModal: boolean) => void
  setOpenPaymentSuccessModal: (openPaymentSuccesModal: boolean) => void
  subscriptionId: string
}) => {
  const stripe = useStripe() as any
  const elements = useElements() as any

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null)
  const [stripeReady, setIsValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [paymentMethodAddedToStripe, setPaymentMethodAddedToStripe] = useState(false)
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState(true)
  const [stripeSetupIntent, { error: stripeSetupIntentError }] =
    useStripeSetupIntentMutation()
  const [updatePaymentMethod, { error: updatePaymentMethodError }] =
    useUpdatePaymentMethodMutation()
  const [isAddressEditing, setIsAddressEditing] = useState(false)
  const [billingAddress, setBillingAddress] = useState<BillingAddressFragment | null>(
    null
  )

  useModalTracking(isOpen, {
    modal_group: 'dunning',
    modal_name: 'add payment'
  })

  const ctaDisabled =
    errorMessage || !stripeReady || loading || paymentMethodAddedToStripe

  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const handleClose = () => {
    if (elements && CardElement) {
      elements?.getElement(CardElement)?.clear()
    }
    setInitiateFlow(false)
    setIsOpen(false)
    setOpenLeaveCheckoutModal(true)
  }

  const handleCtaClick = () => {
    if (!validateAddressFormIsComplete()) {
      return
    }

    submitPaymentMethod()
    trackCtaClicked({
      cta_location: getPageFromPath(),
      cta_type: 'button',
      text: 'Add Card and Renew'
    })
  }

  const handleCardElementChange = (response: StripeCardElementChangeEvent) => {
    if (response?.error) {
      setErrorMessage(response.error.message)
      return setIsValid(false)
    } else {
      setErrorMessage(null)
      return setIsValid(response.complete)
    }
  }

  const submitPaymentMethod = async () => {
    try {
      if (!stripe || !elements) return

      setLoading(true)

      const { data } = await stripeSetupIntent({
        variables: { input: { userId: null } }
      })

      if (
        stripeSetupIntentError !== undefined ||
        updatePaymentMethodError ||
        Boolean(data?.stripeSetupIntent?.errors?.length)
      ) {
        setErrorMessage(
          stripeSetupIntentError?.message ||
            updatePaymentMethodError?.message ||
            data?.stripeSetupIntent?.errors?.[0]
        )
        return setLoading(false)
      }

      const { setupIntent, error: stripeJsError } = await stripe.confirmCardSetup(
        data?.stripeSetupIntent?.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        }
      )

      if (stripeJsError?.message) {
        setErrorMessage(stripeJsError.message)
        return setLoading(false)
      }

      if (isDefaultPaymentMethod) {
        await updatePaymentMethod({
          variables: {
            input: { id: setupIntent.payment_method, isDefaultPaymentMethod: true }
          }
        })
      }

      setIsOpen(false)
      setLoading(false)
      setPaymentMethodAddedToStripe(true)
      return setOpenPaymentSuccessModal(true)
    } catch (e) {
      setErrorMessage(e.message)
      return setLoading(false)
    }
  }

  const validateAddressFormIsComplete = () => {
    if (!billingAddress) {
      setErrorMessage(NO_BILLING_ADDRESS_ERROR_MESSAGE)
      return false
    }
    if (isAddressEditing) {
      setErrorMessage(IS_ADDRESS_EDITING_ERROR_MESSAGE)
      return false
    }
    setErrorMessage(null)
    return true
  }

  const handleSavedBillingAddress = ({
    values,
    isComplete
  }: {
    values: ExternalValues
    isComplete: boolean
  }) => {
    if (isComplete && values.billingAddress) {
      setBillingAddress(values.billingAddress)
      setErrorMessage(null)
    }
  }

  const handleEditStateChange = useCallback((editState) => {
    setIsAddressEditing(editState)
    setErrorMessage(null)
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        className="max-w-2xl"
        scrollContent={false}
      >
        <div className="flex flex-col pb-8 md:pb-11">
          <ModalTitle className="px-8 pb-5 md:px-11">
            {isMobile
              ? 'Add Payment Method and Renew'
              : 'Add Payment Method and Renew Subscription'}
          </ModalTitle>
          <ModalContent scrollContent={false}>
            <p className="text-sm">
              Upon updating your payment method, you will be charged {amountDue} + sales
              tax and then {recurringAmount} + sales tax{' '}
              {isPaidMonthly ? 'a month for the next 12 months ' : `on ${billingDate} `}
              for your Reforge subscription, which will automatically renew every 12
              months. To manage this subscription, visit the Billing page.
            </p>

            <StripeElements>
              <StandaloneBillingInfoForm
                trackingLocation="add-payment-and-pay-modal"
                onSave={(values, isComplete) =>
                  handleSavedBillingAddress({ values, isComplete })
                }
                onLoad={(values, isComplete) =>
                  handleSavedBillingAddress({ values, isComplete })
                }
                onEditStateChange={handleEditStateChange}
              />
            </StripeElements>

            <div className="mt-8">
              <ReforgeCardElement
                onChange={handleCardElementChange}
                errorMessage={errorMessage}
              />
              <label
                className="mb-2.5 flex flex-auto items-center"
                onClick={() => {
                  setIsDefaultPaymentMethod(!isDefaultPaymentMethod)
                }}
              >
                <StyledCheckbox checked={isDefaultPaymentMethod} />
                Set as primary payment method
              </label>
            </div>

            <div className={`flex ${isMobile ? 'justify-center' : 'justify-end'}`}>
              <Button
                onClick={handleClose}
                size={isMobile ? 'x-small' : 'medium'}
                variant={isMobile ? 'outline' : 'text-only'}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button
                onClick={handleCtaClick}
                size={isMobile ? 'x-small' : 'medium'}
                shape="rounded"
                disabled={!!ctaDisabled}
              >
                {loading ? 'Saving...' : 'Add Card and Renew'}
              </Button>
            </div>
          </ModalContent>
        </div>
      </Modal>
    </>
  )
}

export default AddPaymentAndPayContainer
