import React, { ReactChild, ReactElement, cloneElement, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import Button, { type ButtonProps } from 'components/Button'

import { ReactComponent as ClearIcon } from 'images/icon--clear.svg'

const bgColorMapping: Record<NonNullable<BannerProps['type']>, string> = {
  info: 'bg-rb-blue-50',
  warning: 'bg-rb-warning-50',
  error: 'bg-rb-destructive-50'
}

export type BannerVariantType = 'info' | 'warning' | 'error'
export interface BannerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  cta?: ReactElement<ButtonProps>
  dismissable?: boolean
  icon?: ReactElement
  onDismiss?: () => void
  type?: BannerVariantType
  title?: ReactChild
}

export default function Banner({
  className,
  type = 'info',
  cta,
  title,
  children,
  icon,
  onDismiss,
  dismissable = true,
  ...props
}: BannerProps) {
  const iconResponsive = useMemo(
    () =>
      icon
        ? cloneElement(icon, {
            className: twMerge('max-h-full max-w-full', icon.props?.className)
          })
        : null,
    [icon]
  )

  const ctaElement = useMemo(
    () =>
      cta
        ? cloneElement(cta, {
            className: twMerge('shrink-0 mt-4 sm:mt-0', cta.props?.className),
            shape: cta.props.shape || 'rounded-full',
            size: cta.props.size || 'x-small'
          })
        : null,
    [cta]
  )

  return (
    <div
      id="banner"
      className={twMerge(className, 'p-4 text-rb-black md:p-8 ', bgColorMapping[type])}
      {...props}
    >
      <div className="flex items-start gap-4 sm:items-center">
        <div className="flex flex-col sm:w-full sm:flex-row">
          <div className="sm:align-center sm:align-center flex w-full flex-col sm:flex-row sm:justify-between sm:gap-4">
            <div className="flex w-full items-center gap-2">
              {icon && (
                <div className="flex h-[26px] w-[26px] items-center justify-center">
                  {iconResponsive}
                </div>
              )}
              <div className="w-full">
                {title && (
                  <div className="text-xs font-semibold md:text-base">{title}</div>
                )}
                {children && (
                  <div className="text-xs font-normal md:text-sm">{children}</div>
                )}
              </div>
            </div>
            {ctaElement}
          </div>
        </div>
        {dismissable && (
          <Button
            variant="text-only"
            className="ml-auto shrink-0 p-1"
            onClick={onDismiss}
            aria-label="Close"
          >
            <ClearIcon
              className="h-6 w-6 fill-current"
              data-testid="banner-dismiss"
              strokeWidth="1.5"
              aria-hidden="true"
              focusable="false"
            />
          </Button>
        )}
      </div>
    </div>
  )
}
