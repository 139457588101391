const ArrowUpRight = ({
  className,
  width = 10,
  height = 9
}: {
  className?: string
  width?: number
  height?: number
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 9"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.57864 0.950098H1.20051V0.100098H8.60468H9.02968V0.525098V7.10658H8.17968V1.55114L1.50103 8.22978L0.899994 7.62874L7.57864 0.950098Z"
    />
  </svg>
)

export default ArrowUpRight
