import React, { useCallback, useContext, useState } from 'react'

import Modal, { ModalProps } from 'components/Modal'

export type ModalPropsWithoutProvidedProps = Omit<
  ModalProps,
  'children' | 'isOpen' | 'handleClose'
> & { handleClose?: any }

interface GlobalModalProviderProps {
  children: React.ReactNode
}

interface GlobalModalContextValue {
  openGlobalModal: (
    content: React.ReactElement,
    modalProps?: ModalPropsWithoutProvidedProps
  ) => void
  closeGlobalModal: () => void
  isGlobalModalOpen: boolean
}

export const GlobalModalContext = React.createContext<GlobalModalContextValue>({
  openGlobalModal: () => {},
  closeGlobalModal: () => {},
  isGlobalModalOpen: false
})

export const GlobalModalProvider = ({ children }: GlobalModalProviderProps) => {
  const [globalModalContent, setGlobalModalContent] = useState<React.ReactElement | null>(
    null
  )
  const [globalModalProps, setGlobalModalProps] =
    useState<ModalPropsWithoutProvidedProps | null>(null)

  const openGlobalModal = useCallback(
    (content: React.ReactElement | null, props?: ModalPropsWithoutProvidedProps) => {
      if (props) {
        setGlobalModalProps(props)
      }
      setGlobalModalContent(content)
    },
    [setGlobalModalProps, setGlobalModalContent]
  )

  const closeGlobalModal = useCallback(() => {
    setGlobalModalProps(null)
    setGlobalModalContent(null)
  }, [setGlobalModalProps, setGlobalModalContent])

  const globalModalValue: GlobalModalContextValue = {
    openGlobalModal,
    closeGlobalModal,
    isGlobalModalOpen: !!globalModalContent
  }

  return (
    <GlobalModalContext.Provider value={globalModalValue}>
      {children}

      <Modal
        isOpen={!!globalModalContent}
        handleClose={closeGlobalModal} // this handleClose is overwritten by globalModalProps.handleClose if it's provided
        {...globalModalProps}
      >
        {globalModalContent}
      </Modal>
    </GlobalModalContext.Provider>
  )
}

export const useGlobalModal = () =>
  useContext<GlobalModalContextValue>(GlobalModalContext)
