// camelcase disabled because it's imposed by externally generated dependency

/* eslint-disable camelcase */
import { useEffect } from 'react'

import {
  trackModalDismissed as generatedTrackModalDismissed,
  trackModalDisplayed as generatedTrackModalDisplayed
} from 'utils/tracking/analytics'
import { ModalDismissed } from 'utils/tracking/generated/types/ModalDismissed'
import { ModalDisplayed } from 'utils/tracking/generated/types/ModalDisplayed'

type ModalDisplayedTrackingData = Pick<ModalDisplayed, 'modal_group' | 'modal_name'>
type ModalDismissedTrackingData = Pick<ModalDismissed, 'modal_group' | 'modal_name'>

export function trackModalDisplayed({
  modal_group,
  modal_name
}: ModalDisplayedTrackingData) {
  generatedTrackModalDisplayed({
    category: 'app',
    modal_group,
    modal_name
  })
}

/*
  fn: useModalTracking()
  summary: A Convenience wrapper to track modals being displayed
*/
const useModalTracking = (
  isOpen: boolean,
  { modal_name, modal_group }: ModalDisplayedTrackingData
) => {
  useEffect(() => {
    if (isOpen) {
      trackModalDisplayed({ modal_name, modal_group })
    }
  }, [modal_group, modal_name, isOpen])
}

export function trackModalDismissed({
  modal_group,
  modal_name
}: ModalDismissedTrackingData) {
  generatedTrackModalDismissed({
    category: 'app',
    modal_group,
    modal_name
  })
}
/* eslint-disable camelcase */

export default useModalTracking
