import React, { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { numberWithCommas } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'

interface TextAreaQuestionProps {
  autofocus?: boolean
  className?: string
  labelClassName?: string
  initialResponse?: string | null
  label?: string
  maxChars?: number
  onChange: (value: string) => void
  placeholder?: string
  overflowYAuto?: boolean
  dataTest?: string
}

const TextAreaQuestion = ({
  autofocus,
  className = '',
  labelClassName = '',
  initialResponse,
  label,
  maxChars = 300,
  onChange,
  placeholder = '',
  overflowYAuto,
  dataTest
}: TextAreaQuestionProps) => {
  const getTrimmedValue = useCallback(
    (value: string) => value.substring(0, maxChars),
    [maxChars]
  )
  const [textareaValue, setTextareaValue] = useState(initialResponse || '')
  const [charsLeft, setCharsLeft] = useState(maxChars - textareaValue.length)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    setTextareaValue(initialResponse || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialResponse])

  useEffect(() => {
    if (autofocus && textareaValue && textareaRef.current) {
      textareaRef.current.setSelectionRange(textareaValue.length, textareaValue.length)
      textareaRef.current.focus()
    }
  }, [autofocus, textareaValue])

  const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const trimmedValue = getTrimmedValue(e.target.value)

    setTextareaValue(trimmedValue)
    setCharsLeft(maxChars - trimmedValue.length)

    onChange(trimmedValue)
  }

  return (
    <div className={`${className} rf-textarea-question`}>
      <div className={cn('mb-3 text-base font-medium leading-5', labelClassName)}>
        {label}
      </div>
      <div className="sm:flex-evenly flex flex-col sm:flex-row tl:flex-row">
        <div className="relative w-full">
          <textarea
            ref={textareaRef}
            className={twMerge(
              'lg:min-h[238px] box-border min-h-[176px] w-full resize-none overflow-hidden border border-rb-gray-100 p-2.5 font-sans text-base text-rb-gray-500 outline-none placeholder:italic placeholder:text-rb-gray-300 focus:border-black focus:outline-none',
              overflowYAuto ? 'overflow-y-auto' : '',
              charsLeft < 0 ? 'border-rb-destructive focus:border-rb-destructive' : ''
            )}
            placeholder={placeholder}
            onInput={handleChange}
            value={textareaValue}
            data-test={dataTest}
          />
          <div
            className={`absolute right-1.5 bottom-2.5 text-sm text-rb-gray-300 ${
              charsLeft < 0 ? 'text-rb-destructive' : ''
            }`}
          >
            {numberWithCommas(charsLeft)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextAreaQuestion
