import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import DunningFlow from 'domains/Subscription/DunningFlow'

import { AlertBanner } from 'components/AlertBanner'
import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { track, trackCtaClicked } from 'utils/tracking/analytics'

const DUNNING_COOKIE_KEY = 'dismiss-dunning-banner'

const BANNER_NAME = 'dunning'
export const INDIVIDUAL_MESSAGE =
  'We couldn’t process your payment. Add a payment method now to renew your subscription.'
export const TEAM_OWNER_MESSAGE =
  'We couldn’t process your payment. Update your payment method now to renew your subscription.'
export const INVOICE_OWNER_MESSAGE =
  'Your invoice is past due. Complete your payment for continued access to Reforge.'

export const OWNER_WITH_PAYMENT_METHOD_CTA = 'Update Payment'
export const OWNER_NO_PAYMENT_METHOD_CTA = 'View Details'
export const INDIVIDUAL_CTA_LONG = 'Add Payment Method'
export const INDIVIDUAL_CTA_SHORT = 'Add Payment'
export const INVOICE_CTA = 'View Billing History'

export interface DunningBannerProps {
  isSubscriptionOwner: boolean
  isTeamSubscription: boolean
  isDunning: boolean
  hasPaymentMethod: boolean
  expiresAt: string
}

export default function DunningBanner({
  isSubscriptionOwner,
  isTeamSubscription,
  hasPaymentMethod,
  isDunning,
  expiresAt
}: DunningBannerProps) {
  const [initiateFlow, setInitiateFlow] = useState(false)
  const history = useHistory()

  useEffect(() => {
    track('Banner Displayed', {
      category: 'app',
      banner_name: BANNER_NAME
    })
  }, [])

  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)

  const getText = () => {
    if (isDunning) {
      return INVOICE_OWNER_MESSAGE
    }

    if (!hasPaymentMethod && !isTeamSubscription) {
      return INDIVIDUAL_MESSAGE
    }

    return TEAM_OWNER_MESSAGE
  }

  const getCtaText = () => {
    if (isDunning) {
      return INVOICE_CTA
    }

    if (hasPaymentMethod) {
      return OWNER_WITH_PAYMENT_METHOD_CTA
    }

    if (isTeamSubscription) {
      return OWNER_NO_PAYMENT_METHOD_CTA
    }

    return isSmallScreen ? INDIVIDUAL_CTA_SHORT : INDIVIDUAL_CTA_LONG
  }

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'banner',
      cta_type: 'button',
      text: getCtaText()
    })

    if (isDunning || (!hasPaymentMethod && isTeamSubscription)) {
      history.push('/billing')

      return
    }
    setInitiateFlow(true)
  }

  const trackDismiss = () => {
    track('Banner Dismissed', {
      category: 'app',
      banner_name: BANNER_NAME
    })
  }

  const isDismissed = window.localStorage.getItem(DUNNING_COOKIE_KEY) === expiresAt

  if (isDismissed) return null

  return (
    <AlertBanner
      data-testid="dunning-banner"
      onDismiss={() => {
        window.localStorage.setItem(DUNNING_COOKIE_KEY, expiresAt)
        trackDismiss()
      }}
      cta={
        isSubscriptionOwner ? (
          <Button onClick={handleClick}>{getCtaText()}</Button>
        ) : undefined
      }
      type="error"
    >
      <div>{getText()}</div>
      <DunningFlow initiateFlow={initiateFlow} setInitiateFlow={setInitiateFlow} />
    </AlertBanner>
  )
}
