import { useCallback, useEffect } from 'react'

const localStorageKey = 'reforge_inter_tab_communication'

/**
 * Script used to send and receive messages between tabs
 */
const useInterTabCommunication: (onMessageReceived?: (message: any) => void) => {
  postMessage: (message: any) => void
} = (onMessageReceived = () => {}) => {
  const messageReceive = useCallback(
    (ev) => {
      if (ev.key !== localStorageKey) return // ignore other keys

      let data = null
      try {
        data = JSON.parse(ev.newValue)
      } catch (e) {
        data = null
      }

      if (!data?.message) return // ignore empty msg or msg reset

      onMessageReceived(data.message)
    },
    [onMessageReceived]
  )

  useEffect(() => {
    window.addEventListener('storage', messageReceive)
    return () => {
      window.removeEventListener('storage', messageReceive)
    }
  })

  const postMessage = useCallback((message: any) => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        message
      })
    )
    localStorage.removeItem(localStorageKey)
  }, [])

  return {
    postMessage
  }
}

export default useInterTabCommunication
