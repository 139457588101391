import React, { useEffect, useRef, useState } from 'react'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeftIcon } from 'images/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/chevron-right.svg'

import Tab, { TabProps } from './Tab'

export type TabsType = Omit<TabProps, 'size'>[]

export interface TabsProps {
  className?: string
  tabs: TabsType
  size?: TabProps['size']
  dataTest?: string
  allowHorizontalScroll?: boolean
}

const Tabs = ({
  tabs,
  size = 'default',
  className = '',
  dataTest,
  allowHorizontalScroll = true
}: TabsProps) => {
  const [shouldShowScrollRight, setShouldShowScrollRight] = useState(false)
  const [shouldShowScrollLeft, setShouldShowScrollLeft] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { scrollWidth, clientWidth, scrollLeft } = scrollContainerRef.current || {}
    handleShouldScroll(scrollLeft || 0, clientWidth || 0, scrollWidth || 0)

    const handleResize = () => {
      const { scrollWidth, clientWidth, scrollLeft } = scrollContainerRef.current || {}
      handleShouldScroll(scrollLeft || 0, clientWidth || 0, scrollWidth || 0)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollWidth, clientWidth, scrollLeft } = e.currentTarget || {}
    handleShouldScroll(scrollLeft, clientWidth, scrollWidth)
  }

  const handleShouldScroll = (
    scrollLeft: number,
    clientWidth: number,
    scrollWidth: number
  ) => {
    const canScroll = scrollWidth > clientWidth
    setShouldShowScrollRight(canScroll && scrollLeft + clientWidth < scrollWidth)
    setShouldShowScrollLeft(canScroll && scrollLeft > 0)
  }

  const onScrollClick = (amount: number) => {
    scrollContainerRef.current?.scrollBy({
      left: amount,
      behavior: 'smooth'
    })
  }

  return (
    <div className="relative">
      <div
        className={cn('hide-scrollbar flex gap-2 overflow-x-auto md:gap-4', className, {
          'md:gap-2': size === 'small'
        })}
        ref={scrollContainerRef}
        onScroll={onScroll}
        data-test={dataTest}
      >
        {tabs.map((tab, i) => (
          <Tab key={i} {...tab} size={size} />
        ))}
      </div>

      {allowHorizontalScroll && shouldShowScrollRight && (
        <>
          <div className="absolute top-0 right-0 hidden h-8 w-10 rounded-tl-full rounded-bl-full bg-[linear-gradient(90deg,transparent_0%,white_20%)] md:block" />
          <button
            onClick={() => onScrollClick(200)}
            className="absolute top-0 right-0 hidden h-8 w-8 items-center justify-center rounded-full border border-rb-gray bg-rb-white hover:border-rb-gray hover:bg-rb-gray-50 active:border-rb-gray md:flex"
          >
            <ChevronRightIcon className="h-3 w-3" />
          </button>
        </>
      )}

      {allowHorizontalScroll && shouldShowScrollLeft && (
        <>
          <div className="absolute top-0 left-0 hidden h-8 w-10 rounded-tr-full rounded-br-full bg-[linear-gradient(-90deg,transparent_0%,white_20%)] md:block" />
          <button
            onClick={() => onScrollClick(-200)}
            className="absolute top-0 left-0 hidden h-8 w-8 items-center justify-center rounded-full border border-rb-gray bg-rb-white hover:border-rb-gray hover:bg-rb-gray-50 active:border-rb-gray md:flex"
          >
            <ChevronLeftIcon className="h-3 w-3" />
          </button>
        </>
      )}
    </div>
  )
}

export default Tabs
