export const GENERAL_TAB: number = 1
export const BIO_AND_EXPERIENCE_TAB: number = 2
export const ABOUT_MY_PRODUCT_TAB: number = 3
export const WHAT_IM_SEEKING_TAB: number = 4

export const GENERAL_TAB_COPY = 'General'
export const BIO_AND_EXPERIENCE_TAB_COPY = 'Experience'
export const ABOUT_MY_PRODUCT_TAB_COPY = 'My Product'
export const WHAT_IM_SEEKING_TAB_COPY = "Why I'm here"

export const TOOLTIP_STRING = 'Browse all members who match this criteria.'
export const SLACK_RESPONSE_TIME_OPTIONS = [
  { label: 'I usually respond within a few days', id: 'days' },
  { label: 'I usually respond within a week', id: 'week' }
]
export const HEADING_CLASSES =
  'mb-2 tl:mb-4 font-medium tracking-normal text-rb-gray-400 text-lg lg:text-xl'
export const SUBHEADING_CLASSES = 'pb-2 text-base font-medium sm:pb-3 tl:pb-3 3xl:pb-4'
export const SECTION_CLASSES = 'pb-2.5 sm:pb-4 tl:pb-6 3xl:pb-10'
export const FREE_TEXT_CLASSES = 'pb-2.5 sm:pb-4 text-sm md:text-base break-words'

export const ABOUT_ME_TEXT_MAX_CHARACTERS = 1000
export const ASK_ME_ABOUT_TEXT_MAX_CHARACTERS = 300
export const COMPANY_PRODUCT_TEXT_MAX_CHARACTERS = 200
export const COMMUNITY_INTEREST_TEXT_MAX_CHARACTERS = 200
export const LEARNING_GOALS_TEXT_MAX_CHARACTERS = 200
