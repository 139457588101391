import { FC, createContext, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

type RouterContextType = {
  goBackWithFallback: (fallbackPath: string) => void
}

const RouterContext = createContext<RouterContextType>({
  goBackWithFallback: () => {}
})

type Route = {
  to: string
  from?: string | null
}

const RouterProvider: FC = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [route, setRoute] = useState<Route>({
    to: location.pathname,
    from: null // --> previous pathname
  })

  const hasComponentMounted = useRef(false)

  useEffect(() => {
    // Skip the first render of app to avoid adding current path
    // as previous.
    if (hasComponentMounted.current) {
      setRoute((prev) => ({ to: location.pathname, from: prev.to }))
    }

    hasComponentMounted.current = true
  }, [location])

  const goBackWithFallback = (fallbackPath: string) => {
    if (route.from) {
      navigate(route.from)
    } else {
      navigate(fallbackPath)
    }
  }

  return (
    <RouterContext.Provider value={{ goBackWithFallback }}>
      {children}
    </RouterContext.Provider>
  )
}

export function useRouteContext() {
  return useContext(RouterContext)
}

export default RouterProvider
