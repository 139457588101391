// Do not add new media queries/breakpoints. Use Tailwind's breakpoints instead.
// Always use MIN_ value with min-width and MAX_ value with max-width.
// This is because Tailwind's breakpoints are min-width based.
// https://tailwindcss.com/docs/responsive-design#min-width-and-max-width
// e.g. selecting between 768px and 1280px:
// @media (min-width: MIN_WIDTH_TAILWIND_MD) and (max-width: MAX_WIDTH_TAILWIND_LG) {...}

export const MAX_WIDTH_TAILWIND_2XS = '474px'

export const MIN_WIDTH_TAILWIND_XS = '475px'
export const MAX_WIDTH_TAILWIND_XS = '639px'

export const MIN_WIDTH_TAILWIND_SM = '640px'
export const MAX_WIDTH_TAILWIND_SM = '767px'

export const MIN_WIDTH_TAILWIND_MD = '768px'
export const MAX_WIDTH_TAILWIND_MD = '959px'

export const MIN_WIDTH_TAILWIND_TL = '960px'
export const MAX_WIDTH_TAILWIND_TL = '1023px'

export const MIN_WIDTH_TAILWIND_LG = '1024px'
export const MAX_WIDTH_TAILWIND_LG = '1279px'

export const MIN_WIDTH_TAILWIND_XL = '1280px'
export const MAX_WIDTH_TAILWIND_XL = '1535px'

export const MIN_WIDTH_TAILWIND_2XL = '1536px'
