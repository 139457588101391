import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface ChildrenProps {
  children: ReactNode
  className?: string
}

// Lighter color and with less bold font weight
const RfHeader1Alt = ({ children, className }: ChildrenProps) => (
  <h1
    className={twMerge(
      'rf-h1-alt mb-0',
      className // allow color, margin and padding overrides
    )}
  >
    {children}
  </h1>
)

export default RfHeader1Alt
