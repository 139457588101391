import { format } from 'date-fns'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import ReactivationFlow from 'domains/Subscription/ReactivationFlow'

import { AlertBanner } from 'components/AlertBanner'
import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM, MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { SubscriptionReactivationFieldsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import {
  dismissUpcomingExpirationBanner,
  getIsUpcomingExpirationBannerDismissed
} from 'utils/cookies'
import { track, trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as WarningIcon } from 'images/icon--warning.svg'

export interface UpcomingExpirationBannerProps {
  hasPaymentMethod: boolean
  subscription: SubscriptionReactivationFieldsFragment
}

export default function UpcomingExpirationBanner({
  hasPaymentMethod,
  subscription
}: UpcomingExpirationBannerProps) {
  const [shouldInitiateReactivationFlow, setShouldInitiateReactivationFlow] =
    useState(false)
  const history = useHistory()
  const BANNER_NAME = 'upcoming expiration'

  useEffect(() => {
    track('Banner Displayed', {
      category: 'app',
      banner_name: BANNER_NAME
    })
  }, [])

  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const { expiresAt, daysLeft, userIsOwner, isTeam, hasUnlimitedCohortAccess } =
    subscription

  const legacyUnlimitedCohortPlan = hasUnlimitedCohortAccess && userIsOwner

  const getHeading = () => {
    return `Your ${isTeam ? 'team’s ' : ''} subscription expires in ${pluralize(
      'day',
      daysLeft,
      true
    )}`
  }

  const getText = () => {
    // @todo: deprecate once all unlimited cohort plans are migrated
    if (legacyUnlimitedCohortPlan) {
      return `Reforge has new plans available. Select your new plan before ${format(
        new Date(expiresAt),
        'MMMM do, yyyy'
      )} to retain access.`
    }

    if (isTeam) {
      if (userIsOwner) {
        if (hasPaymentMethod) {
          return isMobile
            ? 'Reactivate now for your team’s continued access.'
            : 'Reactivate now for your team’s continued access to content and member benefits.'
        } else {
          return 'Add a primary payment method for your team’s continued access to their content and member benefits.'
        }
      } else {
        return isMobile
          ? 'Ask your subscription owner to reactivate for continued access.'
          : 'Ask your subscription owner to reactivate to continue accessing your content and member benefits.'
      }
    } else {
      if (hasPaymentMethod) {
        return (
          <>
            To reactivate with reimbursement from your company, check out our{' '}
            <Link
              className="text-rb-gray-500 underline"
              to="/reimbursement?internal_ref=expiration-banner"
              target="_blank"
            >
              resources center
            </Link>
            .
          </>
        )
      }
      return 'Add a default payment method now for continued access to content and member benefits.'
    }
  }

  const getCtaText = () => {
    // @todo: deprecate once all unlimited cohort plans are migrated
    if (legacyUnlimitedCohortPlan) {
      return 'Select New Plan'
    }

    if (isTeam) {
      if (userIsOwner) {
        if (hasPaymentMethod) {
          return 'Reactivate'
        } else {
          return isSmallScreen ? 'Add Payment' : 'Add Payment Method'
        }
      } else {
        return isSmallScreen ? 'View Details' : 'View Plan Details'
      }
    } else {
      if (hasPaymentMethod) {
        return 'Reactivate'
      } else {
        return isSmallScreen ? 'Add Payment' : 'Add Payment Method'
      }
    }
  }

  const handleCtaClick = () => {
    trackCtaClicked({
      cta_location: 'upcoming expiration banner',
      cta_type: 'button',
      text: 'reactivate'
    })

    // @todo: deprecate once all unlimited cohort plans are migrated
    if (legacyUnlimitedCohortPlan) {
      return history.push('/manage-plan')
    }

    if (!userIsOwner) {
      history.push('/billing')
    } else {
      setShouldInitiateReactivationFlow(true)
    }
  }

  const trackDismiss = () => {
    // @ts-ignore - 'Upcoming Expiration Banner - Action' event is not defined in Segment JIRA#REF-5159

    track('Banner Dismissed', {
      category: 'app',
      banner_name: BANNER_NAME
    })
  }

  const isDismissed = getIsUpcomingExpirationBannerDismissed(expiresAt, daysLeft)

  return (
    <div data-test="upcoming-expiration-banner">
      {!isDismissed && (
        <AlertBanner
          cta={<Button onClick={handleCtaClick}>{getCtaText()}</Button>}
          icon={<WarningIcon className="h-6 w-6 fill-current" />}
          type="warning"
          title={getHeading()}
          onDismiss={() => {
            dismissUpcomingExpirationBanner(expiresAt, daysLeft)
            trackDismiss()
          }}
        >
          {getText()}
        </AlertBanner>
      )}
      {shouldInitiateReactivationFlow && (
        <ReactivationFlow
          hasPaymentMethod={hasPaymentMethod}
          isSmallScreen={isSmallScreen}
          setShouldInitiateReactivationFlow={setShouldInitiateReactivationFlow}
          shouldInitiate={shouldInitiateReactivationFlow}
          subscription={subscription}
        />
      )}
    </div>
  )
}
