import { useCallback, useState } from 'react'

export const useFilter = (initialActivePill: string) => {
  const [activePill, setActivePill] = useState(initialActivePill)

  const isActive = useCallback((id: string) => activePill === id, [activePill])

  return {
    isActive,
    activePill,
    setActivePill
  }
}
