import { useLocation } from 'react-router-dom'

// https://reacttraining.com/react-router/web/example/query-parameters
export default function useURLParams() {
  const query = new URLSearchParams(useLocation().search)

  const getQueryString = () => {
    return query.toString()
  }

  const getParam = (key: string, placeholder: string = '') => {
    return query.get(key) || placeholder
  }

  const setParam = (key: string, value: string = '') => {
    return query.set(key, value)
  }

  const deleteParam = (key: string) => {
    return query.delete(key)
  }

  return { getQueryString, getParam, setParam, deleteParam }
}
