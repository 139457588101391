import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'

import { SelectableTag } from 'components/Tag'

import { escapeRegexCharacters } from 'utils/stringUtils'

// Docs for react-autosuggest can be found at https://github.com/moroshko/react-autosuggest
const AutosuggestQuestion = ({
  id,
  items,
  onSelectionChange,
  placeholder,
  selections
}) => {
  const [value, setValue] = useState('')
  const [choices, setChoices] = useState(selections)
  const [suggestions, setSuggestions] = useState(items)

  useEffect(() => {
    onSelectionChange(choices)
    refreshSuggestionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices])

  const getPlaceholder = () =>
    !choices || (Object.keys(choices).length === 0 && value === '') ? placeholder : ''

  const handleDelete = (e, text) => onChange(e, { newValue: text, method: 'delete' })

  // All of the following methods and consts are part of the react-autosuggest API
  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim())

    const regex = new RegExp(escapedValue, 'i')

    const result = items
      .map((section) => {
        return {
          title: section.title,
          suggestions: section.suggestions
            .filter((suggestion) => regex.test(suggestion))
            .filter((suggestion) =>
              choices ? !Object.keys(choices).includes(suggestion) : true
            )
        }
      })
      .filter((section) => section.suggestions.length > 0)
    return result
  }

  const refreshSuggestionList = () => {
    const result = items
      .map((section) => {
        return {
          title: section.title,
          suggestions: section.suggestions.filter((suggestion) =>
            choices ? !Object.keys(choices).includes(suggestion) : true
          )
        }
      })
      .filter((section) => section.suggestions.length > 0)
    setSuggestions(result)
  }

  const getSuggestionValue = (suggestion) => suggestion

  const onChange = (_, { newValue, method }) => {
    if (['click', 'enter'].includes(method)) {
      setValue('')
      setChoices((previousChoices) => ({ ...previousChoices, [newValue]: true }))
    } else if (method === 'delete') {
      const choicesCopy = { ...choices }
      delete choicesCopy[newValue]
      setChoices(choicesCopy)
    } else {
      setValue(newValue)
    }
  }

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (['click', 'enter'].includes(method)) {
      setValue('')
      setChoices((previousChoices) => ({ ...previousChoices, [suggestionValue]: true }))
    }
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => setSuggestions([])

  const renderInputComponent = (inputProps) => {
    return (
      <div className="flex flex-wrap items-center whitespace-nowrap rounded-sm border-2 pl-2">
        {choices &&
          choices !== {} &&
          Object.keys(choices).map((choice) => (
            <SelectableTag
              key={choice}
              className="my-0.5 mr-1.5"
              text={choice}
              selected
              onChange={(e) => handleDelete(e, choice)}
            />
          ))}
        <input
          {...inputProps}
          className={`autosuggest-input-field h-10 text-base text-rb-gray-300 outline-none ${
            getPlaceholder() === '' ? 'grow' : 'w-full'
          }`}
        />
      </div>
    )
  }

  const renderSectionTitle = (section) => (
    <div className="py-2 pl-2 text-xs font-medium uppercase tracking-widest text-rb-gray-400">
      {section.title}
    </div>
  )

  const renderSuggestion = (suggestion) => (
    <div className="pl-2.5 text-rb-gray-300">{suggestion}</div>
  )

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div {...containerProps} className="mt-2">
        {children && (
          <div className="max-h-72 overflow-y-auto rounded-sm border-2">{children}</div>
        )}
      </div>
    )
  }

  const getSectionSuggestions = (section) => section.suggestions

  const inputProps = {
    placeholder: getPlaceholder(),
    value,
    onClick: refreshSuggestionList,
    onChange: onChange,
    onBlur: () => {
      setTimeout(() => null, 300)
    } // :(  Hack to prevent onBlur from firing before onClick on Next button
  }

  return (
    <div id={id} className="pt-2 sm:pt-3" data-test="auto-suggest-question-wrap">
      <Autosuggest
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={getSuggestionValue}
        highlightFirstSuggestion={true}
        inputProps={inputProps}
        multiSection={true}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        shouldRenderSuggestions={() => true}
        renderInputComponent={renderInputComponent}
        renderSectionTitle={renderSectionTitle}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
      />
    </div>
  )
}

AutosuggestQuestion.propTypes = {
  children: PropTypes.elementType,
  containerProps: PropTypes.object,
  id: PropTypes.string.isRequired,
  inputStyles: PropTypes.string,
  items: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selections: PropTypes.object
}

export default AutosuggestQuestion
