import { format } from 'date-fns'
import pluralize from 'pluralize'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Banner from 'components/Banner'
import Button from 'components/Button'
import { LearnMoreAboutPricingAndPackagingLink } from 'components/LearnMoreAboutPricingAndPackagingLink'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { SubscriptionReactivationFieldsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { dismissRenewalBanner, getIsRenewalBannerDismissed } from 'utils/cookies'
import { track, trackCtaClicked } from 'utils/tracking/analytics'

export interface RenewalBannerProps {
  isBilledViaInvoice: boolean
  hasOpenInvoices: boolean
  subscription: SubscriptionReactivationFieldsFragment
}

export default function RenewalBanner({
  isBilledViaInvoice,
  hasOpenInvoices,
  subscription
}: RenewalBannerProps) {
  const history = useHistory()

  useEffect(() => {
    track('Banner Displayed', {
      banner_name: 'renewal',
      category: ''
    })
  }, [])

  const BANNER_NAME = 'renewal'

  const { expiresAt, daysLeft, numSeats, userIsOwner, hasUnlimitedCohortAccess } =
    subscription
  const isSubscriptionOwner = userIsOwner
  const daysUntilRenewal = daysLeft
  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const subscriptionSubstring = numSeats > 1 ? 'team’s subscription' : 'subscription'

  const getText = () => {
    if (isSubscriptionOwner && hasUnlimitedCohortAccess) {
      return `Reforge has new plans available. Select your plan before ${format(
        new Date(expiresAt),
        'MMMM do, yyyy'
      )}.`
    }
    if (isBilledViaInvoice) {
      if (isSubscriptionOwner) {
        return hasOpenInvoices
          ? `Your ${subscriptionSubstring} is set to renew. Pay your invoice now for continued access to Reforge.`
          : `Your ${subscriptionSubstring} will renew in ${pluralize(
              'day',
              daysUntilRenewal,
              true
            )}. We’ll send you an invoice to pay.`
      } else {
        return hasOpenInvoices
          ? 'Your team’s subscription is set to renew. Ask your subscription owner for help.'
          : `You are subscribed to a team membership which is set to renew in ${pluralize(
              'day',
              daysUntilRenewal,
              true
            )}.`
      }
    } else {
      return `Your ${subscriptionSubstring} will automatically renew in ${pluralize(
        'day',
        daysUntilRenewal,
        true
      )}.`
    }
  }

  const text = (
    <span>
      {getText()} <LearnMoreAboutPricingAndPackagingLink />
    </span>
  )

  const getCtaText = () => {
    if (isSubscriptionOwner && hasUnlimitedCohortAccess) {
      return 'Manage Subscription'
    }

    if (isBilledViaInvoice && hasOpenInvoices) {
      return 'View Billing History'
    } else {
      if (isSmallScreen) {
        return 'View Details'
      } else {
        return isSubscriptionOwner ? 'Manage Subscription' : 'View Plan Details'
      }
    }
  }

  const handleClick = () => {
    dismissRenewalBanner(expiresAt, daysUntilRenewal)

    trackCtaClicked({
      cta_location: 'renewal banner',
      cta_type: 'button',
      text: getCtaText()
    })

    history.push('/billing')
  }

  const trackDismiss = () => {
    // @ts-ignore - 'Renewal Banner - Action' event is not defined in Segment JIRA#REF-5159
    track('Banner Dismissed', {
      category: 'app',
      banner_name: BANNER_NAME
    })
  }

  const isDismissed = getIsRenewalBannerDismissed(expiresAt, daysUntilRenewal)

  if (isDismissed) return null

  return (
    <Banner
      cta={<Button onClick={handleClick}>{getCtaText()}</Button>}
      onDismiss={() => {
        dismissRenewalBanner(expiresAt, daysUntilRenewal)
        trackDismiss()
      }}
    >
      {text}
    </Banner>
  )
}
