import { useState } from 'react'

import Button from 'components/Button'
import RfHeader1Alt from 'components/typography/RfHeader/RfHeader1Alt'

import { cn } from 'utils/tailwind'

import { AUDIENCE_OPTIONS, BUSINESS_MODEL_OPTIONS } from './constants'

interface TeamSetupStepProps {
  answers: { [key: string]: any }
  handleSetAnswer: (key: string, value: any) => void
  uniqueEmailDomains: string[]
}

interface TagQuestionProps {
  option: string
  handleChange: (value: string) => void
  answers: string[]
}

const TagQuestion = ({ option, handleChange, answers }: TagQuestionProps) => {
  const selected = answers.includes(option)
  return (
    <Button
      size="x-small"
      variant={selected ? 'fill' : 'outline'}
      onClick={() => handleChange(option)}
      className="text-xs font-semibold leading-4"
      shape="rounded-full"
    >
      {option}
    </Button>
  )
}

const TeamSetupStep = ({
  answers,
  handleSetAnswer,
  uniqueEmailDomains
}: TeamSetupStepProps) => {
  const [toggleInFocus, setToggleInFocus] = useState(false)

  const handleSelectChange = (key: string, value: string) => {
    let newVal
    if (!answers[key] || !answers[key].includes(value)) {
      newVal = [...(answers[key] || []), value]
    } else {
      newVal = answers[key].filter((v: string) => v !== value)
    }
    handleSetAnswer(key, newVal)
  }

  return (
    <>
      <RfHeader1Alt>Set up your team plan</RfHeader1Alt>
      <div className="flex flex-col gap-4">
        <label htmlFor="teamNameInput" className="font-semibold leading-6 text-rb-black">
          Team Name &#42;
          <div className="text-base font-normal leading-[1.6]">
            Choose a name that represents your company and function.
          </div>
        </label>
        <input
          type="text"
          id="teamNameInput"
          data-testid="team-setup-team-name"
          required
          onChange={(e) => handleSetAnswer('teamName', e.target.value)}
          defaultValue={answers.teamName}
          placeholder="Ex. Adobe Product Management, Google Marketing, etc."
          className="w-full rounded border border-gray-400 bg-white py-2 px-4 leading-6 text-rb-black placeholder:text-gray-400"
        />
      </div>
      <div className="flex w-full flex-col gap-4">
        <label htmlFor="businessModel" className="font-semibold leading-6 text-rb-black">
          Which best describes your business model? Select all that apply &#42;
        </label>
        <div className="flex flex-wrap gap-4" data-testid="team-setup-business-model">
          {BUSINESS_MODEL_OPTIONS.map((option) => (
            <TagQuestion
              key={option}
              option={option}
              handleChange={(value) => handleSelectChange('businessModel', value)}
              answers={answers.businessModel || []}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <label htmlFor="audience" className="font-semibold leading-6 text-rb-black">
          Which best described your audience? Select all that apply &#42;
        </label>
        <div className="flex flex-wrap gap-4" data-testid="team-setup-audience">
          {AUDIENCE_OPTIONS.map((option) => (
            <TagQuestion
              key={option}
              option={option}
              handleChange={(value) => handleSelectChange('audience', value)}
              answers={answers.audience || []}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-4 mb-16">
        <div className="font-semibold leading-6 text-rb-black">
          Make your team discoverable so team members can find your team and join
        </div>

        <div className="flex flex-col tl:flex-row tl:items-center gap-4 justify-between">
          <label htmlFor="isDiscoverableToggle">
            <div className="relative inline-block my-2 h-6 w-[50px]">
              <input
                type="checkbox"
                className="h-0 w-0 opacity-0"
                id="isDiscoverableToggle"
                name="isDiscoverable"
                onChange={() =>
                  handleSetAnswer('isDiscoverable', !answers.isDiscoverable)
                }
                onFocus={() => setToggleInFocus(true)}
                onBlur={() => setToggleInFocus(false)}
              />
              <span
                className={cn(
                  "absolute inset-0 cursor-pointer rounded-full duration-400 before:absolute before:bottom-0.5 before:left-0.5 before:h-5 before:w-5 before:rounded-[50%] before:bg-white before:duration-400 before:content-['']",
                  answers.isDiscoverable
                    ? 'bg-rb-blue-100 before:translate-x-[26px]'
                    : 'bg-rb-gray-100',
                  toggleInFocus && 'outline-reforge-blue shadow-slider'
                )}
              />
            </div>
            <span className="pl-4 text-base leading-[1.6]">Team is discoverable</span>
          </label>

          {answers.isDiscoverable && (
            <div className="flex items-center gap-4">
              <label htmlFor="domainNameSelector" className="text-base leading-[1.6]">
                Email domain
              </label>
              <div className="border border-neutral-300 rounded overflow-hidden">
                <select
                  name="domainName"
                  id="domainNameSelector"
                  className="w-full border-r-8 border-transparent px-3 h-[38px] text-neutral-900"
                  onChange={(e) => handleSetAnswer('domainName', e.target.value)}
                >
                  <option value="" disabled selected={!answers.domainName}>
                    Select an email domain
                  </option>
                  {uniqueEmailDomains.map((domain) => (
                    <option
                      key={domain}
                      value={domain}
                      selected={domain === answers.domainName}
                    >
                      {domain}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TeamSetupStep
